import React from 'react'
import Header from './Header'
import Lower from './Lower'
import Footer from './Footer'
import NavBar from './navbar'
import ResCont from './ResCont'


const Resources = () => {
  return (
    <div style={{backgroundColor:'#f9f9f1', backgroundSize:'cover'}}>
    <Header />
    <NavBar />
    <h1 className='text-center my-3 bg-danger p-2'>Resources</h1>
    <div className='w-75 p-3 my-3 text-justify border border-dark rounded m-auto bg-body shadow-sm'>
      <ResCont />
    </div> 
    <Lower />
    <Footer />
    </div>
  )
}

export default Resources