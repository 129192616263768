import React from 'react'

function Week5() {
  return (
  <div>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 29 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>Introduction to Image Processing, OpenCV and Yolo</strong></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial', fontWeight: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Introduction to Image Processing, OpenCV and Yolo&nbsp;|</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>Mr Eishkaran Singh&nbsp;|&nbsp;</span><a href="https://github.com/EISHKARAN/TSS-Resources" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a>
            <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
              <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Topics:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span>
                <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Intro to image processing</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Image Processing using OpenCV</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Image Processing using Scikit learn</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Intro to Yolo</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Yolo Architecture</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Object Detection with Yolo</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Projects/opportunities for future</span></li>
                </ul>
              </li>
              <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-39: Face Mask Detection using Yolo V7 |&nbsp;</span><a href="https://github.com/EISHKARAN/TSS-Resources/blob/main/Face_Mask_Detection.ipynb" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
              <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-40: Social Distancing using Yolo V3 &nbsp;|&nbsp;</span><a href="https://github.com/tanav2202/open-cv" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Image Processing using MATLAB |&nbsp;</span><a href="https://circuitdigest.com/tutorial/getting-started-with-image-processing-using-matlab" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project: Social Distancing using Yolo V3 &nbsp;|&nbsp;</span><a href="https://github.com/tanav2202/open-cv" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Resource of the Day</span></strong>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Image Processing using MATLAB |&nbsp;</span><a href="https://circuitdigest.com/tutorial/getting-started-with-image-processing-using-matlab" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>PPT Link |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/EISHKARAN/TSS-Resources/blob/main/Image%20Processing.pptx" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Assignment / Mini Project&nbsp;</></span><span style={{color: 'black', border: '1pt none windowtext'}}><>|</></span><span style={{border: '1pt non  e windowtext', color: 'black'}}><>&nbsp;</></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-39: Face Mask Detection using Yolo V7 |&nbsp;</span><a href="https://github.com/EISHKARAN/TSS-Resources/blob/main/Face_Mask_Detection.ipynb" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span><span style={{color: 'windowtext'}}>&nbsp;</span></a><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-40: Social Distancing using Yolo V3 &nbsp;|&nbsp;</span><a href="https://github.com/tanav2202/open-cv" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a><a href="https://github.com/tanav2202/open-cv" target="_blank"><span style={{border: '1pt none windowtext', color: 'windowtext'}}>&nbsp;</span></a><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black'}}>&nbsp;</span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <hr />  
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 30 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong> Deep Learning for Image Processing</strong></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
        <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontFamily: 'initial', fontWeight: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Deep Learning: Image Processing &nbsp;| Mr Tanav Singh Bajaj |&nbsp;</span><a href="https://www.tanav.xyz/resources/1" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a>
            <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
              <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Topics:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span>
                <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Basics of Deep Learning</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Neural networks</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Forward Propagation</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Backward Propagation</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Terminologies for Neural Networks</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Steps for building a deep learning model&nbsp;</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>CNNs</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>UNet</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>ResNet</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Projects/opportunities for future</span></li>
                </ul>
              </li>
              <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-41: Use Deep Learning for Image Classification |&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/basics-of-deep-learning-classification" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
              <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-42: Using CNNs for Image Classification |&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/cnn-for-multiclass-image-classification" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-43: Using ResNet for Image Classification |&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/resnet-for-classification/notebook" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-44: UNet for Image Segmentation &nbsp;|&nbsp;</span><a href="https://colab.research.google.com/drive/1laPoOaGcqEBB3jTvb-pGnmDU21zwtgJB" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-45: Try using a pre-trained model (Besides ResNet) on Animal Images Dataset |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.kaggle.com/datasets/ashishsaxena2209/animal-image-datasetdog-cat-and-panda" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Resource of the Day</></span>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial', fontWeight: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Collection of resources |&nbsp;</span><a href="https://www.tanav.xyz/resources" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Assignment / Mini Project&nbsp;</></span><span style={{color: 'black', border: '1pt none windowtext'}}><>|</></span><span style={{border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-41: Use Deep Learning for Image Classification |&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/basics-of-deep-learning-classification" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-42: Using CNNs for Image Classification |&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/cnn-for-multiclass-image-classification" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>&nbsp;</span></a><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-43: Using ResNet for Image Classification |&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/resnet-for-classification/notebook" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-44: UNet for Image Segmentation &nbsp;|&nbsp;</span><a href="https://colab.research.google.com/drive/1laPoOaGcqEBB3jTvb-pGnmDU21zwtgJB" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-45: Try using a pre-trained model (Besides ResNet) on Animal Images Dataset |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.kaggle.com/datasets/ashishsaxena2209/animal-image-datasetdog-cat-and-panda" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span><span style={{color: 'rgb(18, 39, 224)'}}>&nbsp;</span></a><span style={{color: 'black', border: '1pt none windowtext'}}>|</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black'}}>&nbsp;</span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <hr />
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 31 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp; Introduction to Generative Adversarial Network (GAN)</strong></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
        <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontFamily: 'initial', fontWeight: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Introduction to Generative Adversarial Network (GAN) | Mr Tanav Singh Bajaj and Eishkaran Singh |&nbsp;</span><a href="https://www.tanav.xyz/resources/1" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a>
            <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
              <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Topics:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span>
                <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>What is generative AI</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Capabilities of GANs</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>What are GANs</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>GAN architecture&nbsp;</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Generator</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Discriminator</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Training a GAN</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Demo of Basic GANs</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Demo of CycleGAN</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Demo of SRGAN</span></li>
                </ul>
              </li>
              <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-46: DCGAN |&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/introduction-to-generative-adversarial-networks/notebook" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
              <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-47: SRGAN |&nbsp;</span><a href="https://www.kaggle.com/code/eishkaran/srgan-pytorch" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-48: CycleGAN |&nbsp;</span><a href="https://www.kaggle.com/code/eishkaran/cyclegan-implementation" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black'}}>&nbsp;</span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Resource of the Day</span></strong>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Collection of resources |&nbsp;</span><a href="https://www.tanav.xyz/resources" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black'}}>&nbsp;</span></p>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Assignment / Mini Project&nbsp;</></span><span style={{color: 'black', border: '1pt none windowtext'}}><>|</></span><span style={{border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial', fontWeight: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-46: DCGAN |&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/introduction-to-generative-adversarial-networks/notebook" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>&nbsp;</span></a><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-47: SRGAN |&nbsp;</span><a href="https://www.kaggle.com/code/eishkaran/srgan-pytorch" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-48: CycleGAN |&nbsp;</span><a href="https://www.kaggle.com/code/eishkaran/cyclegan-implementation" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black'}}>&nbsp;</span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial', fontWeight: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <hr />
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 32 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>Introduction to Natural Language Processing - I</strong></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial', fontWeight: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Introduction to Natural Language Processing&nbsp;| Mr Tanav Singh Bajaj and Eishkaran Singh |&nbsp;</span><a href="https://github.com/EISHKARAN/TSS-Resources" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a>
            <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
              <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Topics:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span>
                <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>What is Natural Language&nbsp;</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Preprocessing Textual Data</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>RNN&nbsp;</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>LSTM</span></li>
                </ul>
              </li>
              <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-49: Toxic Comment Classification using RNN and LSTM |&nbsp;</span><a href="https://www.kaggle.com/code/eishkaran/basic-rnn-lstm-gru" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-50: GRU Part of the Toxic Comment Classification |&nbsp;</span><a href="https://www.kaggle.com/code/eishkaran/basic-rnn-lstm-gru" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-51: Sentiment Analysis Using LSTM, RNN and Bidirectional LSTM |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/movie-reviews-sentiment-analysis" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-52: Sentiment Analysis Using an unsupervised learning approach |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/sentiment-analysis-on-hospital-comments" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Resource of the Day</span></strong>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Collection of resources |&nbsp;</span><a href="https://github.com/EISHKARAN/TSS-Resources" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Assignment / Mini Project&nbsp;</></span><span style={{color: 'black', border: '1pt none windowtext'}}><>|</></span><span style={{border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial', fontWeight: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-49: Toxic Comment Classification using RNN and LSTM |&nbsp;</span><a href="https://www.kaggle.com/code/eishkaran/basic-rnn-lstm-gru" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-50: GRU Part of the Toxic Comment Classification |&nbsp;</span><a href="https://www.kaggle.com/code/eishkaran/basic-rnn-lstm-gru" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-51: Sentiment Analysis Using LSTM, RNN and Bidirectional LSTM |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/movie-reviews-sentiment-analysis" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-52: Sentiment Analysis Using an unsupervised learning approach |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/sentiment-analysis-on-hospital-comments" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-53: Toxic Comment Classification Using RNN, LSTM and GRU |&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/sentiment-analysis-on-hospital-comments" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black'}}>&nbsp;</span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 26}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
        <ol style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial', fontWeight: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
        </ol>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <hr />
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 33 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>Introduction to Natural Language Processing - II</strong></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial', fontWeight: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Introduction to Natural Language Processing&nbsp;| Mr Tanav Singh Bajaj and Eishkaran Singh |&nbsp;</span><a href="https://www.tanav.xyz/resources" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a>
            <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
              <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Topics:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span>
                <ul className="decimal_type" style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>History of NLP</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>NLP &nbsp;Pipeline</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Attention is all you need &nbsp;</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Transformer Architecture</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>BERT&nbsp;</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>BERT vs Alternates</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Change from GPT3.5 onwards</span></li>
                  <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>BERT Code</span></li>
                </ul>
              </li>
              <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-54: Sentiment Analysis on Hospital Comments |&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/sentiment-analysis-on-hospital-comments" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-55: Review Analysis Using Transformers |&nbsp;</span><a href="https://www.kaggle.com/tanavbajaj/review-analysis" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-56: Do Preprocessing and Sentiment Analysis (Using pre-trained models) on the Diabetes Reddit Dataset |&nbsp;</span><a href="https://www.kaggle.com/datasets/tanavbajaj/diabetes-subreddits-data-weekly-update" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black'}}>&nbsp;</span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Resource of the Day</span></strong>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Collection of resources |&nbsp;</span><a href="https://www.tanav.xyz/resources" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Assignment / Mini Project&nbsp;</></span><span style={{color: 'black', border: '1pt none windowtext'}}><>|</></span><span style={{border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial', fontWeight: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-54: Sentiment Analysis on Hospital Comments |&nbsp;</span><a href="https://www.kaggle.com/code/tanavbajaj/sentiment-analysis-on-hospital-comments" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>&nbsp;</span></a><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-55: Review Analysis Using Transformers |&nbsp;</span><a href="https://www.kaggle.com/tanavbajaj/review-analysis" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-56: Do Preprocessing and Sentiment Analysis (Using pre-trained models) on the Diabetes Reddit Dataset |&nbsp;</span><a href="https://www.kaggle.com/datasets/tanavbajaj/diabetes-subreddits-data-weekly-update" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black'}}>&nbsp;</span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 26}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
        <ol style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial', fontWeight: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
        </ol>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <div style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif'}}>
      <ul style={{marginBottom: '0cm', listStyleType: 'disc', marginLeft: 32}}>
        <li style={{margin: '0cm 0cm 0.0001pt', fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Kaggle Hackathon</></span><span style={{border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{color: 'black', border: '1pt none windowtext'}}><>|</></span><span style={{border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{color: 'black', border: '1pt none windowtext'}}><>23rd July 2023 08:00&nbsp;</></span><span style={{border: '1pt none windowtext', color: 'black'}}><>am &nbsp;|</></span>
        {/* <span style={{color: 'black', border: '1pt none windowtext'}}><>&nbsp;</></span><a href="https://www.kaggle.com/t/7bc9aabcfacef243297c1bd6839e2abd" target="_blank"><><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Click Here</></span></></a> */}
        </li>
      </ul>
    </div>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
    <hr />  
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 34 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}>Daily Task</span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}>Due Date:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>Today 23:59:59 |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
        </ul>
      </li>
    </ul>
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}>&nbsp;</span></p>
    <hr />
    <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 35 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span></p>
    <ul style={{listStyleType: 'disc', marginLeft: 32}}>
      <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
        <ul style={{listStyleType: 'circle', fontSize: 'initial', fontFamily: 'initial', fontWeight: 'initial'}}>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Her</span></a>e</li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext', padding: '0cm'}}>1-Activity that I love</span></li>
        </ul>
      </li>
    </ul>
  </div>

  )
}

export default Week5