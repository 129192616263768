import React, { Component } from 'react'
import img from './images/avatar3.png'

export default class MsgFounder extends Component {
  render() {
    return (
      <div style={{'backgroundColor':'red','marginTop':'-40px'}}>
      <div class="container" style={{margin: '',textAlign:'justify',}}>
      <div class="row">            
          <div class="col-md-12 col-lg-12">              
                  <div class="container">
                  <div class="container" className="border mt-5 mb-5 pt-4 pb-4 pe-4 ps-4" style={{ position:"" ,fontSize:"18px",lineHeight:"1.5",borderRadius:'20px','backgroundColor':'white'}}>

                  <div class="base_header"><span><small class="bor_header_left"></small>WE ARE ADROIDCONNECTZ<small class="bor_header_right"></small></span>
                      <h2>Message from <mark style={{ color: "red", backgroundColor: "white" }}>Co-Founders</mark></h2>
                  </div>
                  
                  <div class="base_footer" key="1">
                      <p><br />
                      Welcome to the one stop solution provider for day to day apprehensions and inquisitiveness related to the dreams and career of student’s community. Life of a student is a journey from being a thinker to learner, learner to innovator and innovator to implementer which helps in realizing their dreams. 
                      </p>
                      <p>
                      We at AdroidConnectz embark your journey of life by connecting you with experts and mentors of industry & academia, vibrant community of innovators, entrepreneurs and researchers. Our Summer/ Winter Schools, latest courses on recent and emerging technologies with an insight of research and also expertise of our mentors will prove to be a boon in student’s life. Our courses are well designed under the supervision of domain experts that stand us apart from others.   
</p>
<p>
Discover courses on recent technologies as per research perspective, prestigious research conferences on emerging technologies, research mentorship, and career counseling. Join Adroid Connectz today and unleash your research potential.
</p>
<p>
Our aim is to build a healthy environment for Mentor-Mentee discussions related to research, startups and counseling.<br /><br />
We look forward to your best!
</p>       
<strong>Co - Founders</strong>                 
                  </div>  
                  <div className="row  mt-5"> 
    <div className="col-md-12 col-lg-4">
      <div className="container  mt-3 mx-5">
        <img src={img} style={{width: '70%','borderRadius':'50%'}} />
      </div>
    </div>           
    <div className="col-md-12 col-lg-8" >              
      <div className="container">
        <div className="base_header">
          <h2> <mark style={{ color: "red", backgroundColor: "white" }}>About </mark> the co-founder</h2>
        </div>
        <div className="base_footer" key={1}>
          <p>
          </p><ul>
            <li>Alumni of Delhi College of Engineering (Now Delhi Technological University), India.</li>
            <li>Earned a doctorate degree and post doctorate from Brazil.</li>
            <li>Included in the <b>“Top 2% scientist of the world”</b> list by Stanford 
              University, USA in 2020, 2021, 2022 with Rank 51 in AI &amp; Image Processing, Rank 423 in India overall and Rank 80604 in World overall.</li>
            <li>Highly cited researcher as per scopus and google scholar.</li>
            <li>Included in the <b>“Top 1% computer scientist”</b> list by Research.com in 2022, 2023.</li>
          </ul>
          <p />
        </div>  
      </div>
    </div>
  </div>
              </div>
              
              </div>
          </div>
          
      </div>
  </div>
 

  </div>
    )
  }
}


