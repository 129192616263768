import React from "react";
import style from "../CSS/header.module.css";

const Date=()=>{
  return(
    <div >
  <div className={style.marContainer} >
<h3>Important Dates</h3>
  <marquee  direction="up" className={style.marquee} style={{width:"25vw"}} >

  <p><b>Manuscript Submission Deadline:</b><br />
  <span className={style.colorDate}>November 15th, 2023</span></p>
  <hr />
<p>
<b>Notification of Acceptance / Rejection</b><br />
<span className={style.colorDate}>November 30th, 2023
</span>
<hr />
</p>
{/* <p>
<b>Final manuscript due</b><br />
<span className={style.colorDate}>November 15th, 2023</span>
<hr />
</p> */}
<p>
<b>Registration Deadline</b><br />
<span className={style.colorDate}>December 15th, 2023</span>
<hr />
</p>
<p>
<b>Registration Deadline(Early)</b><br />
<span className={style.colorDate}>November 10th, 2023</span>
<hr />
</p>
<p>
<b>Final Paper Due</b><br />
<span className={style.colorDate}>December 15th, 2023</span>
<hr />
</p>
<p>
<b>Conference Date</b><br />
<span className={style.colorDate}>January 28th, 2024</span>
<hr />
</p>

  </marquee>
  
  </div>
  </div>

 
);
};

export default Date;
