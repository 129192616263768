
import Mid from './src1/component/mid';  
import Navbar from "./navbar";
import Footer from "./Footer";
import Header from './Header';
import Lower from './Lower';
function Contact_us() {
  return (
   <div>
   <Header />
<Navbar />
<Mid />
<Lower/>
<Footer />


   </div>
  );
}

export default Contact_us;
