import React from 'react'
import Service1 from "./robo2.png"
import Service2 from "./laptop.png"
import Header from './Header'
import NavBar from './navbar'
import Lower from './Lower'
import Footer from './Footer'
import './CSS/schools.css'

const Schools = () => {
  return (
    <div>
    <Header />
    <NavBar />
        <div class="container" style= {{ marginTop: '2%',marginBottom: '2%'}}>
     <div class="base_header"><h1 className='text-center'>Our Schools</h1> 
    <p>Adroid connectz aims to provide students with amalgamation of few courses useful to them periodically in a year. We are periodically organizing summer and winter schools for students, academicians and industry people, who can attend these schools and improve their skill sets. These schools will be providing with live doubt sessions and projects with hands-on experience.</p>   
    </div>
    
    <div class="container">   
                <div className="course-container"> 
                <div>
                <h4 className='mob'>Ongoing Schools</h4>
                  <div
                    class="col-md-6 col-lg-4 col-xl-6"
                    style={{ marginTop: "2%" }}
                  >
                      <div class="card category-card" key="1">
                        <img
                          class="card-img-top w-100 d-block"
                          src={Service1}
                          alt="service1"
                        />
                        <div class="card-body">
                          <h4 class="card-title">International Summer School on Research Methods and Data Science 2023</h4>
                          <p>Organized jointly with Shaheed Sukhdev College of Business Studies, University of Delhi, India

This course is the amalgamation of Basic Research and Data Science courses. For details visit: <a href='https://www.adroidconnectz.com/rmds' target='__blank'>Summer School</a>
</p>
                        </div>
                      </div>
                  </div>  
                  </div>

                  <div>
                  
                  <h4 className='mob'>Upcoming Schools</h4>
                  <div
                    class="col-md-6 col-lg-4 col-xl-10"
                    style={{ marginTop: "2%" }}
                  >
                   
                      <div class="card category-card" key="1">
                        <img
                          class="card-img-top w-100 d-block"
                          src={Service2}
                          alt="service1"
                        />
                        <div class="card-body">
                          <h4 class="card-title">International Winter School on Emerging Technologies with Research Skillset</h4>
                        </div>
                      </div>
                  </div> 
                  </div>
                </div>
            </div>
        </div>
        <Lower />
        <Footer />
    </div>
  )
}

export default Schools