import React from 'react'
import Header from './Header'
import Navbar from './navbar'
import '../components/CSS/SummCSS.css'
import speak from "../components/speaker.jpg"
import Lower from './Lower'
import Footer from './Footer'

export default function Speakers() {
    return (
        <div>
            <Header/>
            <Navbar/>
            <div className="body-speak">
                <div>
                    <h1 style={
                        {color: "rgb(220 53 69)"}
                    } className="header-summ">Speakers</h1>
                    <br/>
                    <h6>All the speakers are from Reputed Indian and International Universities and Industry.</h6>
                </div>
                <img src={speak}
                    className='speaker-img'></img>
            </div>
            <Lower/>
            <Footer/>
        </div>

    )
}
