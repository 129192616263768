import React from "react";
import style from "../../CSS/header.module.css";
import NavBar from "../../navbar";
import Header from "../../Header";
import Lower from "../../Lower";
import Footer from "../../Footer";


// function loadScript(src) {
// 	return new Promise((resolve) => {
// 		const script = document.createElement('script')
// 		script.src = src
// 		script.onload = () => {
// 			resolve(true)
// 		}
// 		script.onerror = () => {
// 			resolve(false)
// 		}
// 		document.body.appendChild(script)
// 	})
// }

const Registration = () => {

//     async function displayRazorpay() {
// 		const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

// 		if (!res) {
// 			alert('Razorpay SDK failed to load. Are you online?')
// 			return
// 		}

// 		const data = await fetch('http://localhost:8000/razorpay', 
// 		{ method: 'POST' }).then((t) =>
// 			t.json()
// 		)

// 		console.log(data)

// 		const options = {
// 			key: 'rzp_test_0Kpqhy50iiKUgM',
// 			currency: data.currency,
// 			amount: data.amount.toString(),
// 			order_id: data.id,
// 			name: 'ICICC-CONF REGISTRATION',
// 			description: 'Thank you.',
// 			// image: "",
//             // callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
// 			handler: function (response) {
// 				alert('Payment successful.')
// 				alert(response.razorpay_payment_id)
// 				alert(response.razorpay_order_id)
// 				alert(response.razorpay_signature)
				
// 			},
// 			prefill: {
// 				name:"",
// 				email: '',
// 				phone_number: ''
// 			}
// 		}
// 		const paymentObject = new window.Razorpay(options)
// 		paymentObject.open()
// 	}

  const url1="https://pages.razorpay.com/pl_IOV8wAeLy4uMEQ/view";
  const url2="https://paypal.me/ICICCConference?locale.x=en_GB";

    return (
      <div>
      <Header />
    <NavBar />
    	  
    <div  style={{'backgroundColor':'red','padding':'4px'}}>
		<h2 class={style.abtHeader}>Registration</h2>
    </div>
		<hr />
    <div className={style.container}>	
    <strong>FOR SCOPUS INDEXED JOURNAL REGISTRATION</strong>
    <hr />
		<p>Once Your paper is accepted, the registration process begins. you have to complete the following steps.<br></br>
		<b>Step 1 - Registration Fee:</b>It is mandatory for at least one author of an accepted paper to register in order for the paper to appear in the proceedings and included in the Technical Program.</p>
		
		<ul>
			
			<li>Indian Authors: INR 25000</li>
			<li>International Authors: USD 350</li>
		
		</ul>
    <hr />
    <strong>FOR SCOPUS BOOK CHAPTER REGISTRATION</strong>
    <hr />
		<p>Once Your paper is accepted, the registration process begins. you have to complete the following steps.<br></br>
		<b>Step 1 - Registration Fee:</b>It is mandatory for at least one author of an accepted paper to register in order for the paper to appear in the proceedings and included in the Technical Program.</p>
		
		<ul>
			
			<li>Indian Authors: INR 8000</li>
			<li>International Authors: USD 150</li>
		
		</ul>
    <hr />
    <strong>FOR ELSEVIER SSRN REGISTRATION</strong>
    <hr />
		<p>Once Your paper is accepted, the registration process begins. you have to complete the following steps.<br></br>
		<b>Step 1 - Registration Fee:</b>It is mandatory for at least one author of an accepted paper to register in order for the paper to appear in the proceedings and included in the Technical Program.</p>
		
		<ul>
			
			<li>Indian Authors: INR 5000</li>
			<li>International Authors: USD 120</li>
		
		</ul>

    <br /><br></br>
		<h5><b>Registration Account Details</b><br></br></h5>

		<div className={style.paymentCont}>
		<p style={{fontWeight:"700",fontSize:"17px"}}>Pay via Bank Transfer</p><br></br>
		<div style={{width:"70vw",margin:"auto"}}>
		<table class="table table-bordered table-hover" style={{textAlign:"left"}}>
            <tbody>
              <tr>
                <td><b>ACCOUNT NAME </b></td>
                <td> ADROID CONFERENCES</td>
              </tr>
              <tr>
                <td><b>ACCOUNT NUMBER </b></td>
                <td> 510909010207579</td>
              </tr>
              <tr>
                <td><b>BANK </b></td>
                <td> City Union Bank</td>
              </tr>
              <tr>
                <td><b>BRANCH </b></td>
                <td> Sector-8, Rohini Delhi </td>
              </tr>
              <tr>
                <td><b> BRANCH CODE </b></td>
                <td> 244</td>
              </tr>
              <tr>
                <td><b> IFSC CODE </b></td>
                <td> CIUB0000244 </td>
              </tr>
              <tr>
                <td><b> MICR </b></td>
                <td> 600054027</td>
              </tr>
              <tr>
                <td><b> SWIFT CODE </b></td>
                <td> CIUBIN5M</td>
              </tr>
              <tr>
                <td><b> ACCOUNT TYPE </b></td>
                <td> CURRENT</td>
              </tr>
            </tbody>
          </table>
</div>
		{/* <b>Special Discount of 10% for Universal Inovator (UI) Member</b> */}
    </div>
    <b>Kindly pay the registration fees through online transaction only and mail us the receipt/transaction number along with your paper id and registered author id card.</b>
   
    </div>
    <Lower />
    <Footer />
    </div>
    )

}
export default Registration;