function Footer(){
    return(
        <div  className=" pt-3 pb-3" style={ {
            bottom:'0',
            width: '100%' ,
            backgroundColor: "red",
            color: "white",
            textAlign: "center"
        }}>



        ©<span>{new Date().getFullYear()}</span> ADROIDCONNECTZ PRIVATE LIMITED | All Rights Reserved.
        

        </div>
    )
}
export default Footer