import React from 'react'

const Week4 = () => {
  return (
    <>
<div>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 22 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp;Integration and Deployment of Web Services</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(247, 55, 58)'}}>Topic of the Day</span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Integration and Deployment of Web Services&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>| Mr Sahib Singh, Mr Yuvraj Singh |</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://github.com/ssahibsingh/MERN-Session-TSS23/" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Topics: Integration, Frontend Deployment, Backend Deployment</span></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Tools used: VS Code, Postman, Github, Github Desktop, Vercel, Netlify</span></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-26: Integrate fake store API</span></li>
      </ul>
    </li>
  </ul>
  <p><span style={{fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>NA</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black'}}>&nbsp;</span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}>Assignment / Mini Project&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-26: Integrate fake store API&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a><span style={{fontSize: 16}}><br /></span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}>Daily Task</span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 23 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp;NextJS</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(247, 55, 58)'}}>Topic of the Day</span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>NextJS</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>| Mr Sahib Singh, Mr Yuvraj Singh |&nbsp;</span><a href="https://github.com/ssahibsingh/MERN-Session-TSS23/" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Topics:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black'}}>Introduction to NextJS, Server side rendering, Static site generation, Routing, Dynamic Rendering, Data Fetching, Next API routes</span></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Tools used: VS Code</span></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-27: Blogging Platform</span></li>
      </ul>
    </li>
  </ul>
  <p><span style={{fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>NextJS&nbsp;|&nbsp;</span><a href="https://nextjs.org/" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black'}}><br />&nbsp;&nbsp;</span></li>
      </ul>
    </li>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}>Assignment / Mini Project&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-27: Blogging Platform&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}>Daily Task</span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 24 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp;Web Development using Django - Part-1</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(247, 55, 58)'}}>Topic of the Day</span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Introduction to Django | Mr Tarik Bhateja, Mr.Harjaspreet Singh&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://github.com/harjasdt/ThaparSummerSchool_2k23" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a>
          <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Introduction to Django Framework</span></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Django Vs MERN</span></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Setup and Introduction to the Directory Structure of Django</span></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>How Django Works (MVT)</span></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Introduction to Django</span>
              <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Implementation of Django's directory system</span></li>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Urls and view layer(app)</span></li>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Model layer</span></li>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Data Access through the Admin panel</span></li>
              </ul>
            </li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Getting Started with Our First App</span></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-28: PDF Protect with Password WebApp&nbsp;|&nbsp;</span><a href="https://github.com/Tarik-Bhateja/ThaparSummerSchool_2k23/tree/master/MiniProjects" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Django Cheat Sheet |&nbsp;</span><a href="https://dev.to/ericchapman/my-beloved-django-cheat-sheet-2056" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Django’s Official Documentation |&nbsp;</span><a href="https://docs.djangoproject.com/en/4.2/" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>8 Reasons to Learn Django |&nbsp;</span><a href="https://www.youtube.com/watch?v=758Z3uiKG4c" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}><>Resource of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Github Repository |&nbsp;</span><a href="https://github.com/harjasdt/ThaparSummerSchool_2k23" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>[Video] Django Setup |&nbsp;</span><a href="https://youtu.be/HdoPg2e_m9s" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black'}}>&nbsp;</span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}>Assignment / Mini Project&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-28: PDF Protect with Password WebApp&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}>Daily Task</span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 25 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp; Web Development using Django - Part-2</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(247, 55, 58)'}}>Topic of the Day</span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Projects, ML Integration and Deployment | Mr Tarik Bhateja, Mr.Harjaspreet Singh</span>
          <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-29: Cold Emailing WebApp&nbsp;|&nbsp;</span><a href="https://github.com/harjasdt/ThaparSummerSchool_2k23/tree/master/MiniProjects" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-30: Teachable Machine Integration&nbsp;|&nbsp;</span><a href="https://github.com/harjasdt/ThaparSummerSchool_2k23/tree/master/MiniProjects" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-31: Placement predictor WebApp&nbsp;|&nbsp;</span><a href="https://github.com/harjasdt/ThaparSummerSchool_2k23/tree/master/MiniProjects" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Website Deployment using Linode, AWS, Azure&nbsp;|&nbsp;</span><a href="https://github.com/harjasdt/ThaparSummerSchool_2k23/tree/master/MiniProjects" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Joblib V/s Pickle python library |&nbsp;</span><a href="https://dev.to/ericchapman/my-beloved-django-cheat-sheet-2056" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Gunicorn and Ngnix</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://dev.to/ericchapman/my-beloved-django-cheat-sheet-2056" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black'}}>&nbsp;</span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Resource of the Day</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Github Repository |&nbsp;</span><a href="https://github.com/Tarik-Bhateja/ThaparSummerSchool_2k23/tree/master/MiniProjects" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Digital Ocean Blog |&nbsp;</span><a href="https://www.digitalocean.com/community/tutorials/how-to-set-up-django-with-postgres-nginx-and-gunicorn-on-ubuntu-20-04" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Error no 13 Permission Denied |&nbsp;</span><a href="https://django.fun/en/qa/195432/" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Linode Code |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.youtube.com/redirect?event=video_description&redir_token=QUFFLUhqbGc3emtkdzVqcUp5V0dmQnE3azJ5MWdRaHNNQXxBQ3Jtc0tuSFBFblk0MU5TcUk4ZDZBUTdodG95bFE0UWhheGF5RHhXb0o4RDF1cVlfNklJVjg3bGF6NXQ2VDl0ZmJOYVp4c0pBLUt6LXRIcXNnWFZxN1FIckpEZ2pNN1VYajZVRk43U014dl9aYnN1Qk05LXZMWQ&q=https%3A%2F%2Flinode.com%2Fcwh&v=vYRnoFQnxak" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.youtube.com/watch?v=vYRnoFQnxak" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Video Link</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}>Assignment / Mini Project&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-29: Cold Emailing WebApp&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-30: Teachable Machine Integration&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-31: Placement predictor WebApp&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-32: Google Translate Clone |&nbsp;</span><a href="https://github.com/harjasdt/ThaparSummerSchool_2k23/tree/master/MiniProjects" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>&nbsp;</span></a><span style={{color: 'black', border: '1pt none windowtext'}}>|</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-33: QR Generator WebApp |&nbsp;</span><a href="https://github.com/harjasdt/ThaparSummerSchool_2k23/tree/master/MiniProjects" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>&nbsp;</span></a><span style={{color: 'black', border: '1pt none windowtext'}}>|</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-34: Mashup Generator WebApp |&nbsp;</span><a href="https://github.com/harjasdt/ThaparSummerSchool_2k23/tree/master/MiniProjects" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>&nbsp;</span></a><span style={{color: 'black', border: '1pt none windowtext'}}>|</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}>Daily Task</span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 26 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp;Product Development Lifecycle, SEO, and Coding Tricks (JUGAAD)</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(247, 55, 58)'}}>Topic of the Day</span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Product Development Lifecycle, SEO, and Coding Tricks (JUGAAD) | Mr. Sahib Singh</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/ssahibsingh/development-lifecycle-seo-jugaad" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a>
          <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Topics:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span>
              <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Product Development Lifecycle,</span></li>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>SEO,</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span></li>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Meta tags,</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span></li>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Flask Introduction,&nbsp;</span></li>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Some Tools like Google Search Console,&nbsp;</span></li>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Microsoft Clarity</span></li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Resource of the Day</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Github Repository |&nbsp;</span><a href="https://github.com/ssahibsingh/development-lifecycle-seo-jugaad" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Metatags.io |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://metatags.io/" target="_blank"><span style={{color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Google Search Console |&nbsp;</span><a href="https://search.google.com/search-console/about" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Microsoft Clarity |&nbsp;</span><a href="https://clarity.microsoft.com" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Image Bot |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://imgbot.net" target="_blank"><span style={{color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Sitemaps Generator |&nbsp;</span><a href="https://www.xml-sitemaps.com/" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}>Daily Task</span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 27 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>Web Scraping and Data Extraction</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(247, 55, 58)'}}>Topic of the Day</span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Web Scraping and Data Extraction | Mr. Gurtaran Singh&nbsp;|&nbsp;</span><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span>
          <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Topics:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span>
              <ul style={{listStyleType: 'square', fontSize: 'initial', fontFamily: 'initial'}}>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Introduction to Web Scraping</span></li>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Ethics of Web Scraping</span></li>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Web Scraping Workflow</span></li>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Scraping Thapar Summer School Website using BeautifulSoup and Requests</span></li>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Introduction to Text Scraping and Data Extraction</span></li>
                <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Text Extraction from Images, PDFs, CSVs etc</span></li>
              </ul>
            </li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-35: Scraping raw data and getting useful information from Flipkart website |&nbsp;</span><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-36: Multi-Text Extraction from Images |&nbsp;</span><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  <p><span style={{fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Github Repository |&nbsp;</span><a href="https://github.com/gurtaransingh/scraping/" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Web Scraping Book |&nbsp;</span><a href="https://github.com/gurtaransingh/scraping/blob/main/Web%20Scraping%20with%20Python%2C%202nd%20Edition.pdf" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><span style={{color: 'rgb(32, 206, 136)'}}>Resource of the Day</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Github Repository |&nbsp;</span><a href="https://github.com/gurtaransingh/scraping/" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project 37: Beautiful Soup Code |&nbsp;</span><a href="https://github.com/gurtaransingh/scraping/blob/main/Beautiful_Soup.ipynb" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project 38: Text Extraction |&nbsp;</span><span style={{color: 'rgb(18, 39, 224)'}}>Click Here</span></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>PPT Link |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/gurtaransingh/scraping/blob/main/Introduction%20to%20Web%20Scraping%20%26%20Text%20Extraction.pdf" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}>Assignment / Mini Project&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project 37: Beautiful Soup Code |&nbsp;</span><a href="https://github.com/gurtaransingh/scraping/blob/main/Beautiful_Soup.ipynb" target="_blank">Click Here</a><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project 38: Text Extraction |&nbsp;</span><span style={{color: 'rgb(18, 39, 224)'}}>Click Here</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
      </ul>
    </li>
  </ul>
  
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black'}}>&nbsp;</span></p>
 
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}>Daily Task</span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif'}}><strong><br /></strong></span></p>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 28 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'rgb(32, 206, 136)'}}>Daily Task</span><span style={{color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial', fontFamily: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{border: '1pt none windowtext', color: 'black'}}>|</span><span style={{color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank">Click Here</a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>1-Activity that I love1000</span></li>
      </ul>
    </li>
  </ul>
</div>

    </>
  )
}

export default Week4