import React from 'react'
import Header from './Header'
import Navbar from './navbar'
import '../components/CSS/SummCSS.css'
import {Link} from 'react-router-dom';

import logo1 from "../components/adroidlogo..png"
import logo2 from "../components/ssb.jpeg"

import img from "./laptop.jpg"
import Lower from './Lower'
import Footer from './Footer'
function SummSchool() {
    return (
        <div>
            <Header/>
            <Navbar/>
            <div style={
                {
                    "display": "block",
                    "overflow": "auto"
                }
            }>

                <div className="body-summ">
                    <div className='right'>
                        <img src={logo1}
                            className='logo'></img>
                        {/* <img src={logo2}
                            className='logo'></img> */}
                    </div>
                    <div className='page-content'>

                        <h1 className="header-summ">
                            International Summer School on Research Methods and Data Science</h1>
                        <p className="subscript">Organized by Adroid Connectz Pvt Ltd


                            <br/><br/>
                            <b>1st Batch starts from 1st July 2024 to 9th August 2024 | <br />Monday to Friday<br />
                            </b>
                            <br/>
                            <p style={
                                {"color": "red"}
                            }>

                           <b>Six Weeks Internship cum Training Program for UG/PG/PhD Students in Online Mode.</b>
                            </p>
                        </p>
                        <span onClick={
                            () => window.location.replace("/rmds/#details")
                        }>
                            <button className='btn-rmds' href="/rmds#details">Learn More!</button>
                        </span>
                        <Link to="/rmds/reg">
                            <button className='btn-rmds-2' href="/rmds/reg">Register</button>
                        </Link>
                    </div>


                </div>
                <div className='page-cont-2'>
                    <img src={img}
                        className='page-img'></img>
                    <div className="details" id="details">
                        <h2>Programme Details</h2>


                        <ul>
                            <li>An exclusive course on Research with access to all the video content</li>
                            <li>Learn how to do research, develop reading/writing skills and critical thinking</li>
                            <li>Improving professional/personal development</li>
                            <li>Learn python and R programming languages</li>
                            <li>Learn Machine learning (supervised/unsupervised)</li>
                            <li>Learn deep learning (CNN, RNN, LSTM, GAN, Auto-encoders etc)</li>
                            <li>Optimization techniques (Random/genetic algorithm)</li>
                            <li>2-3 major projects and 5-6 minor projects</li>
                            <li>The TOP three projects will be awarded</li>
                            <li>Hands-on practice on IoT</li>
                            <li>Learn how to write research paper and patents</li>
                            <li>Many more (introduction to Blockchain, Flask, Django, JavaScript, NodeJS, React, Exploring machine learning competitions such as www.kaggle.com)</li>
                            <li>Resource person from India/Abroad Academics and Industry</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Lower/>
            <Footer/>
        </div>
    )
}

export default SummSchool
