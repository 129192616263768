import React from 'react';
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CssBaseline } from '@mui/material';
import Header from '../Header'
import Navbar from '../navbar'
import Footer from '../Footer'
import Lower from '../Lower'
import classes from "./WebDevelopmentCourse.module.css"
import headerImage from '../images/Basic-Research.jpg'
import { Nav } from 'react-bootstrap';

const courseData = {
  "title": "Basic Research",
  "instructor": "Dr. Deepak Gupta, CSE Dept, Maharaja Agrasen Institute of Technology, Delhi",
  "duration": "1 Week",
  "price": "₹799/-",
  "subtitle": "A step-by-step research guidance",
  "outcome": "Student can be able to read the articles, understands them, formulate the problem, and write a survey/review/empirical article using any of the problem-solving approaches in an ethical way under the able guidance from a mentor (having research and paper publication experience) and being able to publish his/her article in either a magazine or national/international conference using the various tools and software for paper publishing learnt during the course.",
  "benefits": [
    "Improves professional and personal development",
    "Enhances reading skills",
    "Develop writing skills",
    "Develop critical thinking",
    "Being able to separate facts and opinions",
    "Inculcates Research Ethics",
    "Improves acumen skills"
  ],
  "after":[    
    "Problem formulation/identification",
    "Apply approaches to problem solving",
    "Read/Write papers",
    "Present a paper in conference",
    "Apply publication ethics",
    "Publish papers",
    "Use various research tools and software",
  ],
  "courseContent": [
    "Introduction",
    "What is Research",
    "Types of Research",
    "Components of Research",
    "Why do we code and its importance.",
    "Basic elements of Research paper writing",
    "Developing advanced reading skills",
    "Research Ethics",
    "Research Process",
    "Research Question",
    "Start your research?",
    "Tools for writing a Research Paper",
    "How to write an academic research paper or report",
    "How to write a good paper for a journal",
    "Writing and presenting a paper in conference/seminar/symposia"
  ],
  "instructorDetails": {
    "name": "Dr. Deepak Gupta (B.Tech., M.E., and Ph. D.)",
    "achievements": [
      "Featured in the list of top 2% scientist/researcher databases worldwide",
      "Ranked #1 as a researcher in the field of healthcare applications (as per Google Scholar citation) and Ranked #51 in India among Top Scientists 2023 by Research.com",
      "Completed Post-Doc from the National Institute of Telecommunications (Inatel), Brazil, in 2018",
      "Co-authored more than 207 journal articles, including 168 SCI papers and 45 conference articles",
      "Authored/edited 60 books, published by IEEE-Wiley, Elsevier, Springer, Wiley, CRC Press, DeGruyter, and Katsons",
      "Filled four Indian patents",
      "Convener of the ICICC, ICDAM, ICCCN, ICIIP & DoSCI Springer conferences series",
      "Associate Editor of Computer & Electrical Engineering, Expert Systems, Alexandria Engineering Journal, Intelligent Decision Technologies",
      "Recipient of the 2021 IEEE System Council Best Paper Award",
      "Working towards promoting Startups and also serving as a Startup Consultant",
      "Series editor of 'Elsevier Biomedical Engineering' at Academic Press, Elsevier, 'Intelligent Biomedical Data Analysis' at De Gruyter, Germany, and 'Explainable AI (XAI) for Engineering Applications' at CRC Press",
      "Appointed as Consulting Editor at Elsevier",
      "Accomplished productive collaborative research with grants of approximately $144000 from various international funding agencies",
      "Co-PI in an International Indo-Russian Joint project of Rs 1.31CR from the Department of Science and Technology"
    ]
  }
}

const BasicResearchCourse = () => {
  return (
    <React.Fragment>
      <Header />
      <Navbar />
      <Container className={classes.Container}>

        <CssBaseline />
        <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>
          {courseData.title}
        </Typography>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          {courseData.subtitle}
        </Typography>



        <div className={classes.imgCont}>
          <img src={headerImage} className='imgres' alt="a" />

        </div>
        <Nav.Link href="https://buy.stripe.com/5kAeWTfob7wj316dQR" target="_blank" style={{ textAlign: "center", minHeight: "90%", marginTop: "2%", marginBottom: "2%" }}><button type="button" class="btn btn-success">Buy Now</button></Nav.Link>
        <div className={classes.con}>
          <Typography variant="h6" className={classes.Typography_gutterBottom}>
            Instructor: {courseData.instructor}
          </Typography>
          <Typography variant="h6" className={classes.Typography_gutterBottom}>
            Duration: {courseData.duration}
          </Typography>
          <Typography variant="h6" className={classes.Typography_gutterBottom}>
            Price: {courseData.price}
          </Typography>
        </div>
        <Typography variant="h4" gutterBottom>
          Outcome:
        </Typography>
        <p className='h7'>{courseData.outcome}</p>
        <div>
          <Typography variant="h4" gutterBottom>
            List of benefits/achievements after the completion of Basic Research Course

          </Typography>
          <b>Benefits:</b>
          <ul>
            {courseData.benefits.map((item, index) => (
              <Typography component="li" key={index}>
                {item}
              </Typography>
            ))}
          </ul>
          <hr></hr>
          <b>Able to do after completion of this course:</b>
          <ul>
            {courseData.after.map((item, index) => (
              <Typography component="li" key={index}>
                {item}
              </Typography>
            ))}
          </ul>
        </div>

        <div>
          <Typography variant="h5" gutterBottom>
            Course Content:
          </Typography>
          <ol>
            {courseData.courseContent.map((item, index) => (
              <Typography component="li" key={index}>
                {item}
              </Typography>
            ))}
          </ol>
          <hr></hr>
        </div>
        <div>
          <Typography variant="h5" gutterBottom>
            Instructor Details:
          </Typography>
          <Typography style={{ color: "red" }}>
            Name: {courseData.instructorDetails.name}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Achievements:
          </Typography>
          <ul>
            {courseData.instructorDetails.achievements.map((item, index) => (
              <Typography component="li" key={index}>
                {item}
              </Typography>
            ))}
          </ul>
          <hr></hr>
        </div>
        <p>
          <span style={{ fontWeight: "bolder" }}>Please Note:</span>  The content of the course “Basic Research” has been designed by the group of reputed researchers who have long trails of success in research related activities like publishing research papers in reputed International Journals, and Conferences; edited and authored books with reputed publishers; Have been part of funded international research projects headed by Dr. Deepak Gupta. </p>
      </Container>
      <Lower />
      <Footer />
    </React.Fragment>
  );

};

export default BasicResearchCourse;
