import React from 'react'
import Header from './Header'
import Navbar from './navbar'
import '../components/CSS/SummCSS.css'
import Lower from './Lower'
import Footer from './Footer'
import vid1 from "./videos/vid1.mp4"
import vid2 from "./videos/vid2.mp4"
import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Video() {
    return (
        <div>
            <Header/>
            <Navbar/>
            
            <div className="">
            
                <div>
                    <h1 style={
                        {color: "rgb(220 53 69)"}
                    } className="header-summ">Videos</h1>
                    <br/>
                    
            <div className="videoDiv">
            <video width={320} height={240} controls>
                    <source src={vid1} type="video/mp4" />
  <source src={vid1} type="video/ogg" />
  Your browser does not support the video tag.
</video>
                <video width={320} height={240} controls>
  <source src={vid2} type="video/mp4" />
  <source src={vid2} type="video/ogg" />
  Your browser does not support the video tag.
</video>
            </div>
                         
            
                    
                    {/* <Container>
                    <Row>
                    <Col>
                    
                    

                    </Col>
                    <Col>
                    
                    </Col>
                    </Row>
                    </Container> */}
                   
                </div>
                    
            </div>
            <Lower/>
            <Footer/>
        </div>

    )
}
