import React from 'react';
import './curve.css'

function Curve() {
    return (
        <>
        <div className="curve_container">
            <figure className="img_curve"></figure>
        </div>
    </>
    )
}

export default Curve;
