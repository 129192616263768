import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./CSS/Navbar.css"

function NavBar() {
   
    return (
      <div className='navCent'>
      <Navbar collapseOnSelect expand="lg"  sticky="top" className='NavCol'>
      <Container style={{"justifyContent": "space-between"}} >         
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="navCent">
         
            <Nav.Link href="/"  style={{color:'red',fontSize:18,margin:"0 10px"}}>        
                    Home          
                </Nav.Link>
            <Nav.Link href="/#aboutus"  style={{color:'red',fontSize:18,margin:"0 10px"}}>About Us</Nav.Link>
            <Nav.Link href="/features"  style={{color:'red',fontSize:18,margin:"0 10px"}}>Features</Nav.Link>
            
            <NavDropdown  title={<span style={{color:"red",fontSize:18,margin:"0 10px"}}>Courses</span>} id="collasible-nav-dropdown" >
              <NavDropdown.Item href="/courses">Research and Technical-Research</NavDropdown.Item>              
              <NavDropdown.Item href="/ResearchSummary">Research Summary</NavDropdown.Item> 
             
            </NavDropdown>
            
            <NavDropdown  title={<span style={{color:"red",fontSize:18,margin:"0 10px"}}>Summer School</span>} id="collasible-nav-dropdown" >
              <NavDropdown.Item href="/rmds">Home</NavDropdown.Item>
              
              <NavDropdown.Item href="/rmds/reg">
              Registration
              </NavDropdown.Item>
              
              <NavDropdown.Item href="/rmds/schedule">Schedule</NavDropdown.Item>
               
              <NavDropdown.Item href="/rmds/speakers">Speakers</NavDropdown.Item>
              {/* <NavDropdown.Item href="/rmds/poster">Poster</NavDropdown.Item> */}
              {/* <NavDropdown.Item href="/videos">Videos</NavDropdown.Item> */}
              <NavDropdown.Item href="/rmds/resource">Resources</NavDropdown.Item>
              
             
            </NavDropdown>
{/* In future use */}

            {/* <Nav.Link href="/conferences"  style={{color:'red',fontSize:18,margin:"0 10px"}}>Conferences</Nav.Link>
            <Nav.Link href="/schools"  style={{color:'red',fontSize:18,margin:"0 10px"}}>Schools</Nav.Link>       
            <Nav.Link href="/internships"  style={{color:'red',fontSize:18,margin:"0 10px"}}>Internships</Nav.Link>  */}
            {/* <Nav.Link href="/workshop"  style={{color:'red',fontSize:18,margin:"0 10px"}}>Workshop</Nav.Link> */}
                        
            <Nav.Link href="/mentors"  style={{color:'red',fontSize:18,margin:"0 10px"}}>Mentors</Nav.Link>
            <Nav.Link href="/journals"  style={{color:'red',fontSize:18,margin:"0 10px"}}>Journals</Nav.Link>
            <Nav.Link href="https://learn.adroidconnectz.com/#/"  style={{color:'red',fontSize:18,margin:"0 10px"}} target='blank'>Join Us</Nav.Link>
            {/* <NavDropdown  title={<span style={{color:"red",fontSize:18,margin:"0 10px"}}>ICHBA 2024</span>} id="collasible-nav-dropdown" >
              <NavDropdown.Item href="/ichba">Home</NavDropdown.Item>
              <NavDropdown.Item href="/ichba/callforpapers">Call for Papers</NavDropdown.Item>
              <NavDropdown.Item href="/ichba/registration">Registration</NavDropdown.Item>   
              <NavDropdown.Item href="/ichba/papersubmission">Paper Submission</NavDropdown.Item>   
              <NavDropdown.Item href="/ichba/downloads">Downloads</NavDropdown.Item>                           
            </NavDropdown> */}
          </Nav>
          {/* <Link to='https://play.google.com/store/apps/details?id=com.adroid.adroidconnectz&pli=1'><img src={playstore} style={{width:'125px'}}/></Link>*/}
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>

    )
}
export default NavBar;
