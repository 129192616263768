import React from 'react'
import Service1 from "./images/icccn.png"
import Header from './Header'
import NavBar from './navbar'
import Lower from './Lower'
import Footer from './Footer'
import conf from './images/conf.jpg'
import './CSS/conf.css'

const Conferences = () => {
  return (
    <div>
    <Header />
    <NavBar />
        <div class="container" style= {{ marginTop: '2%',marginBottom: '2%'}}>
     <div class="base_header"><h1 className=" text-center">Conferences</h1> 
     <div className="imgCont">
    <p className='pe-3' style={{textAlign:'justify'}}>Adroid Connectz organizes conferences with the objective of bringing together scientists, professors, research scholars, students and industrial experts in the field of engineering, applied sciences, management and other domains to a common forum. The primary goal of the conference is to promote the exchange of innovative scientific information between researchers, developers, engineers, students, and practitioners. Another goal is to promote the transformation of fundamental research into institutional and industrialized research and to convert applied exploration into real time application.</p>  
    <img src={conf} className='imgCon'/> 
    </div>
    </div>
    <div style={{'backgroundColor':'red','marginTop':'40px',borderRadius:"20px"}}>
      <div class="container" style={{margin: '',textAlign:'justify',}}>
      <div class="row ">            
          <div class="col-md-12 col-lg-12 mt-3 mb-3 pt-2 pe-1 ps-1" >              
                  <div class="container">
                  <div class="container"  style={{ position:"" ,fontSize:"18px",borderRadius:'20px','backgroundColor':'red'}}>

                  <div class="base_header" >
                      <h3 style={{backgroundColor:"black",color: "white"}} className='p-2 text-center'> Highlights
                     
                      </h3>
                  </div>
                  
                  <div class="base_footer" key="1" style={{color:"white"}}>
                      <p><br />
                      <ul>
                      <li>The conference focusses in bridging the gap between Industry and academia by organizing the workshops by the industry experts on latest technologies for the participants.</li>
                      <li>The conference is anticipated to attract a large number of high quality submissions and stimulate the cutting-edge research discussions among many academic pioneering researchers, scientists, industrial engineers, students from all around the world.</li>
                      <li>Another goal of this conference is to provide opportunities for academics to receive informal in-depth feedback through discussions, and to enable them to establish contact with professionals in various countries and institutions and also to encourage regional and international communication and collaboration.</li>
                      <li>Provide common platform for academic pioneering researchers, scientists, engineers and students to share their views and achievements.</li>
                      <li>Focus on innovative issues at international level by bringing together the experts from different countries.</li>

                      </ul>                     
</p>       
                
                  </div>  
                 
              </div>
              
              </div>
          </div>

          
      </div>
  </div>
 

  </div>
    
    <div class="container mt-5">    
                <div class="row">           

                  <div>
                  <h4>Upcoming Conferences</h4>
                  <div
                    class="col-md-6 col-lg-4 col-xl-4"
                    style={{ marginTop: "2%" }}
                  >
                   
                      <div class="card category-card" key="1">
                        <img
                          className="card-img-top d-block"
                          src={Service1}
                          alt="service1"
                          height={'150px'}
                        />
                        <div class="card-body">
                          <h4 class="card-title">International Conference on Computing and Communication Networks (ICCCN 2023) </h4>
                          <p>It is being held at Manchester Metropolitan University, United Kingdom on Nov 17-18, 2023. For details visit <a href='https://icccn.co.uk/' target='__blank'>ICCCN</a></p>
                        </div>
                      </div>
                  </div> 
                  </div>
                </div>
            </div>
        </div>
        <Lower />
        <Footer />
    </div>
  )
}

export default Conferences