
  import React from 'react';
  import { Link} from 'react-router-dom'
  // import {FaGooglePlay,FaAppStore } from "react-icons/fa";
  import playstore from "./images/playstore.png"



  import logo from "./adroidlogo.png"

  function Lower(){
      return(
        <div className="pt-3 pb-3" style={{ backgroundColor: "black", color: "white" }}>
  <div className="container">
    <div className="row">
      <div className="col-md-3 mb-5">
        <div className="mb-5">
          <a href={() => false}>
            <img src={logo} alt="" width="200px" height="auto" />
          </a>
        </div>
        <p>
          Adroidconnectz is an online platform to connect students with mentors and provide them with the opportunity to grow and build their career.
        </p>
        <Link to='https://play.google.com/store/apps/details?id=com.adroid.adroidconnectz&pli=1'>
          <img src={playstore} style={{ width: '225px' }}/>
        </Link>
        
      </div>
      <div className="col-md-3 mb-5">
        <h3 className="mb-4">Useful Links</h3>
        <ul className="list-unstyled">
          <li>
            <a href="#aboutus" style={{ textDecoration: 'none', color: "white" }}>About Us</a>
          </li>
          
          <li>
            <Link to="/features" style={{ textDecoration: 'none', color: "white" }}>Features</Link>
          </li>
          <li>
          <Link to="https://learn.adroidconnectz.com/#/" style={{ textDecoration: 'none', color: "white" }}>Join Us</Link>
          </li>
          <li>
            <Link to="/contact" style={{ textDecoration: 'none', color: "white" }}>Contact Us</Link>
          </li>
        </ul>
        <div className="mt-4 pt-4 pb-4">
          <h3>Connect us</h3>
          <a href="https://youtube.com/@adroidconnectz" target="_blank" rel="noopener noreferrer" className="me-2">
            <i className="fa fa-youtube" style={{ fontSize: "24px", color: "white" ,padding:"10px" }}></i>
          </a>
          <a href="https://instagram.com/adroidconnectz" target="_blank" rel="noopener noreferrer" className="me-2">
            <i className="fa fa-instagram" style={{ fontSize: "24px", color: "white" ,padding:"10px"}}></i>
          </a>
          <a href="https://www.facebook.com/adroidconnectz" target="_blank" rel="noopener noreferrer" className="me-2">
            <i className="fa fa-facebook" style={{ fontSize: "24px", color: "white" ,padding:"10px" }}></i>
          </a>
          <a href="https://twitter.com/adroidconnectz" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-twitter" style={{ fontSize: "24px", color: "white" ,padding:"10px"}}></i>
          </a>
        </div>
      </div>
<div className="col-md-3 mb-5">
        <h3 className="mb-4">Payment</h3>
        <ul className="list-unstyled">
          <li>
            <a href='https://merchant.razorpay.com/policy/LaQx4xCQO4f7jf/privacy' style={{ textDecoration: 'none', color: "white" }}>Privacy Policy</a>
          </li>
          <li>
            <a href='https://merchant.razorpay.com/policy/NRKbNe18TD4Niy/terms' style={{ textDecoration: 'none', color: "white" }}>Terms and Conditions</a>
          </li>
          <li>
            <Link to="https://merchant.razorpay.com/policy/NRKbNe18TD4Niy/refund" style={{ textDecoration: 'none', color: "white" }}>Cancellation and Refund Policy</Link>
          </li>
          <li>
            <Link to="https://merchant.razorpay.com/policy/NRKbNe18TD4Niy/shipping" style={{ textDecoration: 'none', color: "white" }}>Shipping and Delivery Policy</Link>
          </li>
        </ul>
      </div>
      <div className="col-md-3">
        <h3 className="mb-3">Know More About Us</h3>
        {/* <div className="d-flex">

        </div> */}
        <div className="mt-4">
          <h6>Address:</h6>
          <p>2nd Floor, A-16, Sector-19, Rohini, Delhi-110089, INDIA</p>
        </div>
        <div>
          <h6>Contact:</h6>
          <p>+91-9899265660</p>
        </div>
        <div>
          <h6>Email:</h6>
          <p>adroidconnectz@gmail.com<br />director@adroidconnectz.org</p>
        </div>
      </div>
    </div>
  </div>
</div>

          
      )
  }
  export default Lower;
