
import {
	BrowserRouter as Router,
	Route,
	Routes

} from "react-router-dom";

// import Home component
import Home from "./components/Home.js";
import Contact_us from './components/Contactus.js';
import Features from "./components/features.js"
import SummSchool from "./components/SummSchool.js";
import Mentors from "./components/Mentors.js";
import RmdsReg from "./components/RmdsReg.js";
import Speakers from "./components/speakers.js";
import Schedule from "./components/Schedule.js";
import Poster from "./components/Poster.js";
import Video from "./components/Video.jsx";
import ResTech from "./components/courses/ResTech.js";
import Research from "./components/courses/Research.js";
import Schools from "./components/Schools.js";
import Internships from "./components/Internships.js";
import Conferences from "./components/Conferences.js";
import Resources from "./components/Resources.js";
import WebDevelopmentCourse from "./components/coursesDescription/WebDevelopmentCourse.js";
import SummerCourse from "./components/coursesDescription/SummerCourse.js";
import BasicResearchCourse from "./components/coursesDescription/BasicResearchCourse.js";
import PythonProgrammingCourse from "./components/coursesDescription/PythonProgrammingCourse.js";
import WinterSchoolCourse from "./components/coursesDescription/WinterSchoolCourse.js";
import DeepLearningCourse from "./components/coursesDescription/DeepLearningCourse.js";
import ConnectedHealthCare from "./components/coursesDescription/ConnectedHealthCare.js";
import PlacementCourse from "./components/coursesDescription/PlacementCourse.js";
import EmergingTechnology from "./components/coursesDescription/EmergingTechnology.js";
import Workshop from "./components/Workshop/Workshop.js";
import Downloads from "./components/icbha/downloads/Downloads.js";
import PaperSubmission from "./components/icbha/papers/papersubmission.js";
import Registration from "./components/icbha/registration/Registration.js";
import CallForPaper from "./components/icbha/papers/callforpaper.js";
import HomeCont from "./components/icbha/home.js";
import Journals from "./components/journals/Journals.js";
function App() {
	return (
		<>
			{/* This is the alias of BrowserRouter i.e. Router */}
			<Router>
				<Routes>
					{/* This route is for home component
		with exact path "/", in component props
		we passes the imported component*/}
					<Route exact path="/" element={<Home />} />
					<Route path="/features" element={<Features />} />
					<Route path="/contact" element={<Contact_us />} />
					<Route path="/courses" element={<ResTech />} />
					<Route path="/ResearchSummary" element={<Research />} />
					<Route path="/rmds" element={<SummSchool />} />
					<Route path="/rmds/reg" element={<RmdsReg />} />
					<Route path="/rmds/schedule" element={<Schedule />} />
					<Route path="/rmds/speakers" element={<Speakers />} />
					<Route path="/rmds/poster" element={<Poster />} />
					<Route path="/rmds/resource" element={<Resources />} />
					<Route path="/videos" element={<Video />} />
					<Route path="/mentors" element={<Mentors />} />
					<Route path="/schools" element={<Schools />} />
					<Route path="/internships" element={<Internships />} />
					<Route path="/conferences" element={<Conferences />} />
					<Route path="/courses/webdevelopment" element={<WebDevelopmentCourse />} />
					<Route path="/courses/summerCourse" element={<SummerCourse />} />
					<Route path="/courses/basicResearchCourse" element={<BasicResearchCourse />} />
					<Route path="/courses/pythonProgrammingCourse" element={<PythonProgrammingCourse />} />
					<Route path="/courses/winterCourse" element={<WinterSchoolCourse />} />
					<Route path="/courses/deepLearningCourse" element={<DeepLearningCourse />} />
					<Route path="/courses/connectedHealthcare" element={<ConnectedHealthCare />} />
					<Route path="/courses/placementCourse" element={<PlacementCourse />} />
					<Route path="/courses/emergingTechCourse" element={<EmergingTechnology />} />
					<Route path="/workshop" element={<Workshop />} />
					<Route path="/ichba" element={<HomeCont />} />
					<Route path="/ichba/callforpapers" element={<CallForPaper />} />
					<Route path="/ichba/registration" element={<Registration />} />
					<Route path="/ichba/papersubmission" element={<PaperSubmission/>} />
					<Route path="/ichba/downloads" element={<Downloads />} />
					<Route path="/journals" element={<Journals />} />



					{/* If any route mismatches the upper
		route endpoints then, redirect triggers
		and redirects app to home component with to="/" */}

				</Routes>
			</Router>
		</>
	);
}

export default App;
