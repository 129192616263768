import React from 'react';
import './bg.css'

function ImgBackground() {
    return (
        <>
            <div className="background_container">
                <figure className="img_background"></figure>
            </div>
        </>
    )
}

export default ImgBackground