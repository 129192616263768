import React from 'react'

const Week1 = () => {
  return (
    <div>
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 1 |</strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp;</strong><span style={{color: 'rgb(18, 39, 224)'}}><strong></strong></span></span><span style={{fontFamily: '"Arial", serif', fontSize: 20}}><strong>&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>Opening of Adroid Summer School 2023</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 20}}><span style={{fontFamily: '"Arial", serif', color: 'rgb(32, 206, 136)', border: '1pt none windowtext'}}>Online Mode:</span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: '"Arial", serif'}}>6:30 pm to 8:00 pm - Registration, Briefing and Schedule discussion of Summer School</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: '"Arial", serif'}}>Venue: Zoom (Link will be provided soon)</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: '"Arial", serif'}}>Kindly be on time</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: '"Arial", serif'}}>Install/update Zoom, ready with your laptop and headphone/earphones.</span></li>
      </ul>
    </li>
  </ul>
  <hr />
 

  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 2 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}><strong></strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>|&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', border: '1pt none windowtext', padding: '0cm'}}><strong>Basics of Python Programming</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontWeight: 'bold'}}><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Topic of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial'}}>
        <li><span style={{fontFamily: '"Arial",serif'}}>Basics of Python Programming (using Google Colab) |&nbsp;</span><a href="https://github.com/psrana/Learn-Python-in-2-hr" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click&nbsp;Here</span></a><span style={{fontFamily: '"Arial",serif'}}>&nbsp;</span></li>
      </ul>
    </li>
    <li><strong><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.5pt'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined'}}>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Download and install Anaconda on your local machine |&nbsp;</span><a href="https://www.anaconda.com/" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a>
          <ul style={{listStyleType: 'square'}}>
            <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Learn how to install packages in Python using pip/conda&nbsp;</span></li>
            <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Try to install OpenCV and Yolo packages</span></li>
          </ul>
        </li>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Python Tutorial |&nbsp;</span><a href="https://www.tutorialspoint.com/python/index.htm" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: 'black', border: 'none windowtext 1.0pt'}}>&nbsp; &nbsp;</span></li>
      </ul>
    </li>
    <li><strong><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Resource of the Day</span></strong>
      <ul style={{listStyleType: 'undefined'}}>
        <li><span style={{fontFamily: '"Arial",serif'}}>Anaconda Cloud |&nbsp;</span><a href="https://anaconda.cloud/" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
      </ul>
    </li>
    <li style={{fontWeight: 'bold'}}><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Assignment / Mini Project&nbsp;</></span><span style={{fontFamily: '"Arial",serif'}}><strong>| </strong><span style={{color: '#F7373A'}}><>Due Date:</></span>&nbsp;Tomorrow 23:59:59</span>
    <ol>
  <li style={{fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16}}><span style={{border: '1pt none windowtext'}}>Assignment-01: Python Assignment |&nbsp;</span><a href="https://github.com/psrana/Assignment-Python" target="_blank"><span style={{border: '1pt none windowtext'}}>Click Here</span></a>&nbsp;|&nbsp;<a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext'}}>Submission Link</span></a></li>
  <li style={{fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16}}><span style={{border: '1pt none windowtext'}}>Assignment-02: Challenge Question |&nbsp;</span><a href="https://github.com/psrana/Assignment-Challenge-Question" target="_blank"><span style={{border: '1pt none windowtext'}}>Click Here</span></a>&nbsp;|&nbsp;<a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
  <li style={{fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16}}>Mini Project-01: Subset Selection Problem |&nbsp;<a href="https://github.com/psrana/Mini-Project-Subset-Selection-Problem" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a>&nbsp;|&nbsp;<a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext'}}>Submission Link</span></a></li>
  <li style={{fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16}}><span style={{border: '1pt none windowtext'}}>Mini Project-02: ComboFinder |&nbsp;</span><a href="https://github.com/psrana/Mini-Project-ComboFinder" target="_blank"><span style={{border: '1pt none windowtext'}}>Click Here</span></a>&nbsp;|&nbsp;<a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext'}}>Submission Link</span></a></li>
  <li style={{fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16}}><span style={{border: '1pt none windowtext'}}>Mini Project-03: Text File Conversion |&nbsp;</span><a href="https://github.com/psrana/Mini-Project-Text-File-Conversion" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a>&nbsp;|&nbsp;<a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext'}}>Submission Link</span></a></li>
</ol>

    </li>
    <li style={{fontWeight: 'bold'}}><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Daily Task</></span><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#2B9A25', border: 'none windowtext 1.0pt'}}><strong>&nbsp;</strong></span><span style={{fontFamily: '"Arial",serif'}}><strong>| </strong><span style={{color: '#F7373A'}}><>Due Date:</></span> Today 23:59:59 |&nbsp;</span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial'}}>
        <li><span style={{fontFamily: '"Arial",serif'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>1-One Page writing (Two papers) |&nbsp;For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt'}}>&nbsp;|&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: 'blue', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>1-Audio Book |</span><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <hr />
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 3 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}><strong></strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>|&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', border: '1pt none windowtext', padding: '0cm'}}><strong>OOPs using Python and Working with Pandas</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontWeight: 'bold'}}><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Topic of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial'}}>
        <li><span style={{fontFamily: '"Arial",serif'}}>OOPs using Python |&nbsp;</span><a href="https://github.com/Aadil-5122/Object-Oriented-Programming-in-Python/tree/main/ObjectOrientedProgramming(Python)" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt'}}>&nbsp;</span></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>Working with Pandas |&nbsp;</span><a href="https://github.com/psrana/Working-with-Pandas" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>&nbsp;</span></li>
      </ul>
    </li>
    <li><strong><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined'}}>
        <li><span style={{fontFamily: '"Arial",serif'}}>GitHub &nbsp;- World's largest code sharing platform.</span>
          <ul style={{listStyleType: 'square'}}>
            <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Learn - How to Clone / Public / Private / Push / Pull / etc</span>
              <ul style={{listStyleType: 'square'}}>
                <li><a href="https://www.youtube.com/watch?v=ussgXhJ-cp0" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Li</span></a><a href="https://www.youtube.com/watch?v=ussgXhJ-cp0" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>nk-1</span></a><span style={{fontFamily: '"Arial",serif'}}>&nbsp; &nbsp;</span><a href="https://www.youtube.com/watch?v=B67X9xtOyuI" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Link-2</span></a><span style={{fontFamily: '"Arial",serif'}}>&nbsp; &nbsp;</span><a href="https://www.youtube.com/watch?v=uaeKhfhYE0U" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Link-3</span></a><span style={{fontFamily: '"Arial",serif'}}>&nbsp; &nbsp;</span><a href="https://www.youtube.com/watch?v=ulQA5tjJark" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Link-4</span></a><span style={{fontFamily: '"Arial",serif'}}>&nbsp;&nbsp;</span></li>
              </ul>
            </li>
            <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Create Pro Profile on GitHub |&nbsp;</span><a href="https://www.youtube.com/watch?v=5gQThvVS_ps" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click&nbsp;</span></a><a href="https://www.youtube.com/watch?v=5gQThvVS_ps" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Here</span></a>
              <ul style={{listStyleType: 'square'}}>
                <li><span style={{fontFamily: 'Roboto', fontSize: '10.5pt'}}>Sample Profile <span style={{color: 'black'}}>|&nbsp;</span></span><a href="https://github.com/raj-chinagundi" target="_blank"><span style={{fontFamily: 'Roboto', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a><span style={{fontFamily: 'Roboto'}}>&nbsp;</span></li>
              </ul>
            </li>
            <li><span style={{fontFamily: 'Roboto', fontSize: '10.5pt', color: 'black', border: 'none windowtext 1.0pt'}}>Git Cheat Sheet |&nbsp;</span><a href="https://github.com/psrana/Git-Cheat-Sheet" target="_blank"><span style={{fontFamily: 'Roboto', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
          </ul>
        </li>
      </ul>
    </li>
    <li><strong><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Resource of the Day</span></strong>
      <ul style={{listStyleType: 'undefined'}}>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Papers With Code</span><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt'}}>&nbsp;|&nbsp;</span><a href="https://paperswithcode.com/" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
      </ul>
    </li>
    <li style={{fontWeight: 'bold'}}><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Assignment / Mini Project&nbsp;</></span><span style={{fontFamily: '"Arial",serif'}}><strong>| </strong><span style={{color: '#F7373A'}}><>Due Date:</></span>&nbsp;Tomorrow 23:59:59</span>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial'}}>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Assignment-03: Advance Python |&nbsp;</span><a href="https://github.com/psrana/Assignment-Advance-Python" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a><span style={{fontFamily: '"Arial",serif'}}>&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Submission Link</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Mini Project-04: Marks Adding |&nbsp;</span><a href="https://github.com/psrana/Mini-Project-Marks-Adding" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a><span style={{fontFamily: '"Arial",serif'}}>&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Submission Link</span></a></li>
      </ul>
    </li>
    <li style={{fontWeight: 'bold'}}><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Daily Task</></span><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#2B9A25', border: 'none windowtext 1.0pt'}}><strong>&nbsp;</strong></span><span style={{fontFamily: '"Arial",serif'}}><strong>| </strong><span style={{color: '#F7373A'}}><>Due Date:</></span><> Today 23:59:59 |&nbsp;</></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial'}}>
        <li><span style={{fontFamily: '"Arial",serif'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>1-One Page writing (Two papers) |&nbsp;For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt'}}>&nbsp;|&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: 'blue', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>1-Audio Book |</span><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <hr />
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 4 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}><strong></strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>| Data Visualization and Exploratory Data Analysis (EDA)</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontWeight: 'bold'}}><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Topic of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial'}}>
        <li><span style={{fontFamily: '"Arial",serif'}}>Data Visualization using Seaborn <span style={{color: 'black'}}>|&nbsp;</span></span><a href="https://github.com/Raghavgarg12/Python-Projects/tree/main/Seaborn%20Tutorial" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: 'black', border: 'none windowtext 1.0pt'}}>&nbsp;</span></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>Exploratory Data Analysis (EDA) <span style={{color: 'black'}}>|&nbsp;</span></span><a href="https://github.com/Raghavgarg12/Python-Projects/tree/main/Exploratory_Data_Analysis" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here&nbsp;</span></a></li>
      </ul>
    </li>
    <li><strong><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Self&nbsp;Learning</span></strong>
      <ul style={{listStyleType: 'undefined'}}>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>EDA and Feature Engineering by Krish Naik |&nbsp;</span><a href="https://github.com/krishnaik06/5-Days-Live-EDA-and-Feature-Engineering" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
      </ul>
    </li>
    <li><strong><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Resource of the Day</span></strong>
      <ul style={{listStyleType: 'undefined'}}>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Mini - Major Projects |&nbsp;</span><a href="https://github.com/psrana/Mini-Major-Projects" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Github - ReadMe |&nbsp;</span><a href="https://github.com/psrana/Github-Project-Readme" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
      </ul>
    </li>
    <li style={{fontWeight: 'bold'}}><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Assignment / Mini Project&nbsp;</></span><span style={{fontFamily: '"Arial",serif'}}><strong>|&nbsp;</strong><span style={{color: '#F7373A'}}><>Due Date:</></span>&nbsp;Tomorrow 23:59:59</span>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial'}}>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Assignment-04: "Perform EDA on any of the dataset of your choice"&nbsp;</span><span style={{fontFamily: '"Arial",serif'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Submission Link</span></a>
          <ul style={{listStyleType: 'square'}}>
            <li><span style={{fontFamily: '"Arial",serif'}}>You can get the dataset from UCI Dataset Repository |&nbsp;</span><a href="https://archive.ics.uci.edu/datasets" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a><span style={{fontFamily: '"Arial",serif'}}>&nbsp; &nbsp; &nbsp;</span></li>
          </ul>
        </li>
      </ul>
    </li>
    <li style={{fontWeight: 'bold'}}><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Daily Task</></span><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#2B9A25', border: 'none windowtext 1.0pt'}}><strong>&nbsp;</strong></span><span style={{fontFamily: '"Arial",serif'}}><strong>| </strong><span style={{color: '#F7373A'}}><>Due Date:</></span><> Today 23:59:59 |&nbsp;</></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial'}}>
        <li><span style={{fontFamily: '"Arial",serif'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>1-One Page writing (Two papers) |&nbsp;For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt'}}>&nbsp;|&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: 'blue', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>1-Audio Book |</span><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <hr />
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 5 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}><strong></strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>|&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: '"Arial", serif', border: '1pt none windowtext', padding: '0cm'}}><strong>R Programming and Topsis for Multi-criteria Decision making (MCDM)</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontWeight: 'bold'}}><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Topic of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial'}}>
        <li><span style={{fontFamily: '"Arial",serif'}}>R Programming |&nbsp;</span><a href="https://github.com/psrana/R-Programming" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click He</span></a><a href="https://github.com/psrana/R-Programming" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>re</span></a><span style={{fontFamily: '"Arial",serif'}}>&nbsp;</span></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>Topsis for Multi-criteria Decision making (MCDM) |&nbsp;</span><a href="https://github.com/psrana/Topsis" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here&nbsp;</span></a></li>
      </ul>
    </li>
    <li><strong><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined'}}>
        <li><strong><span style={{fontSize: '10.5pt'}}>Download &amp; Install &nbsp;</span></strong><strong><span style={{fontFamily: '"Arial",serif'}}>- R&nbsp;and&nbsp;R-Studio</span></strong>
          <ul style={{listStyleType: 'square'}}>
            <li><strong><span style={{fontFamily: '"Arial",serif'}}>R -&nbsp;</span></strong><a href="https://cran.rstudio.com/bin/" target="_blank"><strong><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></strong></a><strong><span style={{fontFamily: '"Arial",serif'}}>&nbsp;to download (For Windows / IOS / Linux) | Click on base</span></strong></li>
            <li><strong><span style={{fontFamily: '"Arial",serif'}}>RStudio -&nbsp;</span></strong><a href="https://posit.co/download/rstudio-desktop/" target="_blank"><strong><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: 'blue', border: 'none windowtext 1.0pt'}}>Click Here</span></strong></a><strong><span style={{fontFamily: '"Arial",serif'}}>&nbsp;to download</span></strong></li>
          </ul>
        </li>
        <li><span style={{fontFamily: '"Arial",serif'}}>R Tutorial |&nbsp;</span><a href="https://www.w3schools.com/r/" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>Basic plotting and visualization using R |&nbsp;</span><a href="https://plotly.com/r/" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
      </ul>
    </li>
    <li><strong><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Resource of the Day</span></strong>
      <ul style={{listStyleType: 'undefined'}}>
        <li><span style={{fontFamily: '"Arial",serif'}}>R Packages for research -&nbsp;For research on Scientific/Engineering problems such as AI, Bioinformatics, Chemistry, Electronics Design, GIS, Human Machine Interface, Image Recognition, NPL, etc.)</span>
          <ul style={{listStyleType: 'square'}}>
            <li><span style={{fontFamily: '"Arial",serif'}}>R Packages | By Date -&nbsp;</span><a href="https://cran.r-project.org/web/packages/available_packages_by_date.html" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Her</span></a><a href="https://cran.r-project.org/web/packages/available_packages_by_date.html" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>e</span></a><span style={{fontFamily: '"Arial",serif'}}>&nbsp;| By Task -&nbsp;</span><a href="https://cran.r-project.org/web/views/" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt'}}>&nbsp;| By Name -&nbsp;</span><a href="https://cran.r-project.org/web/packages/available_packages_by_name.html" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
          </ul>
        </li>
        <li><span style={{fontFamily: '"Arial",serif'}}>Books - Machine Learning + R |&nbsp;</span><a href="https://drive.google.com/drive/folders/1lcyFHXmeVM0W1V1TyL6UP3mVUAakP34x?usp=sharing" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt'}}>&nbsp; &nbsp;</span></li>
      </ul>
    </li>
    <li style={{fontWeight: 'bold'}}><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Assignment / Mini Project&nbsp;</></span><span style={{fontFamily: '"Arial",serif'}}><strong>| </strong><span style={{color: '#F7373A'}}><>Due Date:</></span>&nbsp;Tomorrow 23:59:59</span>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial'}}>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Assignment-05: Topic |&nbsp;</span><a href="https://github.com/psrana/Assignment-R-Programming" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt'}}>&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Submission Link</span></a></li>
      </ul>
    </li>
    <li style={{fontWeight: 'bold'}}><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Daily Task</></span><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#2B9A25', border: 'none windowtext 1.0pt'}}><strong>&nbsp;</strong></span><span style={{fontFamily: '"Arial",serif'}}><strong>| </strong><span style={{color: '#F7373A'}}><>Due Date:</></span><> Today 23:59:59 |&nbsp;</></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial'}}>
        <li><span style={{fontFamily: '"Arial",serif'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>1-One Page writing (Two papers) |&nbsp;For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt'}}>&nbsp;|&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: 'blue', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>1-Audio Book |</span><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: '"Arial",serif', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: '"Arial",serif', fontSize: '10.5pt', color: '#1227E0', border: 'none windowtext 1.0pt'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: '"Arial",serif'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 6 |</strong><strong>&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}><strong></strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Daily Task</span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}>Due Date:</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Today 23:59:59 |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm',marginTop:'-20px', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 14, fontFamily: '"Arial",sans-serif', color: '#C71212', border: 'none windowtext 1.0pt', padding: '0cm'}}>&nbsp;</span></p>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 7 |</strong>&nbsp;</span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}><strong></strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}>
    <strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Daily Task</span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}>Due Date:</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Today 23:59:59 |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
</div>
  )
}

export default Week1