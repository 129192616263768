import React from 'react';
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CssBaseline } from '@mui/material';
import Header from '../Header'
import Navbar from '../navbar'
import Footer from '../Footer'
import Lower from '../Lower'
import classes from "./WebDevelopmentCourse.module.css"
import headerImage from '../images/pythonAndMachine.png'
import { Nav } from 'react-bootstrap';
const courseData = {
  "title": "Python & Machine Learning",
  "instructor": "Dr. PS Rana, Thapar Institute of Engineering & Technology, India",
  "duration": "2 Weeks",
  "price": "₹999/-",
  "level": "A basics of python programming & machine learning ",
  "outcome": [
    "Able to use basics of Python for Problem Solving",
    "Able to apply Machine Learning, Optimization, Statistics, and Exploratory Data Analysis (EDA) to a variety of problems",
    "Able to learn How to write and publish research papers",
    "Building profile on Github and Explore Kaggle"
  ],
  "courseContent": [
    {
      "title": "Basics of Python Programming (using Google Colab)",
      "instructors": ["Dr. PS Rana"]
    },
    {
      "title": "OOPs using Python",
      "instructors": ["Mr Aadil Garg", "Mr Raghav Garg"]
    },
    {
      "title": "Working with Pandas",
      "instructors": ["Dr. PS Rana"]
    },
    {
      "title": "Data Visualization using Seaborn",
      "instructors": ["Mr Aadil Garg", "Mr Raghav Garg"]
    },
    {
      "title": "Exploratory Data Analysis (EDA)",
      "instructors": ["Mr Aadil Garg", "Mr Raghav Garg"]
    },
    {
      "title": "R Programming",
      "instructors": ["Dr PS Rana"]
    },
    {
      "title": "Topsis for Multi-criteria Decision making (MCDM)",
      "instructors": ["Dr PS Rana"]
    },
    {
      "title": "Mini Projects",
      "instructors": ["Mr. Raghav Garg", "Mr. Aadil Garg", "Mr. Pratham Garg"],
      "projects": [
        "Mini Project-05: Image to Text to Audio",
        "Mini Project-06: Web crawler (Email Scraper)",
        "Mini Project-07: Audio to Text",
        "Mini Project-08: Plagiarism checker",
        "Mini Project-09: QR Code Generator"
      ]
    },
    {
      "title": "Introduction to Machine Learning",
      "instructors": ["Dr PS Rana"],
      "topics": [
        "Teachable Machine",
        "Sample Dataset",
        "Model Evaluation Parameters for Regression and Classification",
        "Result Analysis of Machine Learning Models using Excel"
      ]
    },
    {
      "title": "Machine Learning using Pycaret",
      "instructors": ["Dr PS Rana"]
    },
    {
      "title": "Machine Learning using Pycaret",
      "instructors": ["Dr PS Rana"]
    },
    {
      "title": "Introduction to Kaggle",
      "instructors": ["Mr. Raghav Garg", "Mr. Aadil Garg", "Mr. Pratham Garg"],
      "topics": [
        "Getting Started with Kaggle",
        "Digit Recognizer Baseline",
        "Titanic Baseline",
        "House Price Regression Baseline"
      ]
    },
    {
      "title": "Handling Errors in ML & DSA",
      "instructors": ["Ms. Gurbani Kaur", "Mr. Gaurav Gupta"]
    },
    {
      "title": "Advance Kaggle",
      "instructors": ["Mr. Eishkaran Singh"]
    }
  ],


  "note": "The content of the course “Python & Machine Learning” has been designed by the group of reputed researchers who have long trails of success in research-related activities; The team for data science course has been headed by Dr. PS Rana.",
  "website": "https://www.psrana.com"
}


const PythonProgrammingCourse = () => {
  return (
    <React.Fragment>
      <Header />
      <Navbar />
      <Container className={classes.Container}>

        <CssBaseline />
        <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>
          {courseData.title}
        </Typography>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          {courseData.level}
        </Typography>


        <div className={classes.imgCont}>
          <img src={headerImage} className='imgres' alt="a" />
        </div>
        <Nav.Link href="https://buy.stripe.com/aEU5mj0th9EreJO28a" target="_blank" style={{ textAlign: "center", minHeight: "90%", marginTop: "2%", marginBottom: "2%" }}><button type="button" class="btn btn-success">Buy Now</button></Nav.Link>
        <div className={classes.con}>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Instructor: {courseData.instructor}
          </Typography>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Duration: {courseData.duration}
          </Typography>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Price: {courseData.price}
          </Typography>
        </div>
        <Typography variant="h4" gutterBottom>
          Outcome:

        </Typography>
        <p> Student can be able to learn and use the basic python to solve a number of problems. They can be able to apply basic machine learning, and optimization approaches for problem solving. Students can be able to apply various machine learning approaches and build a number of minor and major projects. Students will also build Github profile and explore Kaggle.</p>
        <Typography variant="h4" gutterBottom>
          Able to do after completion of this course:

        </Typography>
        <ul>


          {courseData.outcome.map((item, index) => (
            <Typography component="li" key={index}>
              {item}
            </Typography>
          ))}
        </ul>
        <hr></hr>
        <Typography variant="h4" gutterBottom>
          Course Content:

        </Typography>
        {courseData.courseContent.map((section, index) => (
          <div key={index}>
            <Typography variant="h5" gutterBottom>
              {index + 1}.{section.title}
            </Typography>
            <Typography className={classes.Typography_gutterBottom}>
              Instructors: {section.instructors.join(', ')}
            </Typography>
            {section.projects && section.projects.length > 0 && (
              <>
                <Typography variant="h6" className={classes.Typography_variantH6}>
                  Mini Projects
                </Typography>
                <ul>
                  {section.projects.map((project, pIndex) => (
                    <Typography component="li" key={pIndex}>
                      {project}
                    </Typography>
                  ))}
                </ul>


              </>
            )}
            {section.topics && section.topics.length > 0 && (
              <>
                <Typography variant="h6" className={classes.Typography_variantH6}>
                  Topics
                </Typography>
                <ul>
                  {section.topics.map((project, pIndex) => (
                    <Typography component="li" key={pIndex}>
                      {project}
                    </Typography>
                  ))}
                </ul>


              </>
            )}
            <hr />


          </div>
        ))}
        <p>
          <span style={{ fontWeight: "bolder" }}>Please Note:</span>  The content of the course “Python & Machine Learning” has been designed by the group of reputed researchers who have long trails of success in research related activities; The team for data science course has been headed by Dr. PS Rana.

          <a href='https://www.psrana.com' target='_blank'>https://www.psrana.com</a>  </p>
      </Container>
      <Lower />
      <Footer />
    </React.Fragment>
  );

};

export default PythonProgrammingCourse;
