import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import playstore from "./images/playstore.png";
import { Link } from 'react-router-dom';

function Example() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    const loadMod=window.addEventListener('load',()=>{
      setShow(true);
      // console.log('Modal loaded');
    })
    // return window.removeEventListener('load',loadMod);
    
  }, [])
  

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Download
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Download our app!</Modal.Title>
        </Modal.Header>
        <Modal.Body className='align-middle d-flex justify-content-center'><Link to='https://play.google.com/store/apps/details?id=com.adroid.adroidconnectz&pli=1'><img src={playstore} style={{width:'155px'}}/></Link></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Example;