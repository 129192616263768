import React from 'react';
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CssBaseline } from '@mui/material';
import Header from '../Header'
import Navbar from '../navbar'
import Footer from '../Footer'
import Lower from '../Lower'
import classes from "./WebDevelopmentCourse.module.css"
import headerImage from '../images/wds.jpg'
import { Nav } from 'react-bootstrap';
const courseData = {
  "courseName": "Winter School on Machine Learning & Deep Learning with Research Skills",
  "description": "Amalgamation of Research, Machine Learning & Deep Learning",
  "duration": "2 Weeks (starts from 6th November 2023)",
  "price": "₹1999/-",
  "outcome": [
    "Student can be able to learn and use the basic python to solve a number of problems.",
    "They can be able to apply basic machine learning, and optimization approaches for problem solving.",
    "Students can have a basic understanding about the emerging technologies like image processing, Natural language processing, blockchain, Internet of Things, time series, Explainable AI and can be able to apply various machine learning and deep learning approaches on them and build a number of minor and major projects.",
    "Students will also learn how to write and publish related research paper.",
    "Students will also build Github profile and explore Kaggle."
  ],
  "ableToDo": [
    "Able to use basics of Python for Problem Solving",
    "Able to apply Machine Learning, Optimization, Statistics, and Exploratory Data Analysis (EDA) to variety of problems",
    "Understand the basics of Image Processing and Deep Learning",
    "Understands the basics of Natural Language Processing (NLP) and Blockchain",
    "Understands the basics of Time Series, Explainable AI",
    "Able to learn How to write and publish research papers"
  ],
  "courseContent": [
    {
      "title": "Basics of Python Programming (using Google Colab)",
      "instructors": ["Dr. PSRana"]
    },
    {
      "title": "OOPs using Python",
      "instructors": ["Mr Aadil Garg", "Mr Raghav Garg"]
    },
    {
      "title": "Working with Pandas",
      "instructors": ["Dr. PSRana"]
    },
    {
      "title": "Data Visualization using Seaborn",
      "instructors": ["Mr Aadil Garg", "Mr Raghav Garg"]
    },
    {
      "title": "Exploratory Data Analysis (EDA)",
      "instructors": ["Mr Aadil Garg", "Mr Raghav Garg"]
    },
    {
      "title": "R Programming",
      "instructors": ["Dr PSRana"]
    },
    {
      "title": "Topsis for Multi-criteria Decision making (MCDM)",
      "instructors": ["Dr PSRan"]
    },
    {
      "title": "Mini Projects",
      "instructors": ["Mr. Raghav Garg", "Mr. Aadil Garg", "Mr. Pratham Garg"],
      "miniProjects": [
        "Mini Project-05: Image to Text to Audio",
        "Mini Project-06: Web crawler (Email Scraper)",
        "Mini Project-07: Audio to Text",
        "Mini Project-08: Plagiarism checker",
        "Mini Project-09: QR Code Generator"
      ]
    },
    {
      "title": "Introduction to Machine Learning",
      "instructors": ["PSRana"],
      "topics": [
        "Teachable Machine",
        "Sample Dataset",
        "Model Evaluation Parameters for Regression and Classification",
        "Result Analysis of Machine Learning Models using Excel"
      ]
    },
    {
      "title": "Machine Learning using Pycaret",
      "instructors": ["PSRana"]
    },
    {
      "title": "Machine Learning using Pycaret",
      "instructors": ["PSRana"]
    },
    {
      "title": "Introduction to Kaggle",
      "instructors": ["Mr. Raghav Garg", "Mr. Aadil Garg", "Mr. Pratham Garg"],
      "topics": [
        "Getting Started with Kaggle",
        "Digit Recognizer Baseline",
        "Titanic Baseline",
        "House Price Regression Baseline"
      ]
    },
    {
      "title": "Handling Errors in ML & DSA",
      "instructors": ["Ms. Gurbani Kaur", "Mr. Gaurav Gupta"]
    },
    {
      "title": "Advance Kaggle",
      "instructors": ["Mr. Eishkaran Singh"]
    },
    {
      "title": "Introduction to Image Processing, OpenCV and Yolo",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "Intro to image processing",
        "Image Processing using OpenCV",
        "Image Processing using Scikit learn",
        "Intro to Yolo",
        "Yolo Architecture",
        "Object Detection with Yolo",
        "Projects/opportunities for future",
        "Mini Project-39: Face Mask Detection using Yolo V7",
        "Mini Project-40: Social Distancing using Yolo V3"
      ],
      "miniProjects": [
        "Mini Project-39: Face Mask Detection using Yolo V7",
        "Mini Project-40: Social Distancing using Yolo V3"

      ]
    },
    {
      "title": "Deep Learning: Image Processing",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "Basics of Deep Learning",
        "Neural networks",
        "Forward Propagation",
        "Backward Propagation",
        "Terminologies for Neural Networks",
        "Steps for building a deep learning model",
        "CNNs",
        "UNet",
        "ResNet",
        "Projects/opportunities for future",

      ],
      "miniProjects": [
        "Mini Project-41: Use Deep Learning for Image Classification",
        "Mini Project-42: Using CNNs for Image Classification"

      ]
    },
    {
      "title": "Introduction to Generative Adversarial Network (GAN)",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "What is generative AI",
        "Capabilities of GANs",
        "What are GANs",
        "GAN architecture",
        "Generator",
        "Discriminator",
        "Training a GAN",
        "Demo of Basic GANs",
        "Demo of CycleGAN",
        "Demo of SRGAN",
        "Mini Project-46: DCGAN",
        "Mini Project-47: SRGAN"
      ],
      "miniProjects": [
        "Mini Project-46: DCGAN",
        "Mini Project-47: SRGAN"

      ]

    },
    {
      "title": "Introduction to Natural Language Processing",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "What is Natural Language",
        "Preprocessing Textual Data",
        "RNN",
        "LSTM",
        "Mini Project-49: Toxic Comment Classification using RNN and LSTM"
      ],
      "miniProjects": [
        "Mini Project-49: Toxic Comment Classification using RNN and LSTM"

      ]
    },
    {
      "title": "Introduction to Natural Language Processing",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "History of NLP",
        "NLP Pipeline",
        "Attention is all you need",
        "Transformer Architecture",
        "BERT",
        "BERT vs Alternates",
        "Change from GPT3.5 onwards",
        "BERT Code",
        "Mini Project-54: Sentiment Analysis on Hospital Comments"
      ],
      "miniProjects": [
        "Mini Project-54: Sentiment Analysis on Hospital Comments"

      ]
    },
    {
      "title": "Introduction to LLM (Large Language Models)",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj", "Vansh Gahlot"],
      "topics": [
        "Large Language Models",
        "How do large language models work",
        "RLHF",
        "Pros and Cons of LLMs",
        "Hallucinations in LLMs",
        "Ethics in LLMs",
        "Prompt Engineering",
        "Cost Optimization in LLMs",
        "Develop applications using LLM APIs (Langchain)",
        "ThaparGPT Demo"
      ]
    },
    {
      "title": "Guidance for Placement and CV Building",
      "instructors": ["Mr Gaurav Gupta", "Ms Gurbani Kaur"]
    },
    {
      "title": "How to write a research paper",
      "instructors": ["Dr PSRana"]
    },
    {
      "title": "Introduction to IoT (Internet of Things) and its Applications",
      "instructors": ["Dr. Anjula Mehto"],
      "topics": [
        "The World of IoT",
        "IoT Applications",
        "IoT building Components",
        "Case Study and Making a thing",
        "Industry 4.0- Smart Manufacturing",
        "Introduction to Cloud and Edge Computing"
      ]
    },
    {
      "title": "Introduction to IoT (Internet of Things) and its Applications",
      "instructors": ["Dr. Anjula Mehto"],
      "topics": [
        "Introduction to TinKercad",
        "Hands-on with TinKercad"],
      "miniProjects": [
        "Sending sensor data to ThinkSpeak",
        "Mini Project-57: Blinking a LED",
        "Mini Project-58: Blinking Multiple LED",
        "Mini Project-59: Automatic Night Lamp",
        "Mini Project-60: Traffic Light Controller",
        "Mini Project-61: Fire Alarm System"
      ],
      
    },
    {
      "title": "Introduction to Explainable Artificial Intelligence (XAI)",
      "instructors": ["Dr. Parteek Bhatia"]
    },
    {
      "title": "Lets build the Future",
      "instructors": ["Mr. Vansh Gehlot"]
    },
    {
      "title": "Time Series Analysis",
      "instructors": ["Mr. Rudransh Bansal"]
    },
    {
      "title": "Data Generation for ML using Modeling and Simulations",
      "instructors": ["Dr. PSRana"]
    }
  ],
  "note": "The content of the course “Winter School on Machine Learning & Deep Learning with Research Skills” has been designed by the group of reputed researchers who have long trails of success in research-related activities; The team for data science course has been headed by Dr. PS Rana.",
  "website": "https://www.psrana.com"
}


const WinterSchoolCourse = () => {
  return (
    <React.Fragment>
      <Header />
      <Navbar />
      <Container className={classes.Container}>

        <CssBaseline />
        <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>
          {courseData.courseName}
        </Typography>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          {courseData.description}
        </Typography>



        <div className={classes.imgCont}>
          <img src={headerImage} className='imgres' alt="a"/>
        </div>
        <Nav.Link href="https://buy.stripe.com/6oE8yv3FtdUHbxCcMS" target="_blank" style={{ textAlign: "center", minHeight: "90%", marginTop: "2%", marginBottom: "2%" }}><button type="button" class="btn btn-success">Buy Now</button></Nav.Link>
        <div className={classes.con}>
          {/* <Typography className={classes.Typography_gutterBottom} variant="h6">
              Instructor: {courseData.instructor}
            </Typography> */}
          <Typography className={classes.Typography_gutterBottom} variant="h6">
            Duration: {courseData.duration}
          </Typography>
          <Typography className={classes.Typography_gutterBottom} variant="h6">
            Price: {courseData.price}
          </Typography>
        </div>
        <Typography variant="h4" gutterBottom>
          Outcome:

        </Typography>
        <p>Student can be able to learn and use the basic python to solve a number of problems. They can be able to apply basic machine learning, and optimization approaches for problem solving. Students can have a basic understanding about the emerging technologies like image processing, Natural language processing, blockchain, Internet of Things, web development, cloud services, time series, Explainable AI and can be able to apply various machine learning and deep learning approaches on them and build a number of minor and major projects. Students will also learn how to write and publish related research paper. Students will also build Github profile and explore Kaggle.</p>
        <Typography variant="h5" gutterBottom>
          Able to do after completion of this course:

        </Typography>
        <ul>
          {courseData.ableToDo.map((item, index) => (
            <Typography component="li" key={index}>
              {item}
            </Typography>
          ))}
        </ul>
        <hr></hr>
        <Typography variant="h4" gutterBottom>
          Course Content:

        </Typography>
        {courseData.courseContent.map((section, index) => (
          <div key={index}>
            <Typography variant="h5" gutterBottom>
              {index + 1}.{section.title}
            </Typography>
            <Typography className={classes.Typography_gutterBottom}>
              Instructors: {section.instructors.join(', ')}
            </Typography>
            <ul>
              {section.topics ? (
                <ul>
                  {section.topics.map((topic, i) => (
                    <Typography component="li" key={i}>
                      {topic}
                    </Typography>
                  ))}
                </ul>
              ) : (
                <Typography variant="body1" gutterBottom>

                </Typography>
              )}
            </ul>
            {section.miniProjects && section.miniProjects.length > 0 && (
              <>
                <Typography variant="h6" className={classes.Typography_variantH6}>
                  Mini Projects
                </Typography>
                <ul>
                  {section.miniProjects.map((project, pIndex) => (
                    <Typography component="li" key={pIndex}>
                      {project}
                    </Typography>
                  ))}
                </ul>
              </>
            )}
            <hr />


          </div>
        ))}

        <p>
          <span style={{ fontWeight: "bolder" }}>Please Note:</span> The content of the course “Winter School on Machine Learning & Deep Learning with Research Skills” has been designed by the group of reputed researchers who have long trails of success in research related activities; The team for data science course has been headed by Dr. PS Rana.
          <a href='https://www.psrana.com' target='_blank'>https://www.psrana.com</a>  </p>
      </Container>
      <Lower />
      <Footer />
    </React.Fragment>
  );

};

export default WinterSchoolCourse;
