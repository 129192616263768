import React from 'react'


import Header from './Header'
import Navbar from './navbar'
import '../components/CSS/SummCSS.css'
import Accordion from 'react-bootstrap/Accordion';

import laptop from "../components/laptop.jpg"

import bg from "./bg1.jpeg"
import Lower from './Lower'
import Footer from './Footer'
import Table from './Table'
import DetailedCourse from './DetailedCourse'
import Week2 from './WeeklySchedule/Week2';
import Week1 from './WeeklySchedule/Week1';
import Week3 from './WeeklySchedule/Week3';
import Week4 from './WeeklySchedule/Week4';
import Week5 from './WeeklySchedule/Week5';
import Week6 from './WeeklySchedule/Week6';

export default function Schedule() {
  return (
    <div >
        <Header />
        <Navbar />
        {/* <h3 className='text-center'>Adroid Connectz Summer School on Research Methods & Data Science 2023 <br />19th June - 28th July 2023</h3> */}
        
        <div className="body-sch">        
        <div className="page-cont-3">        
        <h1 style={{color:"rgb(220 53 69)"}} className="header-summ">Schedule</h1>
        
        <b>
        Time & Venue :
        </b>
        <ul>
          <li>Online - Monday to Friday - Anytime (3 Hours) |
          <br /> Adroid Connectz e-Learning App</li>
        </ul>  
        
        
        <b style={{"color":"red"}}>Note : </b>
        <ol>
          <li>Be on Time</li>
          <li>Have your Laptop</li>
          <li>Bring power extensions (if possible)</li>
        </ol>
        </div>
        <div>
        <br /><br />
        <b>Brief Schedule:</b><br /><span style={{color:'blue'}}>One-week Basic Research Course content subscription will be provided to you, complete the course during the Adroid Summer School to avail the certification.</span><br />
        <Table />
        </div>
        
        </div>
        <h1 style={{color:"rgb(220 53 69)"}} className="header-summ">Detailed Schedule</h1>
        
        <div className='body-sch'  style={{"marginTop":"-20px"}}>        
        {/* <DetailedCourse/> */}
      <Accordion defaultActiveKey="0" style={{'width':'100%'}}>
      <Accordion.Item eventKey="0">
        <Accordion.Header><p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 19, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 22}}> Basic Research Course [One-week]</span></p>
</Accordion.Header>
        <Accordion.Body>
     <div>
  <ol>
    <li><span style={{color: '#1227e0'}}><strong>Introduction</strong></span>
      <ul>
        <li>Introduction to Research</li>
        <li>Nature of Research&nbsp;</li>
      </ul>
    </li>
  </ol>
  <ol start={2}>
    <li><span style={{color: '#1227e0'}}><strong>What is Research</strong></span>
      <ul>
        <li>Research Examples</li>
        <li>Importance of Research</li>
        <li>Ethics &amp; Truth</li>
        <li>Summary&nbsp;</li>
      </ul>
    </li>
  </ol>
  <ol start={3}>
    <li><span style={{color: '#1227e0'}}><strong>Types of Research</strong></span>
      <ul>
        <li>Types &amp; Objectives</li>
        <li>Types of Research (Basic)</li>
        <li>Case Study</li>
        <li>Research Definitions&nbsp;</li>
      </ul>
    </li>
  </ol>
  <ol start={4}>
    <li><span style={{color: '#1227e0'}}><strong>Components of Research</strong></span>
      <ul>
        <li>Research definitions, characteristics, objectives, Scientific methods</li>
        <li>Types of Research (Advanced)&nbsp;</li>
      </ul>
    </li>
  </ol>
  <ol start={5}>
    <li><span style={{color: '#1227e0'}}><strong>Elements of Coding</strong></span>
      <ul>
        <li>Why do we code and its importance</li>
        <li>Code and its types&nbsp;</li>
      </ul>
    </li>
  </ol>
  <ol start={6}>
    <li><span style={{color: '#1227e0'}}><strong>Basic elements of Research paper writing</strong></span>
      <ul>
        <li>Literature review in research</li>
        <li>Advantages of literature review</li>
        <li>Learning from other research</li>
        <li>Basic template of a research paper&nbsp;</li>
      </ul>
    </li>
  </ol>
  <ol start={7}>
    <li><span style={{color: '#1227e0'}}><strong>Developing advanced reading skills</strong>&nbsp;</span></li>
  </ol>
  <ol start={8}>
    <li><span style={{color: '#1227e0'}}><strong>Research Ethics</strong></span>
      <ul>
        <li>Research Ethics</li>
        <li>Informed consent&nbsp;</li>
      </ul>
    </li>
  </ol>
  <ol start={9}>
    <li><span style={{color: '#1227e0'}}><strong>Research Process</strong>&nbsp;</span></li>
  </ol>
  <ol start={10}>
    <li><span style={{color: '#1227e0'}}><strong>Research Question</strong></span>
      <ul>
        <li>Framing a research question</li>
        <li>Funnelling Process&nbsp;</li>
      </ul>
    </li>
  </ol>
  <ol start={11}>
    <li><span style={{color: '#1227e0'}}><strong>Start your research?</strong></span>
      <ul>
        <li>Choose research topic</li>
        <li>Literature review process&nbsp;</li>
      </ul>
    </li>
  </ol>
  <ol start={12}>
    <li><span style={{color: '#1227e0'}}><strong>Tools for writing a Research Paper</strong></span>
      <ul>
        <li>Basic tools &amp; software</li>
        <li>Plagiarism tools and how to avoid it&nbsp;</li>
      </ul>
    </li>
  </ol>
  <ol start={13}>
    <li><span style={{color: '#1227e0'}}><strong>How to write an academic research paper or report</strong></span>
      <ul>
        <li>Producing research report</li>
        <li>Writing academic literature</li>
        <li>Difference conference &amp; journal&nbsp;</li>
      </ul>
    </li>
  </ol>
  <ol start={14}>
    <li><span style={{color: '#1227e0'}}><strong>How to write a good paper for a journal</strong></span>
      <ul>
        <li>How to write a good journal article and understanding peer-review process</li>
        <li>Publishing ethics&nbsp;</li>
      </ul>
    </li>
  </ol>
  <ol start={15}>
    <li><span style={{color: '#1227e0'}}><strong>Writing and presenting a paper in conference/seminar/symposia</strong></span>
      <ul>
        <li>Presenting a research paper</li>
        <li>Revise manuscript</li>
        <li>Writing about future work</li>
      </ul>
    </li>
  </ol>
</div>


        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header><p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 19, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 22}}>Week 1 | Python</span></p></Accordion.Header>
        <Accordion.Body>
        <Week1 />   
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header><p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 19, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 22}}>Week 2 | Machine Learning</span></p></Accordion.Header>
        <Accordion.Body>
        <Week2 />  
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header><p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 19, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 22}}>Week 3 | Web Development - Basics </span></p></Accordion.Header>
        <Accordion.Body>
        <Week3 />  
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header><p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 19, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 22}}>Week 4 | Web Development - Advanced </span></p></Accordion.Header>
        <Accordion.Body>
        <Week4 />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header><p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 19, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 22}}>Week 5 | Deep Learning and Natural Language Processing (NLP) </span></p></Accordion.Header>
        <Accordion.Body>
        <Week5 />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6">
        <Accordion.Header><p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 19, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 22}}>Week 6 | Time Series, Internet of Things (IoT), Cloud Services </span></p></Accordion.Header>
        <Accordion.Body>
        <Week6 />
        </Accordion.Body>
      </Accordion.Item>
      




    </Accordion>
        </div>
        <Lower/>
    <Footer />
    </div>
    
  )
}

