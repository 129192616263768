import React from "react";
import style from "../../CSS/header.module.css";
import Date from '../Date';
import Header from "../../Header";
import NavBar from "../../navbar";
import Lower from "../../Lower";
import Footer from "../../Footer";


const PaperSubmission=()=>{
  return(
  <div>
  <Header />
  <NavBar />
  <div  style={{'backgroundColor':'red','padding':'4px'}}>
  <h2 class={style.abtHeader}>Paper Submission</h2>
  </div>
  <hr />
  <div className={style.flex}>
  <div className={style.abtInstCont}>
    <ul>
      <li>Papers reporting original and unpublished research results pertaining to the related topics are solicited. </li>
<li>Full paper manuscripts must be in English.</li>
<li>Papers with plagiarism more than 15% will be outrightly rejected.</li>
<li>All papers must be submitted online via google form to <a href="https://forms.gle/1aeR81YHyQEeZFxP9">https://forms.gle/1aeR81YHyQEeZFxP9</a>
</li>
    </ul>
    <br />
    <br />

  </div>
  <Date />

</div>
<Lower/>
<Footer/>


  </div>
);
};

export default PaperSubmission;
