import React from 'react';
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CssBaseline } from '@mui/material';
import Header from '../Header'
import Navbar from '../navbar'
import Footer from '../Footer'
import Lower from '../Lower'
import classes from "./WebDevelopmentCourse.module.css"
import headerImage from '../images/EmergingTechnologies.jpg'
import { Nav } from 'react-bootstrap';
const courseData = {
    "courseName": "Emerging Technologies (IoT, Time Series, XAI, and Data Generation)",
    "description": "A course covers IoT, Time Series, XAI, and Data Generation",
    "instructor": "Dr. PS Rana, Thapar Institute of Engineering & Technology, India",
    "duration": "1 Week",
    "fees": "₹799/-",
    "courseContent": [
        {
            "title": "Introduction to IoT (Internet of Things) and its Applications",
            "instructor": ["Dr. Anjula Mehto"],
            "topics": [
                "The World of IoT",
                "IoT Applications",
                "IoT building Components",
                "Case Study and Making a thing",
                "Industry 4.0- Smart Manufacturing",
                "Introduction to Cloud and Edge Computing"
            ]
        },
        {
            "title": "Introduction to IoT (Internet of Things) and its Applications",
            "instructor": ["Dr. Anjula Mehto"],
            "topics": [
                "Introduction to TinKercad",
                "Hands-on with TinKercad",
                "Sending sensor data to ThinkSpeak",

            ],
            "projects": ["Mini Project-57: Blinking a LED",
                "Mini Project-58: Blinking Multiple LED",
                "Mini Project-59: Automatic Night Lamp",
                "Mini Project-60: Traffic Light Controller",
                "Mini Project-61: Fire Alarm System"],
        },
        {
            "title": "Introduction to Explainable Artificial Intelligence (XAI)",
            "instructor": ["Dr. Parteek Bhatia"]
        },
        {
            "title": "Lets build the Future",
            "instructor": ["Mr. Vansh Gehlot"]
        },
        {
            "title": "Time Series Analysis",
            "instructor": ["Mr. Rudransh Bansal"]
        },
        {
            "title": "Data Generation for ML using Modeling and Simulations",
            "instructor": ["Dr. PSRana"]
        }
    ],
    "outcome": "Students can have a basic understanding about the emerging technologies like Internet of Things, cloud services, time series, Explainable AI and can be able to apply various machine learning & deep learning approaches on them and build a number of minor and major projects. Students will also build Github profile and explore Kaggle.",
    "ableToDoAfterCompletion": [
        "Understands the basics of Internet of Things (IoT)",
        "Understands the basics of Time Series, Explainable AI",
        "Building profile on Github and Explore Kaggle"
    ],
    "note": "The content of the course 'Deep Learning & Applications' has been designed by the group of reputed researchers who have long trails of success in research related activities; The team for data science course has been headed by Dr. PS Rana.",
    "website": "https://www.psrana.com"
}




const EmergingTechnology = () => {
    return (
        <React.Fragment>
            <Header />
            <Navbar />
            <Container className={classes.Container}>

                <CssBaseline />
                <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>
                    {courseData.courseName}
                </Typography>
                <Typography variant="h6" style={{ textAlign: "center" }}>
                    {courseData.description}
                </Typography>


                <div className={classes.imgCont}>
                    <img src={headerImage} className='imgres' alt="a" />
                </div>
                <Nav.Link href="https://buy.stripe.com/cN25mj7VJg2PbxCaEM" target="_blank" style={{ textAlign: "center", minHeight: "90%", marginTop: "2%", marginBottom: "2%" }}><button type="button" class="btn btn-success">Buy Now</button></Nav.Link>
                <div className={classes.con}>
                    <Typography className={classes.Typography_gutterBottom} variant='h6'>
                        Instructor: {courseData.instructor}
                    </Typography>
                    <Typography className={classes.Typography_gutterBottom} variant='h6'>
                        Duration: {courseData.duration}
                    </Typography>
                    <Typography className={classes.Typography_gutterBottom} variant='h6'>
                        Price: {courseData.fees}
                    </Typography>
                </div>
                <Typography variant="h4" gutterBottom>
                    Outcome:

                </Typography>
                {courseData.outcome}
                <hr></hr>
                <Typography variant="h5" gutterBottom>
                    Able to do After completion of course:

                </Typography>
                <ul>
                    {courseData.ableToDoAfterCompletion.map((item, index) => (
                        <Typography component="li" key={index}>
                            {item}
                        </Typography>
                    ))}
                </ul>
                <hr></hr>
                <Typography variant="h4" gutterBottom>
                    Course Content:

                </Typography>
                {courseData.courseContent.map((section, index) => (
                    <div key={index}>
                        <Typography variant="h5" gutterBottom>
                            {index + 1}.{section.title}
                        </Typography>
                        <Typography className={classes.Typography_gutterBottom}>
                            Instructors: {section.instructor.join(', ')}
                        </Typography>

                        {section.topics && section.topics.length > 0 && (
                            <>
                                <Typography variant="h6" className={classes.Typography_variantH6}>
                                    Topics
                                </Typography>
                                <ul>
                                    {section.topics.map((project, pIndex) => (
                                        <Typography component="li" key={pIndex}>
                                            {project}
                                        </Typography>
                                    ))}
                                </ul>


                            </>
                        )}
                        {section.projects && section.projects.length > 0 && (
                            <>
                                <Typography variant="h6" className={classes.Typography_variantH6}>
                                    Mini Projects
                                </Typography>
                                <ul>
                                    {section.projects.map((project, pIndex) => (
                                        <Typography component="li" key={pIndex}>
                                            {project}
                                        </Typography>
                                    ))}
                                </ul>


                            </>
                        )}
                        <hr />


                    </div>
                ))}
                <p>
                    <span style={{ fontWeight: "bolder" }}>Please Note:</span> The content of the course “Emerging technologies (IoT, Time Series, XAI and Data Generation)” has been designed by the group of reputed researchers who have long trails of success in research related activities; The team for data science course has been headed by Dr. PS Rana.

                    <a href='https://www.psrana.com' target='_blank'>https://www.psrana.com</a>  </p>
            </Container>
            <Lower />
            <Footer />
        </React.Fragment>
    );

};

export default EmergingTechnology;
