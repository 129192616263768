import img1 from './images/1.jpg';
import img2 from './images/2.jpg';
import img3 from './images/3.jpg';
import img4 from './images/4.jpg';
import l1 from './images/logo/l1.png'
export const info=[
    {
    name:"Utkarsh Gupta",
    clg:"Netaji Subhash University of Technology",
    img:img2,
    desc:"Adroid research courses and Adroid school/training programme has equipped me with a solid foundation of transferable skills, such as problem-solving, critical thinking and adaptability"
    },
    {
        name:"Anish Noel Rasquinha",
        clg:"Manipal Institute of Technology",
        img:img1,
        desc:"Adroid Connectz provides a very Well Explored and detailed course, dives deep into the domain and teaches from basic to pro level, was very helpful."
    },
    {
        name:"Pabintha A",
        clg:"Ramco Institute of Technology",
        img:img3,
        desc:"Unlock the mysteries of AI, dive into the data-driven universe, and make waves in the realm of research papers. Join Adroid Connectz Summer School on ML and Data Science, where curiosity meets discovery! The best experience that ignited my passion for ML, data science, and research papers."
    },
    {
        name:"Devang Saini",
        clg:"Netaji Subhash University of Technology",
        img:img4,
        desc:"I really enjoyed my journey with the adroid summer school data science course. Worth the money!!!"
    },
]
export const logos=[
    {
        image:l1
    },
    {
        image:l1
    }
]