import React from 'react'

const Week3 = () => {
  return (
    <>
<div>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 15 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}><strong></strong></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp; Introduction to Web Development with HTML, CSS, Bootstrap and Github</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Introduction to Web Development | Mr Sahib Singh, Mr Yuvraj Singh |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://ssahibsingh.github.io/MERN-Session-TSS23/Day1/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a>
          <ul style={{listStyleType: 'square'}}>
            <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Topics: Structure of HTML, Tags, Forms, Semantics, Introduction to CSS, Basic concepts Bootstrap and Git/Github</span></li>
            <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Tools used: VS Code, Github, Github Desktop, Vercel and Netlify</span></li>
            <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Mini Project-19: Personal Portfolio</span></li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  <p><br /></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontWeight: 'bold', fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Resource of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial', fontSize: 'initial'}}>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Vscode |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://code.visualstudio.com/download" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Github Desktop |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://desktop.github.com/" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Git |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://git-scm.com/downloads" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Github |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Bootstrap |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://getbootstrap.com/" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>MDN HTML |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://developer.mozilla.org/en-US/docs/Web/HTML" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>MDN CSS |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://developer.mozilla.org/en-US/docs/Web/CSS" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Netlify |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.netlify.com/" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Vercel |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://vercel.com/" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><br /></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontWeight: 'bold', fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Assignment / Mini Project&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial', fontSize: 'initial'}}>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Mini Project-19: Personal Portfolio |&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 14, fontFamily: '"Arial",sans-serif', color: 'black'}}>&nbsp;</span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>

  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><br /></p>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 16 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp;Javascript, the duct tape of the Internet</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontWeight: 'bold', fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontWeight: 'initial', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Javascript, the duct tape of the Internet | Mr Sahib Singh, Mr Yuvraj Singh |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://ssahibsingh.github.io/MERN-Session-TSS23/Day2/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a>
          <ul style={{listStyleType: 'square', fontSize: 'initial'}}>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Self Learning:</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.youtube.com/watch?v=W6NZfCO5SIk" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Link-1</span></a><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;|&nbsp;</span><a href="https://ssahibsingh.github.io/MERN-Session-TSS23/Day2/JS%20Notes.pdf" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Link-2</span></a><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span></li>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Topics: JS Core Concepts, DOM, ES6 features, Synchronous/Asynchronous Programming &nbsp;</span></li>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Tools used: VS Code</span></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-20: Color Flipper:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/Yuvraj3905/mini-project-color-flipper-js" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-21:&nbsp;Clock:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/Yuvraj3905/mini-project-clock-js" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-22:&nbsp;Counter:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/Yuvraj3905/mini-project-counter-js" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-23:&nbsp;Hamburger Menu:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/Yuvraj3905/mini-project-hamburger-js" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
            <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-24:&nbsp;FAQs:</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/Yuvraj3905/mini-project-faqs-js" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Resource of the Day</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>MDN JS - |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, sans-serif', color: 'black'}}>&nbsp;</span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Assignment / Mini Project&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-20: Color Flipper</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-21:&nbsp;Clock</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-22:&nbsp;Counter</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-23:&nbsp;Hamburger Menu</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-24:&nbsp;FAQs</span><span style={{border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, sans-serif', color: 'black'}}>&nbsp;</span><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 17 &amp; Day 18 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp;Introduction to ReactJS and NodeJS</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Introduction to ReactJS, NodeJS | Mr Sahib Singh, Mr Yuvraj Singh |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/ssahibsingh/MERN-Session-TSS23/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Topics: Node Package Manager, NPX, React Components, JSX, State and Props, React Router, React Hooks, Nodejs</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Tools used: VS Code</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Pre-Requisites:&nbsp;</span>
          <ul style={{listStyleType: 'square', fontSize: 'initial'}}>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Install Node</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>&nbsp;|&nbsp;</span><a href="https://nodejs.org/en/download" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>VSCode Extensions:&nbsp;</span>
              <ul style={{listStyleType: 'square', fontSize: 'initial'}}>
                <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>ES7 React/redux/graphQL/React-Native snippets</span></li>
                <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Prettier</span></li>
              </ul>
            </li>
          </ul>
        </li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-25: eCommerce website</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>NodeJS Basics|</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.youtube.com/watch?v=65a5QQ3ZR2g&list=PL55RiY5tL51oGJorjEgl6NVeDbx_fO5jR" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Link - 1</span></a><span style={{fontFamily: 'Arial, sans-serif', color: 'black'}}>&nbsp;|&nbsp;</span><a href="https://nodejs.org/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Li</span></a><a href="https://nodejs.org/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>nk -&nbsp;</span></a><a href="https://nodejs.org/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)'}}>2</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>ReactJS Basics |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.youtube.com/watch?v=JPT3bFIwJYA&list=PL55RiY5tL51oyA8euSROLjMFZbXaV7skS" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Link - 1</span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)'}}>&nbsp;</span></a><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://legacy.reactjs.org/docs/getting-started.html" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Link - 2</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Resource of the Day</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>LOR and Cold Emailing&nbsp;</span>
          <ul style={{listStyleType: 'square', fontSize: 'initial'}}>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Go to</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://www.psrana.com/" target="_blank"><span style={{fontFamily: '"Arial",sans-serif', border: 'none windowtext 1.0pt'}}>psrana.com</span></a><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>-&gt; LOR</span></li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Assignment / Mini Project&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>Mini Project-25: eCommerce website</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 19 &amp; Day 20 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>&nbsp;Working with Express and MongoDB</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Working with Express and MongoDB&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>| Mr Sahib Singh, Mr Yuvraj Singh |</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://github.com/ssahibsingh/MERN-Session-TSS23/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Topics:</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span>
          <ul style={{listStyleType: 'square', fontSize: 'initial'}}>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Routing in Express, Error Handling, Request and Response Handling, Templating Engines, RESTful API development, Intro to Databases, CRUD Operations, Mongoose</span></li>
          </ul>
        </li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Tools used: Postman, VS Code</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Pre-Requisites:&nbsp;</span>
          <ul style={{listStyleType: 'square', fontSize: 'initial'}}>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Install Express</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>&nbsp;|&nbsp;</span><a href="https://expressjs.com/" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Install Mongodb</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>&nbsp;|&nbsp;</span><a href="https://www.mongodb.com/" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Install Postman</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>&nbsp;|&nbsp;</span><a href="https://www.postman.com/downloads/" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
          </ul>
        </li>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-25: eCommerce website</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Express</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>&nbsp;|&nbsp;</span><a href="https://www.youtube.com/watch?v=WXI27H8oPUM&list=PLbGui_ZYuhigerSlDF_v1t1mM2iGomXnq" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Mongodb</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>&nbsp;|&nbsp;</span><a href="https://www.youtube.com/watch?v=7giZGFDGnkc&list=PL55RiY5tL51rG1x02Yyj93iypUuHYXcB_" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Postman</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>&nbsp;|&nbsp;</span><a href="https://www.youtube.com/watch?v=95NBave0W_k&list=PLUDwpEzHYYLt3kLIHDLVjdnmJw7BDvVdE" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Assignment / Mini Project&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}><span style={{color: 'black', border: '1pt none windowtext'}}>Mini Project-25: eCommerce website |&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}} /><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 21 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
</div>

    </>
  )
}

export default Week3