import write from "./write.jpeg";
function Vismis(){
    return(
        <div className="mb-5" style={{overflow:"hidden",backgroundImage: `url(${write})`}}>
            <div className="float-start ms-5 ps-3 pt-5 pb-3" style={{marginRight:"30%"}}>
                <h1 className="pt-5">Our  <mark style={{color:"red"}}>

Vision
</mark></h1>
                <p>To accentuate student community by providing them an ecosystem of technological skills equipped with research dimension under expert guidance for exploring global opportunities.</p>
            </div>
            <div className="float-end me-5 pe-3 pt-5 pb-5" style={{marginLeft:"30%"}}>
                <h1 className="float-end pe-5">Our  <mark style={{color:"red"}}>

Mission    
</mark></h1>
                <div className="float-start">
                <li>To inculcate inquisitiveness (as thinker), knowledge (as learner), new ideas (as innovator) and skills (as implementer).</li>
<li>To introduce mentor expertise and guidance in the initial stages of career building.</li>
<li>To build technical and research oriented skills in accordance with the emerging technologies and innovations.</li>
<li>To enforce acquired skills for various research, internship, startups and job opportunities globally.</li>


                </div>
            </div>

        </div>
    )
}
export default Vismis