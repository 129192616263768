import React from 'react'
import './CSS/SummCSS.css'

export default function Table() {
  return (
    <div>
        <table className="table" style={{backgroundColor:'white'}}>
		<thead>
			<tr>
				<th  style={{"backgroundColor":"red","textAlign":"center","width":"2%"}}>Week&nbsp;</th>
				{/* <th  style={{"backgroundColor":"red","textAlign":"center","width":"15%"}}>Dates&nbsp;</th> */}
				<th  style={{"backgroundColor":"red","textAlign":"center","width":"45%"}}>Topics</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>&nbsp;1</td>
				
				{/* <td>
				<div rowSpan={1}>
				<tr>19 – 23 June 2023</tr>				
				</div>
				</td>				 */}
				<td><span>Basics of Python, OOPs and Problem-Solving using Python, Pandas, Numpy, Scipy</span><br /></td>
			</tr>
			<tr>
				<td>&nbsp;2</td>
				{/* <td>
				<div rowSpan={1}>
				<tr>26 – 30 June 2023</tr>				
				</div>
				</td>				 */}
				<td><span>Machine Learning, Optimization, Statistics and Exploratory Data Analysis (EDA)</span>&nbsp;</td>
			</tr>
			<tr>
				<td>&nbsp;3</td>
				{/* <td>
				<div rowSpan={1}>
				<tr>03 – 07 July 2023</tr>
				
				</div>
				</td> */}
				
				<td><span>Web Development - Basics (HTML, CSS, Bootstrap, Javascript, ReactJS, Express and MongoDB)</span></td>
			</tr>
			<tr>
				<td>&nbsp;4</td>
				{/* <td>
				<div rowSpan={1}>
				<tr>10 – 14 July 2023</tr>				
				</div>
				</td> */}
				<td><span>Web Development - Advance (NextJS, Django,  Hosting, ML Model Deployment, CRUD)</span></td>
			</tr>
			<tr>
				<td>&nbsp;5</td>
				{/* <td>
				<div rowSpan={1}>
				<tr>17 – 21 July 2023</tr>
				
				</div>
				</td>				 */}
				<td><span>Deep Learning for Image Processing and Natural Language Processing (NLP)</span></td>
			</tr>
			<tr>
				<td>&nbsp;6</td>
				{/* <td>
				<div rowSpan={1}>
				<tr>24 – 28 July 2023</tr>				
				</div>
				</td>				 */}
				<td><span>Time Series, Internet of Things (IoT), Cloud Services, Generative AI, Valedictory, Certificate distribution</span></td>
			</tr>
		</tbody>
	</table>
    </div>
  )
}