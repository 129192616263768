import React from 'react'
import Header from './Header'
import Navbar from './navbar'
import '../components/CSS/SummCSS.css'
import poster from "../components/poster.jpg"
import poster2 from "../components/poster2.jpg"
import pos3 from "./images/Adroid connectz-2-1.jpg"
import pos4 from "./images/Adroid connectz-2-2.jpg"
import Lower from './Lower'
import Footer from './Footer'

export default function Poster() {
    return (
        <div>
            <Header/>
            <Navbar/>
            <h1 style={
                        {color: "rgb(220 53 69)",'margin':'10px'}
                    } className="header-summ">Poster</h1>
                    <br/>
            <div style={{'width':'80%','margin':'auto'}}>
            <div className="posterDiv">
            <img src={poster}
                    className='img-thumbnail'
                    style={
                        {"width": '400px'}
                }>

                </img>
                <img src={poster2}
                    className='img-thumbnail'
                    style={
                        {"width": '400px'}
                }></img>
            </div>
            <div className="posterDiv">
            <img src={pos3}
                    className='img-thumbnail pos'
                  
                ></img>
                <img src={pos4}
                    className='img-thumbnail pos'
                   
                ></img>
            </div>              
            </div>
           
            <Lower/>
            <Footer/>
        </div>

    )
}
