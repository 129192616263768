import React from "react";
import style from "../../CSS/header.module.css";
import Button from 'react-bootstrap/Button';
import Date from "../Date";
import Header from "../../Header";
import NavBar from "../../navbar";
import Footer from "../../Footer";
import Lower from "../../Lower";

const Downloads = () => {
    return (
        <div>
        <Header />
        <NavBar />
        <div  style={{'backgroundColor':'red','padding':'4px'}}>
            <h2 className={
                style.abtHeader
            }>Downloads</h2>
            </div>
            <hr/>
            <div className={style.box}>
                <div className={
                    style.dCont
                }>
                    <div style={
                        {width: "50vw"}
                    }>
                       
                        <Button className={
                            style.downloadBadges
                        } href={require("./docs/icon.png")}
                        target="_blank">Manuscript Guidelines
                            <img src={
                                    require('./docs/icon.png')
                                }
                                className={
                                    style.icon
                            }></img>
                        </Button>
                    </div>

                </div>
                <Date/>    
            </div>
            <Lower />
            <Footer />
        </div>

    );
};

export default Downloads;
