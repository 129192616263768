import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


import App from './App';
ReactDOM.render(
<React.Fragment>

<App />
</React.Fragment>
, document.getElementById("root"));



