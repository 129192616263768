import React, {useEffect, useState} from 'react';
import './testimonial.css'
import quotes from "./images/quotes.png"
import {info,logos} from "./data.js";
import Button from './Background/Button';
import ImgBackground from './Background/ImgBackground';
import Curve from './Background/Curve';
import dot from './images/dot.svg';
import dot2 from './images/dot2.avif'
import { Link } from 'react-router-dom';



function Testimonials() {
    const [currentTestimonial, setCurrentTestimonial] = useState(0);
    useEffect(() => {
        const timer=setTimeout(() => {
            const isLastTestimonial = currentTestimonial === info.length -1;
            const newTestimonial = isLastTestimonial ? 0 : currentTestimonial + 1;
            setCurrentTestimonial(newTestimonial);
        }, 3000);
        return ()=>{
            clearTimeout(timer);
        }
          
    }, [currentTestimonial]);   
    

    const goToPreviousTestimonial = () => {
        const isFirstTestimonial = currentTestimonial === 0;
        const newTestimonial = isFirstTestimonial ? info.length -1 : currentTestimonial -1;
        setCurrentTestimonial(newTestimonial);    

    }

    const goToNextTestimonial = () => {
        const isLastTestimonial = currentTestimonial === info.length -1;
        const newTestimonial = isLastTestimonial ? 0 : currentTestimonial + 1;
        setCurrentTestimonial(newTestimonial);
    }
    const clickTestimonial=(i)=>{
        setCurrentTestimonial(i);
        // console.log(i);
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior:'instant'});
      };

    return (
        <>
            <section key={info[currentTestimonial].id} className="testimonials">
            <h1 className='text-center mt-5 text-xl text-danger'>How learners like you are achieving their goals . . .</h1>
                <div className="tcontainer">                                        
                    <div className="timg_container">                        
                        <figure className="portrait">
                            <ImgBackground />
                            <img src={info[currentTestimonial].img} alt={info[currentTestimonial].alt_img} className='timg'/>
                            <nav className="tnav_button">
                                <Button previousTestimonial={goToPreviousTestimonial} nextTestimonial={goToNextTestimonial} />
                            </nav>
                        </figure>
                    </div>

                    <div className="tinfo_container">
                        {/* <Quotes /> */}
                        <img src={quotes} style={{width:'40px'}}/>
                        <p className="bio"> {info[currentTestimonial].desc} </p>
                        <div className="personal_data">
                            <h3 className="name"> {info[currentTestimonial].name} </h3>
                            <h4 className="profession"> {info[currentTestimonial].clg} </h4>
                        </div>
                        <hr />
                        
                        <Link to='/courses' onClick={scrollToTop}><button className='button-63'>Explore Courses</button></Link>
                    </div>                  
                </div>
                <div className='flex text-center'>
                    {info.map((slide,i)=>{
                        if(i===currentTestimonial){
                            return <img src={dot2} style={{width:'30px',margin:'5px'}} onClick={()=>clickTestimonial(i)} />
                        }else{
                            return <img src={dot} style={{width:'10px',margin:'5px'}} onClick={()=>clickTestimonial(i)} />
                        }
                    })}
                   
                    
                </div>
                {/* <div className='explore'>
                    {logos.map((l,ind)=>{
                        return <img id={ind} src={l.image} />
                    })}
                </div> */}
                    
            </section>
            
            <Curve />
            
        </>
    )
}

export default Testimonials;