import React from 'react';
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CssBaseline } from '@mui/material';
import Header from '../Header'
import Navbar from '../navbar'
import Footer from '../Footer'
import Lower from '../Lower'
import classes from "./WebDevelopmentCourse.module.css"
import headerImage from '../images/webDevelopmentjpg.jpg'
import { Nav } from 'react-bootstrap';
const courseData = {
  "title": "Web Development",
  "level": "Basic and Advanced web development with Web framework",
  "instructor": "Team of Dr. PS Rana, Thapar Institute of Engineering & Technology, India",
  "duration": "2 Weeks",
  "price": "₹999/-",
  "outcome": [
    "Understand the basic to advanced Web Development",
    "Understands the basics of Express and MongoDB",
    "Understands the Integration and Deployment of Web Services",
    "Learn Product Development Lifecycle, SEO, and Coding Tricks, Web Scraping"
  ],
  "courseContent": [
    {
      "title": "Introduction to Web Development",
      "instructors": ["Mr Sahib Singh", "Mr Yuvraj Singh"],
      "topics": [
        "Structure of HTML, Tags, Forms, Semantics, Introduction to CSS, Basic concepts Bootstrap and Git/Github",
        "Tools used: VS Code, Github, Github Desktop, Vercel and Netlify"
      ],
      "miniProjects": ["Mini Project-19: Personal Portfolio"]
    },
    {
      "title": "Javascript, the duct tape of the Internet",
      "instructors": ["Mr Sahib Singh", "Mr Yuvraj Singh"],
      "topics": [
        "JS Core Concepts, DOM, ES6 features, Synchronous/Asynchronous Programming",
        "Tools used: VS Code"
      ],
      "miniProjects": [
        "Mini Project-20: Color Flipper",
        "Mini Project-21: Clock",
        "Mini Project-22: Counter",
        "Mini Project-23: Hamburger Menu",
        "Mini Project-24: FAQs"
      ]
    },
    {
      "title": "Introduction to ReactJS, NodeJS",
      "instructors": ["Mr Sahib Singh", "Mr Yuvraj Singh"],
      "topics": [
        "Node Package Manager, NPX, React Components, JSX, State and Props, React Router, React Hooks, Nodejs",
        "Tools used: VS Code",
        "Pre-Requisites: Install Node, VSCode Extensions: ES7 React/redux/graphQL/React-Native snippets, Prettier"
      ],
      "miniProjects": ["Mini Project-25: eCommerce website"]
    },
    {
      "title": "Working with Express and MongoDB",
      "instructors": ["Mr Sahib Singh", "Mr Yuvraj Singh"],
      "topics": [
        "Routing in Express, Error Handling, Request and Response Handling, Templating Engines, RESTful API development, Intro to Databases, CRUD Operations, Mongoose",
        "Tools used: Postman, VS Code",
        "Pre-Requisites: Install Express, Install Mongodb, Install Postman"
      ],
      "miniProjects": ["Mini Project-25: eCommerce website"]
    },
    {
      "title": "Integration and Deployment of Web Services",
      "instructors": ["Mr Sahib Singh", "Mr Yuvraj Singh"],
      "topics": ["Integration, Frontend Deployment, Backend Deployment", "Tools used: VS Code, Postman, Github, Github Desktop, Vercel, Netlify"],
      "miniProjects": ["Mini Project-26: Integrate fake store API"]
    },
    {
      "title": "NextJS",
      "instructors": ["Mr Sahib Singh", "Mr Yuvraj Singh"],
      "topics": [
        "Introduction to NextJS, Server side rendering, Static site generation, Routing, Dynamic Rendering, Data Fetching, Next API routes",
        "Tools used: VS Code"
      ],
      "miniProjects": ["Mini Project-27: Blogging Platform"]
    },
    {
      "title": "Introduction to Django",
      "instructors": ["Mr Tarik Bhateja", "Mr. Harjaspreet Singh"],
      "topics": [
        "Introduction to Django Framework",
        "Django Vs MERN",
        "Setup and Introduction to the Directory Structure of Django",
        "How Django Works (MVT)",
        "Introduction to Django",
        "Implementation of Django's directory system",
        "Urls and view layer(app)",
        "Model layer",
        "Data Access through the Admin panel",
        "Getting Started with Our First App"
      ],
      "miniProjects": ["Mini Project-28: PDF Protect with Password WebApp"]
    },
    {
      "title": "Mini Projects, ML Integration and Deployment",
      "instructors": ["Mr Tarik Bhateja", "Mr. Harjaspreet Singh"],
      "miniProjects": [
        "Mini Project-29: Cold Emailing WebApp",
        "Mini Project-30: Teachable Machine Integration",
        "Mini Project-31: Placement predictor WebApp",
        "Website Deployment using Linode, AWS, Azure"
      ],
      topics: []
    },
    {
      "title": "Product Development Lifecycle, SEO, and Coding Tricks (JUGAAD)",
      "instructors": ["Mr. Sahib Singh"],
      "topics": [
        "Product Development Lifecycle",
        "SEO",
        "Meta tags",
        "Flask Introduction",
        "Some Tools like Google Search Console",
        "Microsoft Clarity"
      ],
      "miniProjects": []
    },
    {
      "title": "Web Scraping and Data Extraction",
      "instructors": ["Mr. Gurtaran Singh"],
      "topics": [
        "Introduction to Web Scraping",
        "Ethics of Web Scraping",
        "Web Scraping Workflow",
        "Scraping Thapar Summer School Website using BeautifulSoup and Requests",
        "Introduction to Text Scraping and Data Extraction",
        "Text Extraction from Images, PDFs, CSVs etc",
        "Projects/opportunities for future"
      ],
      "miniProjects": [
        "Mini Project-35: Scraping raw data and getting useful information from Flipkart website",
        "Mini Project-36: Multi Text Extraction from Images"
      ]
    }
  ]
}
  ;

const WebDevelopmentCourse = () => {
  return (
    <React.Fragment>
      <Header />
      <Navbar />
      <Container className={classes.Container}>

        <CssBaseline />
        <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>
          {courseData.title}
        </Typography>
        <Typography variant="h5" style={{ textAlign: "center" }}>
          Level-{courseData.level}
        </Typography>


        <div className={classes.imgCont}>
          <img src={headerImage} className='imgres' alt="a" />
        </div>
        <Nav.Link href="https://buy.stripe.com/3cscOL0th03RgRW4gj" target="_blank" style={{ textAlign: "center", minHeight: "90%", marginTop: "2%", marginBottom: "2%" }}><button type="button" class="btn btn-success">Buy Now</button></Nav.Link>
        <div className={classes.con}>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Instructor: {courseData.instructor}
          </Typography>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Duration: {courseData.duration}
          </Typography>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Price: {courseData.price}
          </Typography>
        </div>
        <Typography variant="h4" gutterBottom>
          Outcome:

        </Typography>
        <p> Students can have a basic and advanced understanding of web development, web deployment. They can be able to design their own website as a minor and major projects. Students will also build Github profile and explore Kaggle.</p>
        <Typography variant="h5" gutterBottom>
          Able to do after completion of this course:

        </Typography>
        <ul>
          {courseData.outcome.map((item, index) => (
            <Typography component="li" key={index}>
              {item}
            </Typography>
          ))}
        </ul>
        <hr></hr>
        <Typography variant="h4" gutterBottom>
          Course Content:

        </Typography>
        {courseData.courseContent.map((section, index) => (
          <div key={index}>
            <Typography variant="h5" gutterBottom>
              {index + 1}.{section.title}
            </Typography>
            <Typography className={classes.Typography_gutterBottom}>
              Instructors: {section.instructors.join(', ')}
            </Typography>
            <ul>
              {section.topics.map((topic, i) => (
                <Typography component="li" key={i}>
                  {topic}
                </Typography>
              ))}
            </ul>
            {section.miniProjects.length > 0 && (
              <>
                <Typography variant="h6" className={classes.Typography_variantH6}>
                  Mini Projects
                </Typography>
                <ul>
                  {section.miniProjects.map((project, pIndex) => (
                    <Typography component="li" key={pIndex}>
                      {project}
                    </Typography>
                  ))}
                </ul>
              </>
            )}
            <hr />


          </div>
        ))}
        <p>
          <span style={{ fontWeight: "bolder" }}>Please Note:</span>  The content of the course “Web Development” has been designed by the group of reputed researchers who have long trails of success in research related activities; The team for data science course has been headed by Dr. PS Rana.
          <a href='https://www.psrana.com' target='_blank'>https://www.psrana.com</a>  </p>
      </Container>
      <Lower />
      <Footer />
    </React.Fragment>
  );

};

export default WebDevelopmentCourse;
