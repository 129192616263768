import React from 'react';
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CssBaseline } from '@mui/material';
import Header from '../Header'
import Navbar from '../navbar'
import Footer from '../Footer'
import Lower from '../Lower'
import classes from "./WebDevelopmentCourse.module.css"
import headerImage from '../images/deep-learning.jpg'
import { Nav } from 'react-bootstrap';
const courseData = {
  "courseName": "Deep Learning & Applications",
  "description": "A course on deep learning with various applications for beginners",
  "instructor": "Dr. PS Rana, Thapar Institute of Engineering & Technology, India",
  "duration": "1 Week",
  "price": "₹499/-",
  "outcome": [
    "Students can have a basic understanding about the emerging technologies like image processing, Natural language processing, blockchain, Internet of Things, web development, cloud services, time series, Explainable AI and can be able to apply various deep learning approaches on them and build a number of minor and major projects.",
    "Students will also build Github profile and explore Kaggle."
  ],
  "ableToDo": [
    "Understand the basics of Image Processing and Deep Learning",
    "Understands the basics of Natural Language Processing (NLP) and Blockchain",
    "Building profile on Github and Explore Kaggle"
  ],
  "courseContent": [
    {
      "title": "Introduction to Image Processing, OpenCV and Yolo",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "Intro to image processing",
        "Image Processing using OpenCV",
        "Image Processing using Scikit learn",
        "Intro to Yolo",
        "Yolo Architecture",
        "Object Detection with Yolo",
        "Projects/opportunities for future",

      ],
      "projects": [
        "Mini Project-39: Face Mask Detection using Yolo V7",
        "Mini Project-40: Social Distancing using Yolo V3"
      ],
    },
    {
      "title": "Deep Learning: Image Processing",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "Basics of Deep Learning",
        "Neural networks",
        "Forward Propagation",
        "Backward Propagation",
        "Terminologies for Neural Networks",
        "Steps for building a deep learning model",
        "CNNs",
        "UNet",
        "ResNet",
        "Projects/opportunities for future",
      ],
      "projects": [
        "Mini Project-41: Use Deep Learning for Image Classification",
        "Mini Project-42: Using CNNs for Image Classification"
      ],
    },
    {
      "title": "Introduction to Generative Adversarial Network (GAN)",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "What is generative AI",
        "Capabilities of GANs",
        "What are GANs",
        "GAN architecture",
        "Generator",
        "Discriminator",
        "Training a GAN",
        "Demo of Basic GANs",
        "Demo of CycleGAN",
        "Demo of SRGAN",

      ],
      "projects": [
        "Mini Project-46: DCGAN",
        "Mini Project-47: SRGAN"
      ],
    },
    {
      "title": "Introduction to Natural Language Processing",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "What is Natural Language",
        "Preprocessing Textual Data",
        "RNN",
        "LSTM",
        "Mini Project-49: Toxic Comment Classification using RNN and LSTM"
      ],
      "projects": [
        "Mini Project-49: Toxic Comment Classification using RNN and LSTM",
      ],
    },
    {
      "title": "Introduction to Natural Language Processing",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "History of NLP",
        "NLP  Pipeline",
        "Attention is all you need",
        "Transformer Architecture",
        "BERT",
        "BERT vs Alternates",
        "Change from GPT3.5 onwards",
        "BERT Code",
        "Mini Project-54: Sentiment Analysis on Hospital Comments"
      ],
      "projects": [
        "Mini Project-54: Sentiment Analysis on Hospital Comments"
      ],
    },
    {
      "title": "Introduction to LLM (Large Language Models)",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj", "Vansh Gahlot"],
      "topics": [
        "Large Language Models",
        "How do large language models work",
        "RLHF",
        "Pros and Cons of LLMs",
        "Hallucinations in LLMs",
        "Ethics in LLMs",
        "Prompt Engineering",
        "Cost Optimization in LLMs",
        "Develop applications using LLM APIs (Langchain)",
        "ThaparGPT Demo"
      ]
    }
  ],
  "note": "The content of the course “Deep Learning & Applications” has been designed by the group of reputed researchers who have long trails of success in research related activities; The team for data science course has been headed by Dr. PS Rana.",
  "website": "https://www.psrana.com"
}



const DeepLearningCourse = () => {
  return (
    <React.Fragment>
      <Header />
      <Navbar />
      <Container className={classes.Container}>

        <CssBaseline />
        <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>
          {courseData.courseName}
        </Typography>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          {courseData.description}
        </Typography>


        <div className={classes.imgCont}>
          <img src={headerImage} className='imgres' alt="a" />
        </div>
        <Nav.Link href="https://buy.stripe.com/8wM6qngsf5obaty4gl" target="_blank" style={{ textAlign: "center", minHeight: "90%", marginTop: "2%", marginBottom: "2%" }}><button type="button" class="btn btn-success">Buy Now</button></Nav.Link>
        <div className={classes.con}>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Instructor: {courseData.instructor}
          </Typography>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Duration: {courseData.duration}
          </Typography>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Price: {courseData.price}
          </Typography>
        </div>
        <Typography variant="h4" gutterBottom>
          Outcome:

        </Typography>
        {courseData.outcome}
        <hr></hr>
        <Typography variant="h5" gutterBottom>
          Able to do After completion of course:

        </Typography>
        <ul>
          {courseData.ableToDo.map((item, index) => (
            <Typography component="li" key={index}>
              {item}
            </Typography>
          ))}
        </ul>
        <hr></hr>
        <Typography variant="h4" gutterBottom>
          Course Content:

        </Typography>
        {courseData.courseContent.map((section, index) => (
          <div key={index}>
            <Typography variant="h5" gutterBottom>
              {index + 1}.{section.title}
            </Typography>
            <Typography className={classes.Typography_gutterBottom}>
              Instructors: {section.instructors.join(', ')}
            </Typography>

            {section.topics && section.topics.length > 0 && (
              <>
                <Typography variant="h6" className={classes.Typography_variantH6}>
                  Topics
                </Typography>
                <ul>
                  {section.topics.map((project, pIndex) => (
                    <Typography component="li" key={pIndex}>
                      {project}
                    </Typography>
                  ))}
                </ul>


              </>
            )}
            {section.projects && section.projects.length > 0 && (
              <>
                <Typography variant="h6" className={classes.Typography_variantH6}>
                  Mini Projects
                </Typography>
                <ul>
                  {section.projects.map((project, pIndex) => (
                    <Typography component="li" key={pIndex}>
                      {project}
                    </Typography>
                  ))}
                </ul>


              </>
            )}

            <hr />


          </div>
        ))}
        <p>
          <span style={{ fontWeight: "bolder" }}>Please Note:</span> The content of the course “Deep Learning & Applications” has been designed by the group of reputed researchers who have long trails of success in research related activities; The team for data science course has been headed by Dr. PS Rana.

          <a href='https://www.psrana.com' target='_blank'>https://www.psrana.com</a>  </p>
      </Container>
      <Lower />
      <Footer />
    </React.Fragment>
  );

};

export default DeepLearningCourse;
