import React from 'react'

const Week6 = () => {
  return (
    <>
<div>
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 36 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong> (1) Guidance for Placement and (2) How to write a research paper</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Guidance for Placement and CV Building | Mr Gaurav Gupta and Ms Gurbani Kaur</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://docs.google.com/presentation/d/1YjYVVI3GXN1yaz1Vh2262p6SqlJU8Bjp4RX8JgCJREA/mobilepresent?slide=id.g25812351482_0_196" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>How to write a research paper&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>| Dr PSRana</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/psrana/How-to-Write-Research-Papers" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)&nbsp;|&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, sans-serif', color: 'black'}}>&nbsp;</span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>|</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>

  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, sans-serif', color: 'black'}}>&nbsp;</span></p>
  
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 37 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>Introduction to IoT (Internet of Things) and its Applications</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Introduction to IoT (Internet of Things) and its Applications | Dr. Anjula Mehto</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://drive.google.com/drive/u/2/folders/12qg-X1qWmqzkGEH-6seSf6fhQ-0QxGIH" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Topics</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span>
          <ul className="decimal_type" style={{listStyleType: 'square', fontSize: 'initial'}}>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>The World of IoT</span></li>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>IoT Applications</span></li>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>IoT building Components</span></li>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Case Study and Making a thing</span></li>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Industry 4.0- Smart Manufacturing</span></li>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Introduction to Cloud and Edge Computing</span></li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>Basic concept of Arduino&nbsp;|&nbsp;</span><a href="https://www.youtube.com/watch?v=Pd754nSIr_E&list=RDCMUCGFNZxMqKLsqWERX_N2f08Q&start_radio=1" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>ESP32 tutorial&nbsp;|&nbsp;</span><a href="https://www.youtube.com/watch?v=_0eHPrRC8oc&list=PLfPtpZzK2Z_QO8snrdnRTTNtQvLw35Zfc" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>Wokwi - Online Electronics Simulator&nbsp;|&nbsp;</span><a href="https://docs.wokwi.com/?utm_source=wokw" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Resource of the Day</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>Internet Society&nbsp;|&nbsp;</span><a href="https://www.internetsociety.org/iot/?gclid=CjwKCAjw2K6lBhBXEiwA5RjtCXfvLjVphZQnJfRMG6pjzAzD7jQmr_C2uIoduK7PAh80K5fYSApXahoCDMMQAvD_BwE" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Quiz-1</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>To start&nbsp;|&nbsp;</span><a href="https://forms.gle/6mke4PcAEdz2yRYo7" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>|</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 38 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>IoT hands-on Projects with Tinkercad</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Introduction to IoT (Internet of Things) and its Applications | Dr. Anjula Mehto</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/AnjulaMehto/Projects_with_Tinkercad" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Topics</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span>
          <ul style={{listStyleType: 'square', fontSize: 'initial'}}>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Introduction to TinKercad</span></li>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Hands-on with TinKercad</span></li>
            <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Sending sensor data to ThinkSpeak</span></li>
          </ul>
        </li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Mini Project-57: Blinking a LED&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/AnjulaMehto/Projects_with_Tinkercad" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Mini Project-58: Blinking Multiple LED&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/AnjulaMehto/Projects_with_Tinkercad" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Mini Project-59: Automatic Night Lamp&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/AnjulaMehto/Projects_with_Tinkercad" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Mini Project-60: Traffic Light Controller&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/AnjulaMehto/Projects_with_Tinkercad" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Mini Project-61: Fire Alarm System&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/AnjulaMehto/Projects_with_Tinkercad" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>TinKercad Projects&nbsp;|&nbsp;</span><a href="https://www.tinkercad.com/projects?product=circuits" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>Best Tinkercad Arduino Projects&nbsp;|&nbsp;</span><a href="https://all3dp.com/2/best-tinkercad-arduino-projects/" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Resource of the Day</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>IoT Resources</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/AnjulaMehto/Projects_with_Tinkercad" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Assignment / Mini Project&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Tomorrow 23:59:59</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>Mini Project-57: Blinking a LED&nbsp;|&nbsp;</span><a href="https://github.com/AnjulaMehto/Projects_with_Tinkercad" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>Mini Project-58: Blinking Multiple LED&nbsp;|&nbsp;</span><a href="https://github.com/AnjulaMehto/Projects_with_Tinkercad" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here&nbsp;</span></a><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>Mini Project-59: Automatic Night Lamp&nbsp;|&nbsp;</span><a href="https://github.com/AnjulaMehto/Projects_with_Tinkercad" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here&nbsp;</span></a><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>Mini Project-60: Traffic Light Controller&nbsp;|&nbsp;</span><a href="https://github.com/AnjulaMehto/Projects_with_Tinkercad" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here&nbsp;</span></a><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>Mini Project-61: Fire Alarm System&nbsp;|&nbsp;</span><a href="https://github.com/AnjulaMehto/Projects_with_Tinkercad" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here&nbsp;</span></a><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>Mini Project-62: Room Lighting System&nbsp;|&nbsp;</span><a href="https://docs.google.com/document/d/1YoE9sFi2-bg3MwtWloTtjJd__e51P5jsgmwk22XfRXg/edit" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>Mini Project-63: Smart Irrigation System&nbsp;|&nbsp;</span><a href="https://docs.google.com/document/d/1YoE9sFi2-bg3MwtWloTtjJd__e51P5jsgmwk22XfRXg/edit" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>&nbsp;|&nbsp;</span><a href="https://forms.gle/uuKyDjT6rfkq2VRr7" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Submission Link</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>|</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, sans-serif', color: 'black'}}>&nbsp;</span></p>
  <div style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif'}}>
    <ul style={{marginBottom: '0cm', listStyleType: 'disc', marginLeft: 32}}>
      <li style={{margin: '0cm 0cm 0.0001pt', fontSize: 16, fontFamily: 'Calibri, sans-serif', fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Kaggle Hackathon-2&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><strong>|</strong></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>31st July 2023 07:59:59 am |&nbsp;</></span><><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)'}}>Click Here</span></></li>
    </ul>
  </div>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 39 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong><span>(1) Introduction to Explainable Artificial Intelligence (XAI) and (2) Lets build the Future</span></strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Introduction to Explainable Artificial Intelligence (XAI) | Dr. Parteek Bhatia</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://parteekbhatia.com/" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Lets build the Future | Mr. Vansh Gehlot&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://bit.ly/figma_ideas" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Resource of the Day</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Pitch Deck</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://gamma.app/" target="_blank"><span style={{fontFamily: 'Roboto', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Website</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://www.framer.com/" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Idea Hub</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://bit.ly/figma_ideas" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Idea Validate</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://venturusai.com/" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>|</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <hr />
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}} /><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(199, 18, 18)', border: '1pt none windowtext', padding: '0cm'}}><strong>Day 40 |&nbsp;</strong></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext', padding: '0cm'}}></span><span style={{fontSize: 20, fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext', padding: '0cm'}}><strong>(1) Time Series Analysis (2) Data Generation for ML using Modeling and Simulations and (3) Valedictory</strong></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)'}}><>Topic of the Day</></span>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Time Series Analysis&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>| Mr. Rudransh Bansal</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://parteekbhatia.com/" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>Data Generation for ML using Modeling and Simulations | Dr. PSRana&nbsp;</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>|&nbsp;</span><a href="https://github.com/psrana/Data-Generation-using-Modelling-and-Simulation-for-ML" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Self Learning</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>Time Series Playlist by Krish Naik&nbsp;|&nbsp;</span><a href="https://www.youtube.com/watch?v=vhI0Nr1hHCY" target="_blank"><span style={{fontFamily: 'Roboto'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>AI Engineering Time Series Modelling and Analysis&nbsp;|&nbsp;</span><a href="https://www.youtube.com/watch?v=-r7wB9DJtiU&list=PL3N9eeOlCrP5cK0QRQxeJd6GrQvhAtpBK" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16}}><strong><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}>Resource of the Day</span></strong>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>State-of-the-art Deep Learning Models for Time Series Analysis&nbsp;|&nbsp;</span><a href="https://github.com/timeseriesAI" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>T</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>ime Series&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>D</span><span style={{fontFamily: 'Roboto', color: 'black', border: '1pt none windowtext'}}>ataset&nbsp;|&nbsp;</span><a href="https://github.com/rudranshbansal/ThaparSummerSchool_TS/tree/main/DataSets" target="_blank"><span style={{fontFamily: 'Roboto', border: '1pt none windowtext', color: 'rgb(18, 39, 224)'}}>Click Here</span></a></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <ul style={{listStyleType: 'disc', marginLeft: 32}}>
    <li style={{fontSize: 16, fontWeight: 'bold'}}><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(32, 206, 136)'}}><>Daily Task</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(43, 154, 37)', border: '1pt none windowtext'}}><strong>&nbsp;</strong></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>|</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(247, 55, 58)', border: '1pt none windowtext'}}><>Due Date:</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><>&nbsp;</></span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}><>Today 23:59:59 |</></span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}><strong>&nbsp;</strong></span><a href="https://forms.gle/kBDmvj3rjD2pbrsV8" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}><>Submission Link</></span></a>
      <ul style={{listStyleType: 'undefined', fontSize: 'initial', fontWeight: 'initial'}}>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>5-Points of today's learning (Revision of Today's Lecture)</span></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-One Page writing (Two papers) |&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>For research papers (Go to Resource --&gt; 5. One Page Writing)</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>|</span><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>&nbsp;</span><a href="https://www.adroidconnectz.com/rmds/resource" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Generative AI Tool | Future Tools -&nbsp;</span><a href="https://www.futuretools.io/" target="_blank"><span style={{fontFamily: 'Arial, sans-serif'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Audio Book |</span><span style={{fontFamily: 'Arial, sans-serif', border: '1pt none windowtext', color: 'black'}}>&nbsp;</span><a href="https://github.com/psrana/Must-Read-Books" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>2-Interview Puzzles |&nbsp;</span><a href="https://github.com/psrana/Puzzles-for-Interview" target="_blank"><span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(18, 39, 224)', border: '1pt none windowtext'}}>Click Here</span></a></li>
        <li style={{fontSize: 16}}><span style={{fontFamily: 'Arial, sans-serif', color: 'black', border: '1pt none windowtext'}}>1-Activity that I love</span></li>
      </ul>
    </li>
  </ul>
  <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: 16, fontFamily: '"Times New Roman",serif', margin: '0cm', marginBottom: '.0001pt', lineHeight: '18.0pt', verticalAlign: 'baseline'}}><span style={{fontSize: 16}}><br /></span></p>
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 16}}>&nbsp;</span></p>
</div>

    </>
  )
}

export default Week6