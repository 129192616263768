import React from 'react'

import j1 from "./j1.jpg";
import j2 from "./j2.jpg";
import j3 from "./j3.jpg";
import Header from '../Header';
import Footer from '../Footer';
import Lower from '../Lower';
import NavBar from '../navbar';
import Cards from './cards';

const Journals = () => {
  return (
    <div style={
        {backgroundColor: "#CD3333"}
    }>
        <Header/>
        <NavBar/>
        <h1 style={{textAlign:'center', backgroundColor:'white', width:'100%',color:'black',padding:'10px'}}>Journals</h1>
        <div className="container">        
        <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col">
                        <Cards src={j1}
                            heading="International Journal on Smart & Sustainable Intelligent Computing"
                            style={{'height':'80px'}} link="https://www.adroidjournals.com/journal/IJSSIC"
                            />
                    </div>
                    <div class="col">
                        <Cards src={j2}
                            heading="International Journal on Computational Modelling Applications" link="https://www.adroidjournals.com/journal/IJCMA"
                            />
                    </div>
                    <div class="col">
                        <Cards src={j3}
                            heading="International Journal on Engineering Artificial Intelligence Management, Decision Support, and Policies" link="https://www.adroidjournals.com/journal/IJAIMD"
                            />
                    </div>
        </div>
    </div>
    <Lower/>
    <Footer/>
    </div>
  )
}

export default Journals