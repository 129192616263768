import React from 'react'
import Service1 from "../components/student.jpeg"
import Header from './Header'
import NavBar from './navbar'
import Lower from './Lower'
import Footer from './Footer'
import intern from "./images/intern.jpg"

import './CSS/intern.css'
import CallForIntern from './CallForIntern'

const Internships = () => {
  return (
    <div>
    <Header />
    <NavBar />
        <div class="" style= {{ marginTop: '2%',marginBottom: '0%'}}>
     <div class="base_header">
     {/* <h1 className=''>Internships</h1>  */}
     <img src={intern} className='imgInt'/>
 
    <CallForIntern /> 
    
    </div>
    
 
        </div>
        <Lower />
        <Footer />
    </div>
  )
}

export default Internships