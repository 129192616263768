import React, { Component } from 'react'
import intern2 from "./images/intern2.jpg"

export default class CallForIntern extends Component {
  render() {
    return (
      <div style={{'backgroundColor':'red','marginTop':'-40px'}}>
      <div class="container" style={{margin: '',textAlign:'justify',}}>
      <div class="row">            
          <div class="col-md-12 col-lg-12">              
                  <div class="container">
                  <div class="container" className="border mt-5 mb-5 pt-4 pb-4 pe-4 ps-4" style={{ position:"" ,fontSize:"18px",lineHeight:"1.5",borderRadius:'20px','backgroundColor':'white'}}>
                  <h1 className='text-center'>Internships </h1>
                  <p>Adroid Connectz aims to provide students with few internships, which helps them to understand the emerging technologies and the needs of Industry. It is an Academia and Industry Interaction programme useful for students to understand the needs of the industry and inherit the industry related capabilities to build a successful career. Adroid Connectz aims to provide these internships on emerging topics useful for students and industry to collaborate together.</p>

                  <div class="base_header">
                  {/* <span><small class="bor_header_left"></small>ADROIDCONNECTZ<small class="bor_header_right"></small></span> */}
<hr/>
                      <h3>Call for <mark style={{ color: "red", backgroundColor: "white" }}>Internships</mark></h3>
                  </div>
                  
                  <div>
                 
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 20, fontFamily: '"Calibri",sans-serif'}}>
    
  </p>
 
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif'}}>
    <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>1. Adroid Connectz Pvt Ltd need Interns for&nbsp;
      <strong>Developing AI Tool</strong>.&nbsp;</span>
  </p>
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif'}}>
    <strong>
      <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>Desirable skills:</span>
    </strong>
  </p>
  <ul style={{listStyleType: 'disc'}}>
    <li>
      <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>Knowledge of Research Papers.</span>
    </li>
    <li>
      <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>Knowledge of ML, DL, Future AI Tools.</span>
    </li>
    <li>
      <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>Abilities to handle workload and complete the assigned work timely.</span>
    </li>
    <li>
      <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>Interested to work on new AI Tool development.</span>
    </li>
  </ul>
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif'}}>
    <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>The candidates who are interested can submit the consent with CV on adroidconnectz@gmail.com</span>
  </p>
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif'}}>
    <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>The stipend for the internship depends on the skills of the candidate.</span>
  </p>
  <br />
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif'}}>
    <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>2. Adroid Connectz Pvt Ltd need Interns for
      <strong>Content Development and Graphic Designer</strong>.&nbsp;</span>
  </p>
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif'}}>
    <strong>
      <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>Desirable skills:</span>
    </strong>
  </p>
  <ul style={{listStyleType: 'disc'}}>
    <li>
      <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>Knowledge of Research.</span>
    </li>
    <li>
      <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>Knowledge of Basic Graphic Tools to develop Posters and Video.</span>
    </li>
    <li>
      <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>Good command on Language to proof read the content and improve it.</span>
    </li>
    <li>
      <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>Abilities to handle workload and complete the assigned work timely.</span>
    </li>
  </ul>
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif'}}>
    <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>The candidates who are interested can submit the consent with CV on adroidconnectz@gmail.com</span>
  </p>
  <p style={{margin: '0cm', marginBottom: '.0001pt', fontSize: 16, fontFamily: '"Calibri",sans-serif'}}>
    <span style={{fontFamily: '"Segoe UI",sans-serif', color: '#212529'}}>The stipend for the internship depends on the skills of the candidate.</span>
  </p>
  <br />
</div> 
   <div class="container">   
                <div class="row">
                  <div>
                 
                  <h4>Upcoming Internships</h4>
                  <div
                    class="col-md-6 col-lg-4 col-xl-4"
                    style={{ marginTop: "2%" }}
                  >
                   
                      <div class="card category-card" key="1">
                        <img
                          class="card-img-top w-100 d-block"
                          src={intern2}
                          alt="service1"
                        />
                        <div class="card-body">
                          <h4 class="card-title">Internship program on Quantum Computing</h4>
                        </div>
                      </div>
                  </div> 
                  </div>
                </div>
            </div>
       </div>
              
              </div>
          </div>
          
      </div>
  </div>
 

  </div>
    )
  }
}


