import React from 'react'

function DetailedCourse() {
    return (
        <div>
            <div>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>
                    <strong>&nbsp;</strong>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify'
                    }
                }>
                    <strong>
                        <span style={
                            {fontSize: 19}
                        }>Detailed Schedule of the International Summer School on Research Methods and Data Science 2023 being organized by Shaheed Sukhdev College of Business Studies, University of Delhi and Adroidconnectz. The summer school mainly have the two modules during the six weeks internship cum training program.</span>
                    </strong>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>&nbsp;</p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>
                    <span style={
                        {fontSize: 27}
                    }>Module 1: Basic Research Course [One-week]</span>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>
                    <span style={
                        {fontSize: 19}
                    }>A step-by-step research guidance</span>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>&nbsp;</p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify'
                    }
                }>
                    <strong>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Outcome:&nbsp;</span>
                    </strong>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify'
                    }
                }>
                    <span style={
                        {fontFamily: '"Times New Roman",serif'}
                    }>Student can be able to read the articles, understands them, formulate the problem, and write a survey/review/empirical article using any of the problem-solving approaches in an ethical way under the able guidance from a mentor (having research and paper publication experience) and being able to publish his/her article in either a magazine or national/international conference using the various tools and software for paper publishing learnt during the course.</span>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify'
                    }
                }>
                    <span style={
                        {fontFamily: '"Times New Roman",serif'}
                    }>&nbsp;</span>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify'
                    }
                }>
                    <strong>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Able to do after completion of this course:&nbsp;</span>
                    </strong>
                </p>
                <ul style={
                    {
                        listStyleType: 'disc',
                        marginLeft: 8
                    }
                }>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Learn to Problem formulation/identification</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Apply approaches to problem solving</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Learn Paper reading/writing skills</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Learn Presentation skills</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Publication ethics</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Paper Publications&nbsp;</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Awareness about the various tools and software</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Blockchain secured Certification to be issued by Adroidconnectz, Shaheed Sukhdev College of Business Studies and Ramanujan College, University of Delhi.</span>
                    </li>
                </ul>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>
                    <span style={
                        {fontFamily: '"Times New Roman",serif'}
                    }>&nbsp;</span>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>
                    <strong>
                        <span style={
                            {
                                fontSize: 19,
                                fontFamily: '"Times New Roman",serif'
                            }
                        }>Course Content</span>
                    </strong>
                </p>
                <ol style={
                    {
                        listStyleType: 'decimal',
                        marginLeft: 8
                    }
                }>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Introduction</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>What is Research</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Types of Research</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Components of Research</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Why do we code and its importance.</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Basic elements of Research paper writing</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Developing advanced reading skills&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Research Ethics</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Research Process</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Research Question</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Start your research?</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Tools for writing a Research Paper</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>How to write an academic research paper or report</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>How to write a good paper for a journal</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Writing and presenting a paper in conference/seminar/symposia</span>
                    </li>
                </ol>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify'
                    }
                }>
                    <strong>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>&nbsp;</span>
                    </strong>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>
                    <span style={
                        {
                            color: '#0563C1',
                            textDecoration: 'underline'
                        }
                    }>
                        <span style={
                            {
                                fontSize: 13,
                                fontFamily: '"Times New Roman",serif'
                            }
                        }>
                            <span style={
                                {textDecoration: 'none'}
                            }>&nbsp;</span>
                        </span>
                    </span>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>
                    <span style={
                        {fontSize: 27}
                    }>Module 2: Data Science Course [Six-Weeks]</span>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>
                    <span style={
                        {fontSize: 19}
                    }>A complete beginner’s course for machine learning and deep learning</span>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>&nbsp;</p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify'
                    }
                }>
                    <strong>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Outcome:&nbsp;</span>
                    </strong>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify'
                    }
                }>
                    <span style={
                        {fontFamily: '"Times New Roman",serif'}
                    }>Student can be able to learn and use the basic python to solve a number of problems. They can be able to apply basic machine learning, and optimization approaches for problem solving. Students can have a basic understanding about the emerging technologies like image processing, Natural language processing, blockchain, Internet of Things, web development, cloud services, time series, Explainable AI and can be able to apply various machine learning and deep learning approaches on them and build a number of minor and major projects. Students will also learn how to write and publish related research paper.</span>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify'
                    }
                }>
                    <span style={
                        {fontFamily: '"Times New Roman",serif'}
                    }>&nbsp;</span>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify'
                    }
                }>
                    <strong>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Able to do after completion of this course:&nbsp;</span>
                    </strong>
                </p>
                <ul style={
                    {
                        listStyleType: 'disc',
                        marginLeft: 8
                    }
                }>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Able to use basics of Python for Problem Solving</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Able to apply Machine Learning, Optimization, Statistics, and&nbsp;Exploratory Data Analysis (EDA) to variety of problems</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Understand the basics of Image Processing and Deep Learning</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Understands the basics of Natural Language Processing (NLP) and Blockchain</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Understands the basics of Internet of Things (IoT), Web Development and Cloud Services</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Understands the basics of Time Series, Explainable AI</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Able to learn How to write and publish research papers</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Blockchain secured Certification to be issued by Adroidconnectz, Shaheed Sukhdev College of Business Studies and Ramanujan College, University of Delhi</span>
                    </li>
                </ul>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>
                    <strong>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>&nbsp;</span>
                    </strong>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>
                    <strong>
                        <span style={
                            {
                                fontSize: 19,
                                fontFamily: '"Times New Roman",serif'
                            }
                        }>Course Content</span>
                    </strong>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        verticalAlign: 'baseline'
                    }
                }>
                    <strong>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                border: 'none windowtext 1.0pt',
                                padding: '0cm'
                            }
                        }>Week 1. Theme: Basics of Python and Problem Solving</span>
                    </strong>
                </p>
                <ol className="decimal_type"
                    style={
                        {listStyleType: 'decimal'}
                }>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt',
                                color: 'black',
                                border: 'none windowtext 1.0pt'
                            }
                        }>Briefing of the course</span>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt'
                            }
                        }>&nbsp;</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Basics of Python Programming</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Advance Python (Numpy, Matplotlib, Scipy, Pandas)</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Basics of R Programming, Topsis, Feature Extraction (Audio, DNA)</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Mini Projects and Automation using Python</span>
                    </li>
                </ol>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        verticalAlign: 'baseline'
                    }
                }>
                    <span style={
                        {fontFamily: '"Times New Roman",serif'}
                    }>&nbsp;</span>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        verticalAlign: 'baseline'
                    }
                }>
                    <strong>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                border: 'none windowtext 1.0pt',
                                padding: '0cm'
                            }
                        }>Week 2. Theme: Machine Learning, Optimization, Statistics, and&nbsp;Exploratory Data Analysis (EDA)</span>
                    </strong>
                </p>
                <ol className="decimal_type"
                    style={
                        {listStyleType: 'undefined'}
                }>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt',
                                color: 'black',
                                border: 'none windowtext 1.0pt'
                            }
                        }>Optimization Using Random Approach</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Machine Learning - Part-1 (Introduction)</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Machine Learning - Part-2 (Regression)</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Machine Learning - Part-3 (Classification and Clustering)</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Data Analytics using statistics</span>
                    </li>
                </ol>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        verticalAlign: 'baseline'
                    }
                }>
                    <strong>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                border: 'none windowtext 1.0pt',
                                padding: '0cm'
                            }
                        }>&nbsp;</span>
                    </strong>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        verticalAlign: 'baseline'
                    }
                }>
                    <strong>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                border: 'none windowtext 1.0pt',
                                padding: '0cm'
                            }
                        }>Week 3. Theme: Image Processing and Deep Learning</span>
                    </strong>
                </p>
                <ol className="decimal_type"
                    style={
                        {listStyleType: 'undefined'}
                }>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt',
                                color: 'black',
                                border: 'none windowtext 1.0pt'
                            }
                        }>Introduction to Image Processing and Deep Learning (Part-1)</span>
                    </li>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                border: 'none windowtext 1.0pt'
                            }
                        }>Introduction to Image Processing and Deep Learning (Part-2)</span>
                    </li>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                border: 'none windowtext 1.0pt'
                            }
                        }>Introduction to Image Processing and Deep Learning (Part-3)&nbsp;</span>
                    </li>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                border: 'none windowtext 1.0pt'
                            }
                        }>Introduction to Image Processing and Deep Learning (Part-4)&nbsp;</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Hands-on Practice on Transfer Learning and GAN</span>
                    </li>
                </ol>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify',
                        verticalAlign: 'baseline'
                    }
                }>
                    <strong>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                border: 'none windowtext 1.0pt',
                                padding: '0cm'
                            }
                        }>&nbsp;</span>
                    </strong>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify',
                        verticalAlign: 'baseline'
                    }
                }>
                    <strong>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                border: 'none windowtext 1.0pt',
                                padding: '0cm'
                            }
                        }>Week 4. Theme: Natural Language Processing (NLP) and Blockchain</span>
                    </strong>
                </p>
                <ol className="decimal_type"
                    style={
                        {listStyleType: 'undefined'}
                }>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt',
                                color: 'black',
                                border: 'none windowtext 1.0pt'
                            }
                        }>Introduction to Natural Language Processing&nbsp;</span>
                    </li>
                    <li>
                        <span style={
                            {fontFamily: '"Times New Roman",serif'}
                        }>Text Analytics and NLP&nbsp;</span>
                    </li>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt'
                            }
                        }>Hands-on with NLP Projects&nbsp;</span>
                    </li>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt'
                            }
                        }>Introduction to Blockchain&nbsp;</span>
                    </li>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt'
                            }
                        }>Tools for Blockchain&nbsp;</span>
                    </li>
                </ol>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify',
                        lineHeight: '18.0pt',
                        verticalAlign: 'baseline'
                    }
                }>
                    <span style={
                        {
                            fontSize: 14,
                            fontFamily: '"Times New Roman",serif'
                        }
                    }>&nbsp;</span>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify',
                        verticalAlign: 'baseline'
                    }
                }>
                    <strong>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                border: 'none windowtext 1.0pt',
                                padding: '0cm'
                            }
                        }>Week 5. Theme:&nbsp;Internet of Things (IoT), Web Development and Cloud Services</span>
                    </strong>
                </p>
                <ol className="decimal_type"
                    style={
                        {listStyleType: 'undefined'}
                }>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt',
                                color: 'black',
                                border: 'none windowtext 1.0pt'
                            }
                        }>Internet of Things (</span>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt'
                            }
                        }>IoT) and its applications-1&nbsp;</span>
                    </li>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt',
                                color: 'black',
                                border: 'none windowtext 1.0pt'
                            }
                        }>Internet of Things (</span>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                fontSize: '10.5pt'
                            }
                        }>IoT) and its applications-2&nbsp;</span>
                    </li>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt'
                            }
                        }>Application of IoT using Node MCU</span>
                    </li>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt',
                                color: 'black',
                                border: 'none windowtext 1.0pt'
                            }
                        }>Web Development using DJango - Part-1&nbsp;</span>
                    </li>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt',
                                color: 'black',
                                border: 'none windowtext 1.0pt'
                            }
                        }>Web Development using DJango - Part-2 (Machine Learning Model Deployment)&nbsp;</span>
                    </li>
                </ol>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify',
                        verticalAlign: 'baseline'
                    }
                }>
                    <strong>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                border: 'none windowtext 1.0pt',
                                padding: '0cm'
                            }
                        }>&nbsp;</span>
                    </strong>
                </p>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: 'justify',
                        verticalAlign: 'baseline'
                    }
                }>
                    <strong>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                border: 'none windowtext 1.0pt',
                                padding: '0cm'
                            }
                        }>Week 6. Theme: Time Series, Explainable AI, How to write and publish research papers</span>
                    </strong>
                </p>
                <ol className="decimal_type"
                    style={
                        {listStyleType: 'undefined'}
                }>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt',
                                color: 'black',
                                border: 'none windowtext 1.0pt'
                            }
                        }>Introduction and Hands-on practics on Time-Series problems</span>
                    </li>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt'
                            }
                        }>Introduction and
                            <span style={
                                {color: 'black'}
                            }>Hands-on practics on&nbsp;</span>Explainable AI&nbsp;</span>
                    </li>
                    <li>
                        <span style={
                            {
                                fontFamily: '"Times New Roman",serif',
                                // fontSize: '10.5pt'
                            }
                        }>R Programming and TOPSIS&nbsp;</span>
                        
                            <li>
                                <span style={
                                    {
                                        fontFamily: '"Times New Roman",serif',
                                        // fontSize: '10.5pt'
                                    }
                                }>How to write and publish research papers</span>
                            </li>
                        
                    </li>
                </ol>
                <p style={
                    {
                        margin: '0cm',
                        marginBottom: '.0001pt',
                        fontSize: 16,
                        fontFamily: '"Calibri",sans-serif'
                    }
                }>
                    <span style={
                        {
                            fontSize: 15,
                            fontFamily: '"Segoe UI",sans-serif'
                        }
                    }>&nbsp;</span>
                </p>
            </div>

        </div>
    )
}

export default DetailedCourse;
