import React from 'react'
import Header from '../Header'
import Navbar from '../navbar'
import Footer from '../Footer'
import Lower from '../Lower'
import health from "../images/health.jpg"
import blockchain from "../images/blockchain.jpeg"
import Nav from 'react-bootstrap/Nav';
import ar from "../images/ar.png"
import ds from "../images/ds.jpeg"
import wds from "../images/wds.jpg"
import br from "../images/Basic-Research.jpg"
import pm from "../images/pythonAndMachine.png"
import dp from "../images/Deep_Learning.jpg"
import wd from "../images/webDevelopmentjpg.jpg"
import emerging from "../images/EmergingTechnologies.jpg"
import './courses.css'
import Accordion from 'react-bootstrap/Accordion';
import restech from '../images/research_courses.jpeg'
import placement from '../images/placements4.jpg'

let jsonData = {
  "courses": [
    {
      "title": "Basic Research",
      "description": "A complete step by step research guidance with mentoring.",
      "img": br,
      "duration": "1 Week",
      "fees": "799/-",
      "detailLink": "/courses/basicResearchCourse",
      "link": "https://buy.stripe.com/5kAeWTfob7wj316dQR",
      "content": [
        "Introduction",
        "What is Research",
        "Types of Research",
        "Components of Research",
        "Elements of Coding",
        "Basic elements of Research paper writing",
        "Developing advanced reading skills",
        "Research Ethics",
        "Research Process",
        "Research Question",
        "Start your research?",
        "Tools for writing a Research Paper",
        "How to write an academic research paper or report",
        "How to write a good paper for a journal",
        "Writing and presenting a paper in conference/seminar/symposia"
      ]
    },
    // {
    //   "title": "Python & Machine Learning",
    //   "description": "A basics of python programming & machine learning",
    //   "img": pm,
    //   "duration": "2 Weeks",
    //   "fees": "999/-",
    //   "detailLink": "/courses/pythonProgrammingCourse",
    //   "link": "https://buy.stripe.com/aEU5mj0th9EreJO28a",
    //   "content": [
    //     "Basics of Python Programming",
    //     "OOPs using Python and Working with Pandas",
    //     "Data Visualization and Exploratory Data Analysis (EDA)",
    //     "R Programming and Topsis for Multi-criteria Decision making (MCDM)",
    //     "Mini Projects",
    //     "Machine Learning - Part-1 (Introduction)",
    //     "Machine Learning - Part-2 (Regression)",
    //     "Machine Learning - Part-3 (Classification and Clustering) and Introduction to Kaggle",
    //     "Advance Kaggle and Handling Errors in ML & DSA"
    //   ]
    // },
    // {
    //   "title": "Deep Learning & Applications",
    //   "description": " A course on deep learning with various applications for beginners",
    //   "img": dp,
    //   "duration": "1 Week",
    //   "fees": "499/-",
    //   "detailLink": "/courses/deepLearningCourse",
    //   "link": "https://buy.stripe.com/8wM6qngsf5obaty4gl",
    //   "content": [
    //     "Introduction to Image Processing, OpenCV and Yolo",
    //     "Deep Learning for Image Processing",
    //     "Introduction to Generative Adversarial Network (GAN)",
    //     "Introduction to Natural Language Processing – I",
    //     "Introduction to Natural Language Processing – II",
    //     "(1) Guidance for Placement and (2) How to write a research paper",
    //     "Introduction to IoT (Internet of Things) and its Applications",
    //     "IoT hands-on Projects with Tinkercad",
    //     "Time Series",
    //     "Data Generation for ML using Modeling and Simulations"
    //   ]
    // },
    // {
    //   "title": "Web Development ",
    //   "description": " Basic and Advanced web development with Web framework.Introduction to Web Development with HTML, CSS, Bootstrap and Github",
    //   "img": wd,
    //   "duration": "2 Weeks",
    //   "fees": "999/-",
    //   "detailLink": "/courses/webdevelopment",
    //   "link": "https://buy.stripe.com/3cscOL0th03RgRW4gj",
    //   "content": [
    //     "Introduction to Web Development with HTML, CSS, Bootstrap and Github",
    //     "Javascript, the duct tape of the Internet",
    //     "Introduction to ReactJS and NodeJS",
    //     "Working with Express and MongoDB",
    //     "Integration and Deployment of Web Services",
    //     "NextJS",
    //     "Web Development using Django - Part-1",
    //     "Web Development using Django - Part-2",
    //     "Product Development Lifecycle, SEO, and Coding Tricks (JUGAAD)",
    //     "Web Scraping and Data Extraction"
    //   ]
    // },
    {
      "title": "International Summer School on Research Methods and Data Science",
      "description": "  Amalgamation of Research, Data Science and Web Development",
      "img": ds,
      "duration": "4 Weeks (starts from 11th September 2023)",
      "fees": "2999/-",
      "detailLink": "/courses/summerCourse",
      "link": "https://buy.stripe.com/9AQ3eb7VJcQD1X2eUU",
      "content": [
        "Module 1: Basic Research",
        "Module 2: Python & Machine Learning",
        "Module 3: Web Development",
        "Module 4: Deep Learning"
      ]
    },
    {
      "title": "Winter School on Machine Learning & Deep Learning with Research Skills",
      "description": "Amalgamation of Research, Machine Learning & Deep Learning",
      "img": wds,
      "duration": "2 Weeks (starts from 06th Nov 2023)",
      "fees": "1999/-",
      "detailLink": "/courses/winterCourse",
      "link": "https://buy.stripe.com/6oE8yv3FtdUHbxCcMS",
      "content": [
        "Module 1: Basic Research",
        "Module 2: Python & Machine Learning",
        "Module 3: Deep Learning"
      ]
    },
    {
      "title": "Connected Healthcare ",
      "description": "A complete beginners to advanced course on healthcare in amalgamation with Internet of Things, Data Science and Blockchain ",
      "img": health,
      "duration": "1 Week (starts from 02nd October 2023)",
      "fees": "799/-",
      "detailLink": "/courses/connectedHealthcare",
      "link": "https://buy.stripe.com/fZe7urek7cQDcBG9AH"
    },
    {
      "title": "Placement & Research paper writing ",
      "description": "A course on placement guidance and writing research paper",
      "img": placement,
      "duration": "1 Day",
      "fees": "249/-",
      "detailLink": "/courses/placementCourse",
      "link": "https://buy.stripe.com/fZe6qn6RF2bZeJOeV3"
    },
    {
      "title": "Emerging technologies (IoT, Time Series, XAI and Data Generation)",
      "description": "A course covers IoT, Time Series, XAI and Data Generation",
      "img": emerging,
      "duration": "1 Week",
      "fees": "799/-",
      "detailLink": "/courses/emergingTechCourse",
      "link": "https://buy.stripe.com/cN25mj7VJg2PbxCaEM"
    },


  ]
}
const ResTech = () => {

  return (
    <div>
      <Header />
      <Navbar />
      <div class="container" style={{ marginTop: '2%', marginBottom: '2%' }}>
        <div class="base_header ">
          <h1 className='text-center'>Research and Technical-Research Courses</h1>
          <hr></hr>
          <div className="imgCont">
            <img src={restech} className='imgres' alt="research" />
            <p style={{ textAlign: 'justify', padding: "20px", wordSpacing: "3px" }}>Empower yourself with Adroid Connectz e-learning platform. Our platform offers a wide range of curated courses on research methodology and Technical-Research oriented courses, taught by renowned experts in the field. Whether you're a student, researcher, or industry professional, our courses provide valuable insights, practical knowledge, and hands-on training to enhance your research skills. Stay ahead of the curve with the latest trends and emerging technologies, and unlock new opportunities for growth and innovation.

              Research is not only for the scientists who are working in their labs, it should be made mandatory for students, whether they are pursuing their studies (School, College), people working in a multinational organization, or an entrepreneur. Research enhances one’s capability to take right decisions in life and it makes us efficient and effective.</p>

          </div>
          <hr></hr>
          <div style={{ 'backgroundColor': '#ebedf0', borderRadius: "10px" }} className='p-2'>
            <h5 className='text-center'> Research will help you to inculcate the following skills:</h5>

            <Accordion className='main'>

              <Accordion.Item eventKey="0" className="item">
                <Accordion.Header className="header">Improves professional & personal development</Accordion.Header>
                <Accordion.Body>
                  Research is a tiring process, a path less travelled, tough, and weary. To conduct research, it is of paramount importance that a person is fully developed in academic, mental, and emotional aspects. That is where our course comes into play. It helps in the holistic development which not only helps you become a researcher but indeed the best one.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1" className="item">
                <Accordion.Header className="header">Enhances reading skills</Accordion.Header>
                <Accordion.Body>
                  Research starts with scanning and skimming all the previous work done in the field of the research objective. This process is popularly known as the Literature Review. Without a well-understood review, descriptive and causal research is mostly not possible. Our course helps in this by honing your reading skills so that your speed, accuracy, and comprehension reach an all-together new level.
                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="2" className="item">
                <Accordion.Header className="header">Develop writing skills</Accordion.Header>
                <Accordion.Body>
                  After the ground level researching and review, it is time to pen it down into words that should be chosen correctly and put forth the view of the researcher in a clear, concise, and appropriate manner. For this, the researcher requires writing skills that are able to convey what the mind thinks. Our course enables you to frame your research in the best possible manner so that whoever reads your research, understands every bit of it.
                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="3" className="item">
                <Accordion.Header className="header">Develop critical thinking</Accordion.Header>
                <Accordion.Body>
                  Research starts with scanning and skimming all the previous work done in the field of the research objective. This process is popularly known as the Literature Review. Without a well-understood review, descriptive and causal research is mostly not possible. Our course helps in this by honing your reading skills so that your speed, accuracy, and comprehension reach an all-together new level.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4" className="item">
                <Accordion.Header className="header">Being able to separate facts & opinions</Accordion.Header>
                <Accordion.Body>
                  A lot of times researchers while conducting primary research or even secondary, get confused about whether to take a statement as a fact or an opinion. This is where we step in to help you distinguish clearly between these two terminologies and guide you as to what-is-what so that you do not make such mistake.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5" className="item">
                <Accordion.Header className="header">Inculcates Research Ethics</Accordion.Header>
                <Accordion.Body>
                  Research ought to be ethical. It is one of the basic principles of research that it should not violate moral and social values. Our course will make you understand what values are and how you can find different ways to research without exploiting any of those values. We shall also help you dive deeper into the social values which will make your research closer to the community you choose to cater to.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6" className="item">
                <Accordion.Header className="header">Improves Acumen Skills</Accordion.Header>
                <Accordion.Body>
                  A judgment is the closure of research and hence, is of supreme importance. Technically, research holds importance based on the judgment it has led to. A judgment shall not only be well-framed (ah, don’t worry we will help you with writing your skills) but also balanced. Most importantly, the research done should be a testimony of the judgment and they both should not go in opposite directions. Our course is a powerpack solution from
                  the start of the research to the end judgment!.
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>

          </div>

        </div>

        <h2 className='mob' style={{ 'marginTop': '30px' }}>Adroid Connectz E-Learning Courses</h2>
        <div className="container">
          <div className="row">




            {jsonData.courses.map((course, index) => (
              <div className="col-md-6 col-lg-4 col-xl-4" style={{ display:"grid" ,marginTop: "2%", marginBottom: "2%" }} key={index}>
                {/* <h4>{course.title}</h4> */}
                <div className="card category-card" style={{ maxHeight: "100%" }}>
                  <img className="card-img-top w-100 d-block" src={course.img} alt="service1" />
                  <div className="card-body">
                    <h4 className="card-title" style={{ padding: "4px" }}>{course.title}</h4>
                    <p >{course.description}</p>
                    <p class="h6">Duration : {course.duration}</p>
                    <p class="h6">Price : ₹{course.fees}</p>

                  </div>

<div>
                  <Nav.Link href={course.detailLink} style={{ textAlign: "center", minHeight: "90%", marginTop: "2%", marginBottom: "2%" }}><button type="button" class="btn btn-danger">Details</button></Nav.Link></div>
              <div>   <Nav.Link href={course.link} target="_blank" style={{ textAlign: "center", minHeight: "90%", marginTop: "2%", marginBottom: "2%" }}><button type="button" class="btn btn-success">Buy Now</button></Nav.Link></div>
                </div>
              </div>
            ))}



            {/* <div className="col-md-6 col-lg-4 col-xl-4" style={{ marginTop: "2%" }}>
              <h4>Technical Research</h4>
              <div className="card category-card" key={4}>
                <img className="card-img-top w-100 d-block" src={wd} alt="service1" />
                <div className="card-body">
                  <h4 className="card-title">Web Development</h4>
                  <p>Basic and Advanced web development</p>
                </div>
              </div>
            </div> */}

            {/* <div className="col-md-6 col-lg-4 col-xl-4" style={{ marginTop: "2%" }}>
              <h4>Technical Research</h4>
              <div className="card category-card" key={5}>
                <img className="card-img-top w-100 d-block" src={ds} alt="service1" />
                <div className="card-body">
                  <h4 className="card-title">Summer School on Research Methods & Data Science</h4>
                  <p>Amalgamation of Research, Data Science and Web Development</p>

                </div>

              </div>

            </div>

            <div className="col-md-6 col-lg-4 col-xl-4" style={{ marginTop: "2%" }}>
              <h4>Technical Research</h4>
              <div className="card category-card" key={6}>
                <img className="card-img-top w-100 d-block" src={ds} alt="service1" />
                <div className="card-body">
                  <h4 className="card-title">Winter School on Data Science</h4>
                  <p>Amalgamation of Research, and Machine & Deep Learning</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-4 mob" style={{ marginTop: "2%" }}>
              <h4 className='mob'>Technical Research</h4>
              <div className="card category-card h-40" key={7}>
                <img className="card-img-top w-100 d-block" src={ds} alt="service1" />
                <div className="card-body">
                  <h4 className="card-title">Data Science</h4>
                  <p>A complete beginner’s course for machine learning and deep learning</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>


        <hr></hr>
        <br /><br />
        <h2 className='mob'>Upcoming Courses</h2>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4 col-xl-4" style={{ marginTop: "2%" }}>
              <h4>Research</h4>
              <div className="card category-card" key={1}>
                <img className="card-img-top w-100 d-block" src={ar} alt="service1" />
                <div className="card-body">
                  <h4 className="card-title">Advanced Research</h4>
                  <p> learns advanced research concepts with publication assistance in reputed ISI/SCI indexed journal.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-4" style={{ marginTop: "2%" }}>
              <h4 className='mob'>Technical Research</h4>
              <div className="card category-card" key={2}>
                <img className="card-img-top w-100 d-block" src={blockchain} alt="service1" />
                <div className="card-body">
                  <h4 className="card-title">Blockchain</h4>
                  <p> A complete beginner’s course based on research perspective.</p>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <Lower />
      <Footer />
    </div>
  )
}

export default ResTech