import React from 'react';
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CssBaseline } from '@mui/material';
import Header from '../Header'
import Navbar from '../navbar'
import Footer from '../Footer'
import Lower from '../Lower'
import classes from "./WebDevelopmentCourse.module.css"
import headerImage from '../images/health.jpg'
import { Nav } from 'react-bootstrap';
const courseData = {
  "courseName": "Connected Healthcare",
  "description": "A complete beginners to advanced course on healthcare in amalgamation with Internet of Things, Data Science and Blockchain",
  "instructor": "Assoc. Prof. Dr. Utku KOSE (Suleyman Demirel University, Turkey)",
  "duration": "1 Week (starts from 02nd October 2023)",
  "fees": "799/-",
  "courseContent": [
    "Healthcare in 21st Century",
    "The Concept of Connected Healthcare",
    "Essentials of Internet of Things",
    "Data Science for Internet of Healthcare Things",
    "Python for Data Science Processes",
    "Python for Artificial Intelligence Aspects",
    "Scenarios for Healthcare Applications",
    "Ensuring Connected Codes",
    "Essentials of Blockchain",
    "Blockchain for Connected Healthcare",
    "Scenarios for Blockchain Based Internet of Healthcare Things",
    "Final Remarks"
  ],
  "note": "The content of the course “Connected Healthcare” has been designed by the group of reputed researchers who have long trails of success in research related activities like publishing research papers in reputed International Journals, and Conferences; edited and authored books with reputed publishers; Have been part of funded international research projects headed by Dr. Utku Kose."
}



const ConnectedHealthCare = () => {
  return (
    <React.Fragment>
      <Header />
      <Navbar />
      <Container className={classes.Container}>

        <CssBaseline />
        <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>
          {courseData.courseName}
        </Typography>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          {courseData.description}
        </Typography>


        <div className={classes.imgCont}>
          <img src={headerImage} className='imgres' alt="a" />
        </div>
        <Nav.Link href="https://buy.stripe.com/fZe7urek7cQDcBG9AH" target="_blank" style={{ textAlign: "center", minHeight: "90%", marginTop: "2%", marginBottom: "2%" }}><button type="button" class="btn btn-success">Buy Now</button></Nav.Link>
        <div className={classes.con}>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Instructor: {courseData.instructor}
          </Typography>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Duration: {courseData.duration}
          </Typography>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Price: {courseData.fees}
          </Typography>
        </div>

        <Typography variant="h4" gutterBottom>
          Course Content:

        </Typography>
        {courseData.courseContent.map((item, index) => (
          <Typography component="li" key={index}>
            {item}
          </Typography>
        ))}
        <hr></hr>
        <p>
          <span style={{ fontWeight: "bolder" }}>Please Note:</span> The content of the course “Connected Healthcare” has been designed by the group of reputed researchers who have long trails of success in research related activities like publishing research papers in reputed International Journals, and Conferences; edited and authored books with reputed publishers; Have been part of funded international research projects headed by Dr. Utku Kose.  </p>
      </Container>
      <Lower />
      <Footer />
    </React.Fragment>
  );

};

export default ConnectedHealthCare;
