import React from 'react'
import Header from '../Header'
import Navbar from '../navbar'
import '../../components/CSS/SummCSS.css'
import poster from "./Images/WORKSHOP ADROID.png"
import poster2 from "./Images/wkshp-2.jpg"
import Lower from '../Lower'
import Footer from '../Footer'

export default function Workshop() {
    return (
        <div>
            <Header/>
            <Navbar/>
            <h1 style={
                        {color: "rgb(220 53 69)",'margin':'10px'}
                    } className="header-summ">Workshops</h1>
                    <br/>
            <div style={{'width':'80%','margin':'auto'}}>
            <div className="posterDiv">
            <img src={poster2}
                    className='img-thumbnail'
                    style={
                        {"width": '400px'}
                }>

                </img>
            <img src={poster}
                    className='img-thumbnail'
                    style={
                        {"width": '400px'}
                }>

                </img>
               
            </div>
                         
            </div>
           
            <Lower/>
            <Footer/>
        </div>

    )
}
