import React from 'react';
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CssBaseline } from '@mui/material';
import Header from '../Header'
import Navbar from '../navbar'
import Footer from '../Footer'
import Lower from '../Lower'
import classes from "./WebDevelopmentCourse.module.css"
import headerImage from '../images/ds.jpeg'
import { Nav } from 'react-bootstrap';
const courseData = {
  "title": "International Summer School on Research Methods and Data Science",
  "subtitle": "Amalgamation of Research, Data Science and Web Development",
  "duration": "4 Weeks (starts from 11th September 2023)",
  "price": "₹2999/-",
  "outcome": [
    "Student can be able to learn and use the basic python to solve a number of problems. They can be able to apply basic machine learning, and optimization approaches for problem solving.",
    "Students can have a basic understanding about the emerging technologies like image processing, Natural language processing, blockchain, Internet of Things, web development, cloud services, time series, Explainable AI and can be able to apply various machine learning and deep learning approaches on them and build a number of minor and major projects.",
    "Students will also learn how to write and publish related research paper.",
    "Students will also build Github profile and explore Kaggle."
  ],
  "ableToDo": [
    "Able to use basics of Python for Problem Solving",
    "Able to apply Machine Learning, Optimization, Statistics, and Exploratory Data Analysis (EDA) to variety of problems",
    "Understand the basics of Image Processing and Deep Learning",
    "Understands the basics of Natural Language Processing (NLP) and Blockchain",
    "Understands the basics of Internet of Things (IoT), Web Development and Cloud Services",
    "Understands the basics of Time Series, Explainable AI",
    "Able to learn How to write and publish research papers"
  ],
  "courseContent": [
    {
      "title": "Basics of Python Programming (using Google Colab)",
      "instructors": ["Dr. PS Rana"]
    },
    {
      "title": "OOPs using Python",
      "instructors": ["Mr Aadil Garg", "Mr Raghav Garg"]
    },
    {
      "title": "Working with Pandas",
      "instructors": ["Dr. PS Rana"]
    },
    {
      "title": "Data Visualization using Seaborn",
      "instructors": ["Mr Aadil Garg", "Mr Raghav Garg"]
    },
    {
      "title": "Exploratory Data Analysis (EDA)",
      "instructors": ["Mr Aadil Garg", "Mr Raghav Garg"]
    },
    {
      "title": "R Programming",
      "instructors": ["Dr. PS Rana"]
    },
    {
      "title": "Topsis for Multi-criteria Decision making (MCDM)",
      "instructors": ["Dr. PS Rana"]
    },
    {
      "title": "Mini Projects",
      "instructors": ["Mr. Raghav Garg", "Mr. Aadil Garg", "Mr. Pratham Garg"],
      "miniProjects": [
        "Mini Project-05: Image to Text to Audio",
        "Mini Project-06: Web crawler (Email Scraper)",
        "Mini Project-07: Audio to Text",
        "Mini Project-08: Plagiarism checker",
        "Mini Project-09: QR Code Generator"
      ],
      "topics": []
    },
    {
      "title": "Introduction to Machine Learning",
      "instructors": ["PSRana"],
      "topics": [
        "Teachable Machine",
        "Sample Dataset",
        "Model Evaluation Parameters for Regression and Classification",
        "Result Analysis of Machine Learning Models using Excel"
      ]
    },
    {
      "title": "Machine Learning using Pycaret",
      "instructors": ["PSRana"]
    },
    {
      "title": "Machine Learning using Pycaret",
      "instructors": ["PSRana"]
    },
    {
      "title": "Introduction to Kaggle",
      "instructors": ["Mr. Raghav Garg", "Mr. Aadil Garg", "Mr. Pratham Garg"],
      "topics": [
        "Getting Started with Kaggle",
        "Digit Recognizer Baseline",
        "Titanic Baseline",
        "House Price Regression Baseline"
      ]
    },
    {
      "title": "Handling Errors in ML & DSA",
      "instructors": ["Ms. Gurbani Kaur", "Mr. Gaurav Gupta"]
    },
    {
      "title": "Advance Kaggle",
      "instructors": ["Mr. Eishkaran Singh"]
    },
    {
      "title": "Introduction to Web Development",
      "instructors": ["Mr Sahib Singh", "Mr Yuvraj Singh"],
      "topics": [
        "Structure of HTML, Tags, Forms, Semantics, Introduction to CSS, Basic concepts Bootstrap and Git/Github",
        "Tools used: VS Code, Github, Github Desktop, Vercel and Netlify"
      ],
      "miniProjects": ["Mini Project-19: Personal Portfolio"]
    },
    {
      "title": "Javascript, the duct tape of the Internet",
      "instructors": ["Mr Sahib Singh", "Mr Yuvraj Singh"],
      "topics": [
        "JS Core Concepts, DOM, ES6 features, Synchronous/Asynchronous Programming",
        "Tools used: VS Code"
      ],
      "miniProjects": [
        "Mini Project-20: Color Flipper",
        "Mini Project-21: Clock",
        "Mini Project-22: Counter",
        "Mini Project-23: Hamburger Menu",
        "Mini Project-24: FAQs"
      ]
    },
    {
      "title": "Introduction to ReactJS, NodeJS",
      "instructors": ["Mr Sahib Singh", "Mr Yuvraj Singh"],
      "topics": [
        "Node Package Manager, NPX, React Components, JSX, State and Props, React Router, React Hooks, Nodejs",
        "Tools used: VS Code",
        "Pre-Requisites: Install Node, VSCode Extensions: ES7 React/redux/graphQL/React-Native snippets, Prettier"
      ],
      "miniProjects": ["Mini Project-25: eCommerce website"]
    },
    {
      "title": "Working with Express and MongoDB",
      "instructors": ["Mr Sahib Singh", "Mr Yuvraj Singh"],
      "topics": [
        "Routing in Express, Error Handling, Request and Response Handling, Templating Engines, RESTful API development, Intro to Databases, CRUD Operations, Mongoose",
        "Tools used: Postman, VS Code",
        "Pre-Requisites: Install Express, Install Mongodb, Install Postman"
      ],
      "miniProjects": ["Mini Project-25: eCommerce website"]
    },
    {
      "title": "Integration and Deployment of Web Services",
      "instructors": ["Mr Sahib Singh", "Mr Yuvraj Singh"],
      "topics": [
        "Integration, Frontend Deployment, Backend Deployment",
        "Tools used: VS Code, Postman, Github, Github Desktop, Vercel, Netlify"
      ],
      "miniProjects": ["Mini Project-26: Integrate fake store API"]
    },
    {
      "title": "NextJS",
      "instructors": ["Mr Sahib Singh", "Mr Yuvraj Singh"],
      "topics": [
        "Introduction to NextJS, Server side rendering, Static site generation, Routing, Dynamic Rendering, Data Fetching, Next API routes",
        "Tools used: VS Code"
      ],
      "miniProjects": ["Mini Project-27: Blogging Platform"]
    },
    {
      "title": "Introduction to Django",
      "instructors": ["Mr Tarik Bhateja", "Mr. Harjaspreet Singh"],
      "topics": [
        "Introduction to Django Framework",
        "Django Vs MERN",
        "Setup and Introduction to the Directory Structure of Django",
        "How Django Works (MVT)",
        "Introduction to Django",
        "Implementation of Django's directory system",
        "Urls and view layer(app)",
        "Model layer",
        "Data Access through the Admin panel",
        "Getting Started with Our First App"
      ],
      "miniProjects": ["Mini Project-28: PDF Protect with Password WebApp"]
    },
    {
      "title": "Mini Projects, ML Integration and Deployment",
      "instructors": ["Mr Tarik Bhateja", "Mr. Harjaspreet Singh"],
      "miniProjects": [
        "Mini Project-29: Cold Emailing WebApp",
        "Mini Project-30: Teachable Machine Integration",
        "Mini Project-31: Placement predictor WebApp",
        "Website Deployment using Linode, AWS, Azure"
      ]
    },
    {
      "title": "Product Development Lifecycle, SEO, and Coding Tricks (JUGAAD)",
      "instructors": ["Mr. Sahib Singh"],
      "topics": [
        "Product Development Lifecycle",
        "SEO",
        "Meta tags",
        "Flask Introduction",
        "Some Tools like Google Search Console",
        "Microsoft Clarity"
      ]
    },
    {
      "title": "Web Scraping and Data Extraction",
      "instructors": ["Mr. Gurtaran Singh"],
      "topics": [
        "Introduction to Web Scraping",
        "Ethics of Web Scraping",
        "Web Scraping Workflow",
        "Scraping Thapar Summer School Website using BeautifulSoup and Requests",
        "Introduction to Text Scraping and Data Extraction",
        "Text Extraction from Images, PDFs, CSVs etc",
        "Projects/opportunities for future"
      ],
      "miniProjects": [
        "Mini Project-35: Scraping raw data and getting useful information from Flipkart website",
        "Mini Project-36: Multi Text Extraction from Images"
      ]
    },
    {
      "title": "Introduction to Image Processing, OpenCV and Yolo",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "Intro to image processing",
        "Image Processing using OpenCV",
        "Image Processing using Scikit learn",
        "Intro to Yolo",
        "Yolo Architecture",
        "Object Detection with Yolo",
        "Projects/opportunities for future"
      ],
      "miniProjects": [
        "Mini Project-39: Face Mask Detection using Yolo V7",
        "Mini Project-40: Social Distancing using Yolo V3"
      ]
    },
    {
      "title": "Deep Learning: Image Processing",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "Basics of Deep Learning",
        "Neural networks",
        "Forward Propagation",
        "Backward Propagation",
        "Terminologies for Neural Networks",
        "Steps for building a deep learning model",
        "CNNs",
        "UNet",
        "ResNet",
        "Projects/opportunities for future"
      ],
      "miniProjects": [
        "Mini Project-41: Use Deep Learning for Image Classification",
        "Mini Project-42: Using CNNs for Image Classification"
      ]
    },
    {
      "title": "Introduction to Generative Adversarial Network (GAN)",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "What is generative AI",
        "Capabilities of GANs",
        "What are GANs",
        "GAN architecture",
        "Generator",
        "Discriminator",
        "Training a GAN",
        "Demo of Basic GANs",
        "Demo of CycleGAN",
        "Demo of SRGAN",
        "Projects/opportunities for future"
      ],
      "miniProjects": [
        "Mini Project-46: DCGAN",
        "Mini Project-47: SRGAN"
      ]
    },
    {
      "title": "Introduction to Natural Language Processing",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "What is Natural Language",
        "Preprocessing Textual Data",
        "RNN",
        "LSTM",
        "Projects/opportunities for future"
      ],
      "miniProjects": [
        "Mini Project-49: Toxic Comment Classification using RNN and LSTM"
      ]
    },
    {
      "title": "Introduction to Natural Language Processing",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj"],
      "topics": [
        "History of NLP",
        "NLP Pipeline",
        "Attention is all you need",
        "Transformer Architecture",
        "BERT",
        "BERT vs Alternates",
        "Change from GPT3.5 onwards",
        "BERT Code",
        "Projects/opportunities for future"
      ],
      "miniProjects": [
        "Mini Project-54: Sentiment Analysis on Hospital Comments"
      ]
    },
    {
      "title": "Introduction to LLM (Large Language Models)",
      "instructors": ["Mr Eishkaran Singh", "Mr Tanav Singh Bajaj", "Vansh Gahlot"],
      "topics": [
        "Large Language Models",
        "How do large language models work",
        "RLHF",
        "Pros and Cons of LLMs",
        "Hallucinations in LLMs",
        "Ethics in LLMs",
        "Prompt Engineering",
        "Cost Optimization in LLMs",
        "Develop applications using LLM APIs (Langchain)",
        "ThaparGPT Demo"
      ]
    },
    {
      "title": "Guidance for Placement and CV Building",
      "instructors": ["Mr Gaurav Gupta", "Ms Gurbani Kaur"]
    },
    {
      "title": "How to write a research paper",
      "instructors": ["Dr PSRana"]
    },
    {
      "title": "Introduction to IoT (Internet of Things) and its Applications",
      "instructors": ["Dr. Anjula Mehto"],
      "topics": [
        "The World of IoT",
        "IoT Applications",
        "IoT building Components",
        "Case Study and Making a thing",
        "Industry 4.0- Smart Manufacturing",
        "Introduction to Cloud and Edge Computing"
      ]
    },
    {
      "title": "Introduction to IoT (Internet of Things) and its Applications",
      "instructors": ["Dr. Anjula Mehto"],
      "topics": [
        "Introduction to TinKercad",
        "Hands-on with TinKercad",
        "Sending sensor data to ThinkSpeak"
      ],
      "miniProjects": [
        "Mini Project-57: Blinking a LED",
        "Mini Project-58: Blinking Multiple LED",
        "Mini Project-59: Automatic Night Lamp",
        "Mini Project-60: Traffic Light Controller",
        "Mini Project-61: Fire Alarm System"
      ]
    },
    {
      "title": "Introduction to Explainable Artificial Intelligence (XAI)",
      "instructors": ["Dr. Parteek Bhatia"]
    },
    {
      "title": "Lets build the Future",
      "instructors": ["Mr. Vansh Gehlot"]
    },
    {
      "title": "Time Series Analysis",
      "instructors": ["Mr. Rudransh Bansal"]
    },
    {
      "title": "Data Generation for ML using Modeling and Simulations",
      "instructors": ["Dr. PSRana"]
    }
  ],
  "source": "https://www.psrana.com"
}


const SummerCourse = () => {
  return (
    <React.Fragment>
      <Header />
      <Navbar />
      <Container className={classes.Container}>

        <CssBaseline />
        <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>
          {courseData.title}
        </Typography>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          {courseData.subtitle}
        </Typography>



        <div className={classes.imgCont}>
          <img src={headerImage} className='imgres' alt="a" />
        </div>
        <Nav.Link href="https://buy.stripe.com/9AQ3eb7VJcQD1X2eUU" target="_blank" style={{ textAlign: "center", minHeight: "90%", marginTop: "2%", marginBottom: "2%" }}><button type="button" class="btn btn-success">Buy Now</button></Nav.Link>
        <div className={classes.con}>
          {/* <Typography className={classes.Typography_gutterBottom} variant='h6'>
              Instructor: {courseData.instructor}
            </Typography> */}
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Duration: {courseData.duration}
          </Typography>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Price: {courseData.price}
          </Typography>
        </div>
        <Typography variant="h4" gutterBottom>
          Outcome:

        </Typography>
        <p>Student can be able to learn and use the basic python to solve a number of problems. They can be able to apply basic machine learning, and optimization approaches for problem solving. Students can have a basic understanding about the emerging technologies like image processing, Natural language processing, blockchain, Internet of Things, web development, cloud services, time series, Explainable AI and can be able to apply various machine learning and deep learning approaches on them and build a number of minor and major projects. Students will also learn how to write and publish related research paper. Students will also build Github profile and explore Kaggle.</p>
        <Typography variant="h5" gutterBottom>
          Able to do after completion of this course:

        </Typography>
        <ul>
          {courseData.ableToDo.map((item, index) => (
            <Typography component="li" key={index}>
              {item}
            </Typography>
          ))}
        </ul>
        <hr></hr>
        <Typography variant="h4" gutterBottom>
          Course Content:

        </Typography>
        {courseData.courseContent.map((section, index) => (
          <div key={index}>
            <Typography variant="h5" gutterBottom>
              {index + 1}.{section.title}
            </Typography>
            <Typography className={classes.Typography_gutterBottom}>
              Instructors: {section.instructors.join(', ')}
            </Typography>
            <ul>
              {section.topics ? (
                <ul>
                  {section.topics.map((topic, i) => (
                    <Typography component="li" key={i}>
                      {topic}
                    </Typography>
                  ))}
                </ul>
              ) : (
                <Typography variant="body1" gutterBottom>

                </Typography>
              )}
            </ul>
            {section.miniProjects && section.miniProjects.length > 0 && (
              <>
                <Typography variant="h6" className={classes.Typography_variantH6}>
                  Mini Projects
                </Typography>
                <ul>
                  {section.miniProjects.map((project, pIndex) => (
                    <Typography component="li" key={pIndex} className={classes.li}>
                      {project}
                    </Typography>
                  ))}
                </ul>
              </>
            )}
            <hr />


          </div>
        ))}
        <p>
          <span style={{ fontWeight: "bolder" }}>Please Note:</span> The content of the course “International Summer School on Research Methods and Data Science” has been designed by the group of reputed researchers who have long trails of success in research related activities; The team for data science course has been headed by Dr. PS Rana.  <a href='https://www.psrana.com' target='_blank'>https://www.psrana.com</a>  </p>
      </Container>
      <Lower />
      <Footer />
    </React.Fragment>
  );

};

export default SummerCourse;
