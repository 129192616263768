import React from 'react';
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CssBaseline } from '@mui/material';
import Header from '../Header'
import Navbar from '../navbar'
import Footer from '../Footer'
import Lower from '../Lower'
import classes from "./WebDevelopmentCourse.module.css"
import headerImage from '../images/placements4.jpg'
import { Nav } from 'react-bootstrap';
const courseData = {
  "courseName": "Placement & Research Paper Writing",
  "description": "A course on placement guidance and writing research paper",
  "instructor": "Dr. PS Rana, Thapar Institute of Engineering & Technology, India",
  "duration": "1 Day",
  "fees": "₹249/-",
  "courseContent": [{
    "title": "Guidance for Placement and CV Building ",
    "instructor": ["Mr Gaurav Gupta", "Ms Gurbani Kaur"],
  }
  ],

  "outcome": "Students can be able to learn how and what to prepare for placements to crack the interview. They will also learn how to write a good research paper.",
  "ableToDoAfterCompletion": [
    "Understand the basics requirements to crack placement interviews",
    "Understands how to write a good research paper"
  ],
  "note": "The content of the course 'Deep Learning & Applications' has been designed by the group of reputed researchers who have long trails of success in research related activities; The team for data science course has been headed by Dr. PS Rana.",
  "website": "https://www.psrana.com"
}




const PlacementCourse = () => {
  return (
    <React.Fragment>
      <Header />
      <Navbar />
      <Container className={classes.Container}>

        <CssBaseline />
        <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>
          {courseData.courseName}
        </Typography>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          {courseData.description}
        </Typography>


        <div className={classes.imgCont}>
          <img src={headerImage} className='imgres' alt="a" />
        </div>
        <Nav.Link href="https://buy.stripe.com/fZe6qn6RF2bZeJOeV3" target="_blank" style={{ textAlign: "center", minHeight: "90%", marginTop: "2%", marginBottom: "2%" }}><button type="button" class="btn btn-success">Buy Now</button></Nav.Link>
        <div className={classes.con}>
          <Typography className={classes.Typography_gutterBottom} variant='h6' >
            Instructor: {courseData.instructor}
          </Typography>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Duration: {courseData.duration}
          </Typography>
          <Typography className={classes.Typography_gutterBottom} variant='h6'>
            Price: {courseData.fees}
          </Typography>
        </div>
        <Typography variant="h4" gutterBottom>
          Outcome:

        </Typography>
        {courseData.outcome}
        <hr></hr>

        <Typography variant="h5" gutterBottom>
          Able to do After completion of course:

        </Typography>
        <ul>
          {courseData.ableToDoAfterCompletion.map((item, index) => (
            <Typography component="li" key={index}>
              {item}
            </Typography>
          ))}
        </ul>
        <Typography variant="h4" gutterBottom>
          Course Content:

        </Typography>
        {courseData.courseContent.map((section, index) => (
          <div key={index}>
            <Typography variant="h5" gutterBottom>
              {index + 1}.{section.title}
            </Typography>
            <Typography className={classes.Typography_gutterBottom}>
              Instructors: {section.instructor.join(', ')}
            </Typography>
          </div>
        ))}

        <hr></hr>
        <p>
          <span style={{ fontWeight: "bolder" }}>Please Note:</span>  The content of the course “Placement & Research Paper Writing” has been designed by the group of reputed researchers who have long trails of success in research related activities; The team for data science course has been headed by Dr. PS Rana.
          <a href='https://www.psrana.com' target='_blank'>https://www.psrana.com</a>  </p>
      </Container>
      <Lower />
      <Footer />
    </React.Fragment>
  );

};

export default PlacementCourse;
