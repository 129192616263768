import React from 'react';
import { useState } from 'react';
import Header from '../Header'
import Navbar from '../navbar'
import Footer from '../Footer'
import Lower from '../Lower'
import resSum from '../images/research_summary.jpeg'
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import './courses.css'
import writeUpForm from "../Write-up details-Adroid Connectz.docx"
const videoUrls = [
  "https://www.youtube.com/embed/xoWmKBy1660",
  "https://www.youtube.com/embed/jibD45jGz1k",
  "https://www.youtube.com/embed/S2zLTLNP2DQ",
  "https://www.youtube.com/embed/W_HE99vyn5M",
  "https://www.youtube.com/embed/JuKpeQsShVg",
  "https://www.youtube.com/embed/w17dctxOTkw",
  "https://www.youtube.com/embed/trS-LDPeTF8",
  "https://www.youtube.com/embed/j-o0VzfU0KI",
  "https://www.youtube.com/embed/DZEjQO1snmc",
];
const Research = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const playNextVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoUrls.length);
  };

  const playPreviousVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex - 1 + videoUrls.length) % videoUrls.length);
  };
  return (
    <div>
      <Header />
      <Navbar />
      <div class="container" style={{ marginTop: '2%', marginBottom: '10%' }}>
        <div class="base_header "><h1 className='text-center'>Research Summary</h1>
        <hr></hr>
          <div className="imgCont">
            <img src={resSum} className='imgresSum' />
            <p className='ps-3 pt-2' style={{ textAlign: 'justify', padding: "10px",wordSpacing:"3px"}}>Embark on an exciting journey of learning and creativity with Adroid Connectz captivating research summary. Covert and share your advanced research discoveries and gain recognition from respected peers and industry experts. Join our vibrant community of innovators and contribute to global progress by adding to our collective knowledge.</p>
            <p className='ps-3 pt-2' style={{ textAlign: 'justify', padding: "10px",wordSpacing:"3px" }}>Embark on an exciting journey of learning and creativity with Adroid Connectz captivating research summary. Covert and share your advanced research discoveries and gain recognition from respected peers and industry experts. Join our vibrant community of innovators and contribute to global progress by adding to our collective knowledge.

Adroid Connectz aims to help researchers, students, academicians to understand the published paper in easy and simple manner. To start the research, the first thing is to search and read the research papers; then we are able to formulate the problem for our research. But it is a very tedious task, to read the article and save it for future reference; to solve this, Adroid Connectz takes an initiative as Video Summary. 

We have converted a published paper into a video with voice format which makes it easy, readable and understandable for researchers.</p>
          </div>
          <hr></hr>
          <p style={{ textAlign: 'center', padding: "10px",wordSpacing:"3px" }}>To view video summary for published research papers, please subscribe to our <b><a href='https://youtube.com/@adroidconnectz' target='__blank'>YouTube Channel</a></b> </p>
          <p style={{ color: 'red' }}>If you are interested and giving your consent to upload the content in Video Form on Adroid Connectz YouTube and Social Media Platform then download the write-up-summary form below and fill the registration form.</p>
          <div className='imgCont' style={{ justifyContent: 'normal' }}>
            <a href={writeUpForm}><Button variant="primary" className='m-2' > <i className="fa fa-download" />    Download Write-up Form</Button></a>
            <a href='https://forms.gle/zBPmCyLrcBgv8SJp9'><Button variant="danger" className='m-2'>  Registration Form</Button></a>
          </div>
          <hr></hr>
          <h3 className='text-center m-3'>Adroid Connectz: Research Summary Initiative</h3>

          {/* <iframe className='vidresSum' src="https://www.youtube.com/embed/xoWmKBy1660" title="Conference Research Paper - 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <iframe className='vidresSum' src="https://www.youtube.com/embed/jibD45jGz1k" title="Conference Research Paper - 2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
          <div id="videoCarousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        {videoUrls.map((videoUrl, index) => (
          <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
            <div className="embed-responsive embed-responsive-16by9" style={{ marginTop: '2%', marginBottom: '10%',height:"60%" }}>
              <iframe
                className="embed-responsive-item"
                src={videoUrl}
                title={`Conference Research Paper - ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        ))}
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#videoCarousel" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#videoCarousel" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
        </div>
      </div>

      {/* </div> */}
      <Lower />
      <Footer />
    </div>
  )
}

export default Research