import React from "react";
import style from "../../CSS/header.module.css";
import Date from '../Date'
import Header from "../../Header";
import NavBar from "../../navbar";
import Lower from "../../Lower";
import Footer from "../../Footer";

const CallForPaper=()=>{
  return(
  <div>
  <Header />
  <NavBar />
  <div  style={{'backgroundColor':'red','padding':'4px'}}>
  <h2 class={style.abtHeader}>Call For Papers</h2>
  </div>
  <hr />
  <div className={style.flex}>
  <div className={style.abtInstCont}>

  <h5>ICHBA 2024 will be inviting paper on the topics as listed below but not limited to:</h5>
      <ul>
<li>Digital twin and cognitive AI</li>
<li>Intelligent reality</li>
<li>AI for combating long COVID</li>
<li>AI-based clinical decision systems</li>
<li>Biomedical natural language processing</li>
<li>AI for biomarker discovery and drug design</li>
<li>Digital radiology and pathology</li>
<li>Single-cell and spatial omics</li>
<li>Cancer genomics and immunotherapy</li>
<li>Medical security and federated learning</li>
<li>AI ethics and regulation</li>
<li>Clinical informatics</li>
<li>Translational informatics</li>
<li>Behavioral and mental health informatics</li>
<li>Biomedical data science and engineering education Multiscale modeling</li>
<li>Medical AI for wearable and pervasive sensing</li>

      </ul>                      
    
  </div>



  <Date />

</div>

  <Lower />
  <Footer />
  </div>
);
};

export default CallForPaper;
