import React from "react";
import style from "../CSS/header.module.css";
import Date from './Date'
import Header from "../Header";
import NavBar from "../navbar";
import Lower from "../Lower";
import Footer from "../Footer";


const HomeCont = () => {
    return (
        <div>
            <Header />
            <NavBar />
            <div  style={{'backgroundColor':'red','padding':'4px'}}>
            <h3 className={
                style.abtHeader
            }>International Conference on Healthcare and Biomedical Applications 2024</h3>
            </div>
            <hr/>
            <div className={style.flex} >
                <div className={
                    style.abtContent
                }>
                    <p>International Conference on Healthcare and Biomedical Applications (ICHBA-2024) is organized with the objective of bringing together scientists, professors, research scholars, students, and industrial experts in the field of healthcare and biomedical in a common forum. The conference will provide the researchers and attendees with prospects for national and international collaboration and networking. ICHBA-2024 will be held in Virtual Mode. Depending on the quality of the paper (after double blinded peer review), the paper will be accepted for possible publication in one of the following venue:
                    </p>
                    <ul>
                        <li>International Journals (SCOPUS) </li>
                        <li>Book Chapter (SCOPUS)</li>
                        <li>Elsevier SSRN</li>
                    </ul>
                    <h4>About Adroid Connectz</h4>
                    <p>Adroid Connectz organizes conferences with the objective of bringing together scientists, professors, research scholars, students and industrial experts in the field of engineering, applied sciences, management and other domains to a common forum. The primary goal of the conference is to promote the exchange of innovative scientific information between researchers, developers, engineers, students, and practitioners. Another goal is to promote the transformation of fundamental research into institutional and industrialized research and to convert applied exploration into real time application.</p>
                    <h4>Highlights</h4>
                    <ul>
<li>The conference focusses in bridging the gap between Industry and academia by organizing the workshops by the industry experts on latest technologies for the participants.</li>
<li>The conference is anticipated to attract a large number of high quality submissions and stimulate the cutting-edge research discussions among many academic pioneering researchers, scientists, industrial engineers, students from all around the world.</li>
<li>Another goal of this conference is to provide opportunities for academics to receive informal in-depth feedback through discussions, and to enable them to establish contact with professionals in various countries and institutions and also to encourage regional and international communication and collaboration.</li>
<li>Provide common platform for academic pioneering researchers, scientists, engineers and students to share their views and achievements.</li>
<li>Focus on innovative issues at international level by bringing together the experts from different countries.</li>
                    </ul>
                <h4>Paper Submission</h4>      
                <p>All papers must be submitted online via google form to <a href="https://forms.gle/1aeR81YHyQEeZFxP9">https://forms.gle/1aeR81YHyQEeZFxP9</a></p>
                </div>

                <Date/>
                {/* <div className={
                    style.downloads
                }>
                    <h3>Downloads</h3>
                    <marquee direction="up"
                        className={
                            style.marquee
                        }
                        style={
                            {width: '20vw'}
                        }
                        
                        scrollAmount="4"
                        >
                        
                        
                        <a href={
                                require("../Downloads/docs/Checklist.pdf")
                            }
                            target="_blank">Checklist
                        </a>
                        <hr style={
                            {width: '15vw'}
                        }/>

                        <a href={require("../Downloads/docs/ManuscriptguidelinesforEnglishbooks.pdf")}
                        target="_blank">Manuscript Guidelines
                            
                        </a>
                        <hr style={
                            {width: '15vw'}
                        }/>
                       
                        
                        <a
                        href={require("../Downloads//docs/SpringerWordTemplate.zip")}
                        target="_blank"
                        >Springer Word Template
                            
                        </a>
                        <hr style={
                            {width: '15vw'}
                        }/>
                        <a
                        href={require("../Downloads//docs/SpringerLaTeXTemplate.zip")}
                        target="_blank"
                        >Springer LaTex Template 
                            
                        </a>
                        <hr style={
                            {width: '15vw'}
                        }/>
                        <a
                        href={require("../Downloads/docs/Elsevier SSRN_Word Template (1).docx")}
                        target="_blank"
                        >Elsevier SSRN Paper Template 
                            
                        </a>
                        <hr style={
                            {width: '15vw'}
                        }/>
                        <a
                        href={require("../Papers/Special-session-CFP-Template-(DoSCI).docx")}
                        target="_blank"
                        >Special Session Proposal Template
                            
                        </a>
                        <hr style={
                            {width: '15vw'}
                        }/>
                        

                    </marquee>
                </div> */}
                
            </div>
            <Lower />
            <Footer />
        </div>
    );
};

export default HomeCont;
