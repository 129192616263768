import Header from "./Header";
import Navbar from "./navbar";
import Footer from "./Footer";
import Carousel1 from "./Carousel1";
import Carousel2 from "./Carousel2";
import Img2 from "./img2"
import Aboutus from "./aboutus"
import Vismis from "./VisMis";
import Lower from "./Lower";
import MsgFounder from "./MsgFounder";
import Modal from "./Modal";
import Testimonials from "./Testimonials/Testimonials";



function App() {

  return (
    
    <div>


    <Header />
    <Navbar />
    <Carousel1 />
    <Carousel2 />
    {/* <div className="invisible">
    <Modal /> 
    </div> */}


   <Vismis />
    <Img2 />


<Aboutus />
<Testimonials />
<MsgFounder />
<Lower />
    <Footer />


    </div>
    );
}


export default App;
