
function Cards(props) {
        return (

                <div class="card col m-5 border-0 " style={{
                        // width: "15rem" ,
                        backgroundColor: "black", color: "white", height:'440px',padding:'10px'
                }}
                >
                        <a href={props.link} target="_blank"><img src={props.src} className="card-img-top" alt="..." width="100px" height="300px" /></a>
                        <div className="card-body">
                                <h6>{props.heading}</h6>
                                <p className="card-text">{props.text} </p>
                        </div>
                </div>
        )
}
export default Cards