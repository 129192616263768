import React from 'react'
import img from "./robo.jpg"
import robo2 from "./robo2.png"


import Header from './Header'
import Navbar from './navbar'
import '../components/CSS/SummCSS.css'

import bgmain from "../components/bgsumm.jpg"
import { Link } from 'react-router-dom'

import bg from "./bg1.jpeg"
import Lower from './Lower'
import Footer from './Footer'

export default function RmdsReg() {
  return (
    <div>
        <Header />
        <Navbar />
        <div className="body-reg">        
        <div className='reg'>   
        
        <h1 
         style={{float:"left"}}
         className="header-summ"
         >Registration Information</h1>  
          <br /><br/><br/>   
        <div className='reg-inner'>
        <div>
        
        <h4  className="">Registration & Fee Payment: </h4>
        
        <ul> 
        <li>For registration : <a href=" https://forms.gle/YkCS3cocP2f2KAhx5" className='linkanc' target='__blank'> https://forms.gle/YkCS3cocP2f2KAhx5<br /></a>
        </li>
        <li>1st Batch starts from 1st July 2024 to 9th August 2024 | Monday to Friday  </li>
        
<li>Mode: Online (ZOOM) or Adroid Connectz e-Learning Platform</li>
<li>Target Audience: UG/PG/PhD students of all the streams and all years</li>
<li>The recording of all the lectures/labs will be provided to all the students.</li>
{/* <li><b style={{"color":"red"}}>Registration for Batch 1 closed.</b>
</li> */}
<li><b style={{"color":"red"}}>Last Date for Registration: 28th June 2024.</b></li>      
        
        
        </ul>
        </div>
        <div>
        <img src={img} className="reg-img"></img>
        </div>
        </div>
        <div className='reg-inner'>
        <img src={robo2} className="reg-img"></img>
        <div>
        <h3>Registration Fee:</h3>
        <ul>
        <li>Registration Fees of Rs 5310 should be paid<br /> 
        <Link to="https://buy.stripe.com/9AQ3eb7VJcQD1X2eUU" target='__blank'>
        <button className='btn-pay'>Pay now</button>
        </Link>
        </li>
        <li>
        Or SCAN QR Code to Pay Rs 5310/- <br /><img src={require('./UPI QR.png')} style={{width:'125px'}}/>

        {/* <Link to="https://paytm.me/BFXk-Vb" target='__blank'>
        <button className='btn-pay'>Pay via UPI</button>
        </Link>
        Click to Pay and fill the registration form.         */}
        </li>
        <li>
        Fill the registration form <a href="https://forms.gle/YkCS3cocP2f2KAhx5">https://forms.gle/YkCS3cocP2f2KAhx5</a> , once successfully paid registration fees
        </li>
        <br />
        
       <h6 style={{"color":"red"}}>Contact for more information:
       <br />
Director (Adroid Connectz Pvt Ltd)<br />
adroidconnectz@gmail.com
</h6>
        
        </ul>
        </div>
        
        
        </div>
     
        </div> 
    </div>

        

        
        <Lower/>
    <Footer />
    </div>
    
  )
}
